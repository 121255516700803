import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClearIcon } from "@mui/x-date-pickers";
import {
  Container,
  TextField,
  Button,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Fade,
  Modal,
  Backdrop,
  Divider,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  FormHelperText,
} from "@mui/material";
import { setError, useGetMachineInfoByIdQuery } from "../../../redux-services";
import Error from "../../../components/ErrorBox/Error";
import {
  useCreateMachineDocMutation,
  useEditMachineDocsMutation,
  useGetDocumentInfoByIdQuery,
} from "../../../redux-services/api/MachineDocApi";
import { RootState } from "../../../store/store";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useMachineDocumentTypeListQuery } from "../../../redux-services/api/MachineDocumentTypeApi";

export interface UserState {
  insurance_amount: string;
  document_type: string;
  expiry_date: any;
  state_tax_amount: number;
  tax_expiry_date: number;
  vehicle_permit_amount: number;
  permit_date: number;
  images: any[];
  updated_image: any[];
  image_url: any[];
    previous_images: any[];
  urls?: any[];
  machine_id: string;
  deleted_images?: any;
}

interface FormErrors {
  insurance_amount?: number;
  expiry_date?: any;
  state_tax_amount?: number;
  tax_expiry_date?: number;
  vehicle_permit_amount?: number;
  permit_date?: number;
  document_type?: string;
  images?: string;
}

interface PreviousImage {
  path: string;
  fullUrl: string;
  expiry_date: number;
  insurance_amount: number;
  createdAt: number;
}

const AddEditDocument: React.FC<{
  id: string;
  showModal: boolean;
  setShowModal: any;
  setMachineEntryEditId: any;
  paramId: any;
}> = ({ id, showModal, setShowModal, setMachineEntryEditId, paramId }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };

  const intialState: UserState = {
    machine_id: "",
    insurance_amount: "",
    expiry_date: "",
    state_tax_amount: 0,
    tax_expiry_date: 0,
    vehicle_permit_amount: 0,
    document_type: "",
    permit_date: 0,
    images: [],
    updated_image: [],
    image_url: [],
    urls: [],
    deleted_images: [],
    previous_images: [],
  };
  let dispatch = useDispatch();
  const [formData, setFormData] = useState<UserState>(intialState);
  const [openDialog, setOpenDialog] = useState(false);
  const [previousImages, setPreviousImages] = useState<PreviousImage[]>([]);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>("");
  const [labelText, setLabelText] = useState("");
  const [selectedDocType, setSelectedDocType] = useState(null);
  const [orgId, setOrgId] = useState("");
  const [submittedWithoutImage, setSubmittedWithoutImage] = useState(false);

  const [createMachineDocs] = useCreateMachineDocMutation();
  const [editMachineDocs, editResult] = useEditMachineDocsMutation();
  const { data: MachineInfoData } = useGetMachineInfoByIdQuery(paramId, {
    skip: !paramId,
  });
  const userLoginDetail = useSelector((state: RootState) => state.auth);

  const { data: DocumentData } = useGetDocumentInfoByIdQuery(id, {
    skip: !id,
  });
  const today = new Date().toISOString().split("T")[0];

  const { data: machineDocTypeData } = useMachineDocumentTypeListQuery(orgId, {
    skip: !orgId,
  });

  useEffect(() => {
    if (userLoginDetail?.user?.organization_id) {
      setOrgId(userLoginDetail?.user?.organization_id);
    } else {
      setOrgId(localStorage.getItem("orgId") ?? "");
    }
  }, [userLoginDetail?.user?.organization_id]);

  useEffect(() => {
    if (DocumentData?.status === 200) {
      let data: any = DocumentData?.response;
      setSelectedDocumentType(data.document_type);
      const timestamp = data?.images[0]?.expiry_date;
      let formattedDate = "";
      if (timestamp) {
        const date = new Date(timestamp * 1000);
        const day = date.getUTCDate().toString().padStart(2, "0");
        const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
        const year = date.getUTCFullYear();
        formattedDate = `${year}-${month}-${day}`;
      }
      const previousImages = data?.previous_images || [];
      setPreviousImages(data?.previous_images || []);
      setFormData({
        ...formData,
        document_type: data?.document_type ?? "",
        expiry_date: formattedDate,
        insurance_amount: data?.images[0]?.insurance_amount ?? "",
        images: data?.images?.length
          ? data.images.map((image: any) => image.path)
          : [],
        image_url: data?.encodedUrl?.length ? data.encodedUrl : [],
        previous_images: previousImages,
      });
      setSelectedDocumentType(data?.document_type ?? "");
    }
    return () => {
      dispatch(setError([]));
    };
  }, [DocumentData]);

  const documentTypeLabels: Record<string, string> = {
    Insurance: " Insurance Images",
    "Vehicle RC": " RC Images",
    "Vehicle Paper": "Paper Images",
    "Loan Paper": " Loan Images",
    "Vehicle State Tax": "State Tax Images",
    "Vehicle India Permit": "Permit Images",
  };
  if (editResult.isSuccess && editResult.data.status === 200) {
    setShowModal(false);
    setMachineEntryEditId("");
  }

  const machineDocuments =
    machineDocTypeData?.response?.map((doc: any) => doc.document_type) || [];

  const handleDocumentTypeChange = (event: any) => {
    const selectedType = event.target.value as string;
    setFormData((prevFormData) => ({
      ...prevFormData,
      document_type: selectedType,
    }));
    setSelectedDocumentType(selectedType);
    setLabelText(documentTypeLabels[selectedType] || "");
    const docTypeDetails = machineDocTypeData?.response?.find(
      (doc: { document_type: string }) => doc.document_type === selectedType
    );
    setSelectedDocType(docTypeDetails);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(setError([]));
    let machineName = "";
    if (MachineInfoData?.response?.number_plate !== "") {
      machineName =
        MachineInfoData?.response?.machine_type +
        "-" +
        MachineInfoData?.response?.number_plate.slice(-4);
    } else {
      machineName = MachineInfoData?.response?.machine_type;
    }
    const hasImages = formData.images && formData.images.length > 0;
    if (!hasImages) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        images: "Please upload an image",
      }));
      setSubmittedWithoutImage(true);
      return;
    }

    let data = new FormData();
    data.append("document_type", selectedDocumentType);
    let serviceTime =
      formData.expiry_date instanceof Object
        ? new Date(formData.expiry_date as any).valueOf()
        : formData.expiry_date;

    for (const item of formData.images) {
      data.append("images", item);
      data.append("insurance_amount", formData.insurance_amount);
      data.append("expiry_date", serviceTime as string);
    }
    data.append("machine_name", machineName ?? "");
    data.append("machine_id", paramId);
    data.append(
      "organization_name",
      MachineInfoData?.response.organization_name
    );
    data.append("id", id ?? null);

    try {
      if (id) {
        await editMachineDocs(data).unwrap();
      } else {
        await createMachineDocs(data).unwrap();
      }
      setShowModal(false);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleRemoveImage = useCallback(
    (index: number) => {
      const updatedImages = [...formData.images];
      formData.expiry_date = "";
      updatedImages.splice(index, 1);

      let deletedImgArr = [];
      if (formData.urls?.length) {
        deletedImgArr.push(formData.urls[index]);
      }
      const updatedFiles = [...formData.image_url];

      updatedFiles.splice(index, 1);
      setFormData({
        ...formData,
        images: updatedImages,
        image_url: updatedFiles,
        deleted_images: [...formData.deleted_images, ...deletedImgArr],
      });
    },
    [formData]
  );
  const handleChange = (e: React.ChangeEvent<any>) => {
    e.preventDefault();

    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.trimStart(),
    }));

    if (name === "expiry_date") {
      const today = new Date();
      const selectedDate = new Date(value);
      if (selectedDate < today) {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          expiry_date: "Expiry date cannot be less than the present date.",
        }));
      } else {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          expiry_date: "",
        }));
      }
    }
  };
  const handleChangeImage = (event: any) => {
    let setFileUrl: string[] = [];
    let file = Array.from(event.target.files);

    for (const element of event.target.files) {
      setFileUrl.push(URL.createObjectURL(element));
    }
    setFormData((prevFormData) => {
      const newPreviousFields = [
        ...(prevFormData.previous_images || []),
        ...prevFormData.images.map((image, index) => ({
          image: image,
          image_url: prevFormData.image_url[index],
          insurance_amount: prevFormData.insurance_amount,
          expiry_date: prevFormData.expiry_date,
        })),
      ];

      return {
        ...prevFormData,
        images: file,
        image_url: setFileUrl,
        previous_fields: newPreviousFields,
      };
    });
    if (file.length > 0) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        images: "",
      }));
      setSubmittedWithoutImage(false);
    }
    const thumbnailElement = document.getElementById(
      "images"
    ) as HTMLInputElement | null;
    if (thumbnailElement) {
      thumbnailElement.value = "";
    }
  };

  const insuranceAmountField =
    id &&
    (formData.insurance_amount === "" ||
      Number(formData.insurance_amount) !== 0) ? (
      <Grid item xs={6} md={6} mt={2}>
        <TextField
          fullWidth
          id="insurance_amount"
          required
          label="Amount"
          name="insurance_amount"
          type="text"
          size="small"
          autoComplete="insurance_amount"
          value={formData.insurance_amount}
          onChange={handleChange}
          error={!!formErrors.insurance_amount}
          helperText={formErrors.insurance_amount}
        />
      </Grid>
    ) : null;

  const expiryDateField =
    id && formData.expiry_date !== "" ? (
      <Grid item xs={6} md={6} mt={2}>
        <TextField
          fullWidth
          id="expiry_date"
          required
          label="Expiry Date"
          name="expiry_date"
          type="date"
          size="small"
          autoComplete="expiry_date"
          value={formData.expiry_date}
          onChange={handleChange}
          error={!!formErrors.expiry_date}
          helperText={formErrors.expiry_date}
        />
      </Grid>
    ) : null;

  return (
    <Container maxWidth={false} className="p-0">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showModal}>
          <Box sx={style} className="form-outers karuModal">
            <Typography
              id="transition-modal-title"
              variant="h5"
              className="inner-headings"
              component="h2"
            >
              {id ? "Edit Document Entry" : "Add Document Entry"}
            </Typography>
            <HighlightOffIcon
              className="closeicon"
              onClick={() => {
                setShowModal(false);
                setMachineEntryEditId("");
              }}
            />

            <Box>
              <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Document Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Document Type"
                        value={formData.document_type}
                        onChange={handleDocumentTypeChange}
                        disabled={!!id}
                      >
                        {machineDocuments?.map((type: any) => (
                          <MenuItem key={type?._id} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                      {formErrors.document_type && (
                        <FormHelperText>
                          {formErrors.document_type}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {/* {(selectedDocType as unknown as { images: boolean })?.images && ( */}

                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        id="images"
                        name="images"
                        label={labelText}
                        type="file"
                        className="accessory_img"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          accept: ".pdf, .csv, .jpg, .jpeg, .png, .gif",
                        }}
                        autoComplete="images"
                        onChange={handleChangeImage}
                        error={!!formErrors.images}
                        helperText={formErrors.images}
                      />
                      <Error current_key="images" />
                    </Grid>
                    {/* )} */}
                    <Dialog open={openDialog} onClose={handleClose}>
                      <DialogTitle>Previous Images</DialogTitle>
                      <DialogContent>
                        {previousImages.map((image, index) => (
                          <div
                            style={{ marginBottom: "16px" }}
                            key={image.path}
                          >
                            <p>
                              Expiry Date:{" "}
                              {new Date(
                                image.expiry_date * 1000
                              ).toLocaleDateString()}
                            </p>
                            <p>Insurance Amount: {image.insurance_amount}</p>
                            <p>
                              Created At:{" "}
                              {new Date(
                                image.createdAt * 1000
                              ).toLocaleDateString()}
                            </p>
                          </div>
                        ))}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                      </DialogActions>
                    </Dialog>
                    <Grid item xs={12} className="imageDiv">
                      {formData?.image_url?.length > 0
                        ? formData.image_url.map(
                            (image: string, index: number) => (
                              <div
                                key={image}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="company-logo">
                                  <img
                                    src={image}
                                    alt={`img-${index}`}
                                    className="preview-img"
                                  />
                                  <button
                                    className="closeimg"
                                    style={{
                                      display: "flex",
                                      border: "none",
                                      background: "none",
                                    }}
                                    onClick={() => handleRemoveImage(index)}
                                    title="Remove Image"
                                  >
                                    <ClearIcon />
                                  </button>
                                </div>
                                <Button href={image}>Download</Button>
                              </div>
                            )
                          )
                        : ""}
                    </Grid>

                    <Grid container spacing={2}>
                      {selectedDocType && insuranceAmountField}

                      {selectedDocType &&
                      (selectedDocType as { expiry_date: boolean })
                        .expiry_date ? (
                        <Grid item xs={6} md={6} mt={2}>
                          <TextField
                            fullWidth
                            id="expiry_date"
                            required
                            label="Expiry Date"
                            name="expiry_date"
                            type="date"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="expiry_date"
                            value={formData.expiry_date}
                            onChange={handleChange}
                            error={!!formErrors.expiry_date}
                            helperText={formErrors.expiry_date}
                            InputProps={{ inputProps: { min: today } }}
                          />
                        </Grid>
                      ) : (
                        expiryDateField
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} mt={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="save-btn"
                      onSubmit={handleSubmit}
                      style={{ marginRight: "10px" }}
                      disabled={submittedWithoutImage}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="close-btn"
                      onClick={() => {
                        setShowModal(false);
                        setMachineEntryEditId("");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default memo(AddEditDocument);
