import {
  FC,
  SyntheticEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Autocomplete,
  Divider,
  Fade,
  Grid,
  Modal,
  TextField,
  Paper,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { OrgState } from "../../../types/CommonTypes";
import {
  Country,
  State,
  City,
  IState,
  ICountry,
  ICity,
} from "country-state-city";
import ClearIcon from "@mui/icons-material/Clear";
import {
  setError,
  useCreateOrganizationMutation,
  useEditOrganizationMutation,
  useGetOrganizationByIdQuery,
} from "../../../redux-services";
import { useDispatch } from "react-redux";
import Error from "../../../components/ErrorBox/Error";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { kMaxLength } from "buffer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  borderRadius: 2,
  p: 2,
};

interface FormErrors {
  organization_name?: string;
  email?: string;
  address?: string;
  postal_code?: string;
  contact_no?: string;
  country?: string;
  state?: string;
  city?: string;
  pan_card_number?: string;
  aadhar_card_number?: string;
}

const AddEditOrg: FC<{
  open: boolean;
  handleModel: any;
  editId: string | any;
}> = ({ open, handleModel, editId }) => {
  const dispatch = useDispatch();
  const intialState: OrgState = {
    organization_name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    images: [],
    image_url: [],
    logo_image: [],
    logo_image_url: [],
    urls: [],
    logo_urls: [],
    deleted_images: [],
    ccode: "+91",
    iute164_phone: "",
    contact_no: "",
    pan_card_number: "",
    aadhar_card_number: "",
  };
  const [addressData, setAddressData] = useState<any>({
    country: [],
    state: [],
    city: [],
  });
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  const [formData, setFormData] = useState<OrgState>(intialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [defaultCountry, setDefaultCountry] = useState<any>(null);
  const [defaultState, setDefaultState] = useState<any>(null);
  const [defaultCity, setDefaultCity] = useState<any>(null);
  const [addressCode, setAddressCode] = useState<any>({
    countryCode: "",
    stateCode: "",
  });

  const { data: organizationDetail } = useGetOrganizationByIdQuery(editId, {
    skip: !editId,
  });

  const [editOrganization, editResult] = useEditOrganizationMutation();
  const [createOrganization, result] = useCreateOrganizationMutation();
  useEffect(() => {
    const fetchCountries = async () => {
      const countries = Country.getAllCountries() ?? [];
      setAddressData((prev: any) => ({
        ...prev,
        country: countries,
      }));

      const india = countries.find((country: any) => country.name === "India");
      if (india) {
        setDefaultCountry(india);
        setFormData((prev) => ({
          ...prev,
          country: india.name,
        }));
        setAddressCode((prev: any) => ({
          ...prev,
          countryCode: india.isoCode,
        }));
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    if (defaultCountry !== undefined) {
      setAddressCode({ ...addressCode, countryCode: defaultCountry?.isoCode });
    }
  }, [defaultCountry]);

  useEffect(() => {
    if (defaultState !== undefined) {
      setAddressCode({ ...addressCode, stateCode: defaultState?.isoCode });
    }
  }, [defaultState]);

  useEffect(() => {
    const stateList = State.getStatesOfCountry(addressCode?.countryCode);
    if (stateList.length > 0) {
      setDefaultState(
        stateList.find((state: any) => state.name === formData?.state)
      );
    }
    const cityList = City.getCitiesOfState(
      addressCode.countryCode,
      addressCode.stateCode
    );
    if (cityList.length > 0) {
      setDefaultCity(
        cityList.find((city: any) => city.name === formData?.city)
      );
    }
  }, [addressCode]);

  useEffect(() => {
    setAddressData({
      ...addressData,
      country: Country.getAllCountries() ?? [],
      state: State.getStatesOfCountry(addressCode.countryCode) ?? [],
      city:
        City.getCitiesOfState(addressCode.countryCode, addressCode.stateCode) ??
        [],
    });
  }, [Country, State, City, addressCode]);

  useEffect(() => {
    if (organizationDetail?.status === 200) {
      let data = organizationDetail?.response[0];
      const country = data?.country ?? "";
      const state = data?.state ?? "";
      const city = data?.city ?? "";
      setFormData({
        ...formData,
        organization_name: data?.organization_name ?? "",
        email: data?.email ?? " ",
        address: data?.address ?? "",
        country: country,
        state: state,
        city: city,
        ccode: data?.ccode ?? "",
        iute164_phone: (data?.ccode ?? "") + (data?.contact_no ?? ""),
        contact_no: data?.contact_no ?? "",
        postal_code: data?.postal_code ?? "",
        images: data?.images?.length
          ? data.images.map((image: any) => image.path)
          : [],
        image_url: data?.encodedUrl?.length ? data.encodedUrl : [],
        urls: data.images?.length
          ? data?.images.map((image: any) => image.fullUrl)
          : [],
        logo_image: data?.logo_image?.length
          ? data.logo_image.map((image: any) => image.path)
          : [],
        logo_image_url: data?.logoEncodedUrl?.length ? data.logoEncodedUrl : [],
        logo_urls: data.logo_image?.length
          ? data.logo_image.map((image: any) => image.fullUrl)
          : [],
        pan_card_number: data?.pan_card_number ?? "",
        aadhar_card_number: data?.aadhar_card_number ?? "",
      });
      const countries = Country.getAllCountries() ?? [];
      setAddressData((prev:any) => ({
        ...prev,
        country: countries,
      }));
  
      const india = countries.find((country) => country.name === "India");
      if (india) {
        setDefaultCountry(india);
        setAddressCode((prev:any) => ({
          ...prev,
          countryCode: india.isoCode,
        }));
  
        const stateList = State.getStatesOfCountry(india.isoCode);
        const matchedState = stateList.find((s) => s.name === state);
        setDefaultState(matchedState);
  
        const cityList = City.getCitiesOfState(india.isoCode, matchedState?.isoCode || 'defaultIsoCode');
        const matchedCity = cityList.find((c) => c.name === city);
        setDefaultCity(matchedCity);
      }
    }
    return () => {
      dispatch(setError([]));
    };
  }, [organizationDetail]);

  const handleCountryChange = (data: ICountry) => {
    setFormData({
      ...formData,
      country: data !== null ? data.name : "",
      state: "",
      city: "",
    });

    const stateList = State.getStatesOfCountry(data?.isoCode);
    setAddressData({ ...addressData, state: stateList });
    setDefaultCountry(data);
    setDefaultState(null);
    setDefaultCity(null);
    setAddressCode({ ...addressCode, countryCode: data?.isoCode });
  };

  const handleStateChange = (data: IState) => {
    const cityList =
      City.getCitiesOfState(data.countryCode, data.isoCode) ?? [];
    setFormData({
      ...formData,
      state: data !== null ? data.name : "",
      city: "",
    });
    setAddressData({ ...addressData, city: cityList });
    setDefaultState(data);
    setDefaultCity(null);
    setAddressCode({ ...addressCode, stateCode: data?.isoCode });
  };

  const handleCityChange = (data: ICity) => {
    setDefaultCity(data);
    setFormData({ ...formData, city: data !== null ? data.name : "" });
  };

  const handleChangeImage = (event: any) => {
    let setFileUrl: string[] = [];
    let file = Array.from(event.target.files) as File[];

    const containsGif = file.some((f: File) => f.type === "image/gif");
    if (containsGif) {
      alert("GIF images are not allowed");

      const thumbnailElement = document.getElementById(
        "images"
      ) as HTMLInputElement | null;
      if (thumbnailElement) {
        thumbnailElement.value = "";
      }
      return;
    }

    for (const element of event.target.files) {
      setFileUrl.push(URL.createObjectURL(element));
    }
    setFormData({
      ...formData,
      images: [...file],
      image_url: [...setFileUrl],
    });
    const thumbnailElement = document.getElementById(
      "images"
    ) as HTMLInputElement | null;

    if (thumbnailElement) {
      thumbnailElement.value = "";
    }
  };
  const handleChangeLogoImage = (event: any) => {
    let setFileUrl: string[] = [];
    let file = Array.from(event.target.files) as File[];

    const containsGif = file.some((f: File) => f.type === "image/gif");
    if (containsGif) {
      alert("GIF images are not allowed");

      const thumbnailElement = document.getElementById(
        "logo_image"
      ) as HTMLInputElement | null;
      if (thumbnailElement) {
        thumbnailElement.value = "";
      }
      return;
    }

    for (const element of event.target.files) {
      setFileUrl.push(URL.createObjectURL(element));
    }

    setFormData({
      ...formData,
      logo_image: [...file],
      logo_image_url: [...setFileUrl],
    });

    const thumbnailElement = document.getElementById(
      "logo_image"
    ) as HTMLInputElement | null;
    if (thumbnailElement) {
      thumbnailElement.value = "";
    }
  };

  const handleRemoveImage = useCallback(
    (index: number) => {
      const updatedImages = [...formData.images];
      updatedImages.splice(index, 1);

      let deletedImgArr = [];
      if (formData.urls?.length) {
        deletedImgArr.push(formData.urls[index]);
      }

      const updatedFiles = [...formData.image_url];
      updatedFiles.splice(index, 1);
      setFormData({
        ...formData,
        images: updatedImages,
        image_url: updatedFiles,
        deleted_images: [...formData.deleted_images, ...deletedImgArr],
      });
    },
    [formData]
  );

  const handleRemoveLogoImage = useCallback(
    (index: number) => {
      const updatedImages = [...formData.logo_image];
      updatedImages.splice(index, 1);

      let deletedImgArr = [];
      if (formData.urls?.length) {
        deletedImgArr.push(formData.urls[index]);
      }

      const updatedFiles = [...formData.logo_image_url];
      updatedFiles.splice(index, 1);
      setFormData({
        ...formData,
        logo_image: updatedImages,
        logo_image_url: updatedFiles,
        deleted_images: [...formData.deleted_images, ...deletedImgArr],
      });
    },
    [formData]
  );

  useEffect(() => {
    if (editResult?.data?.status === 200) {
      handleModel(false);
      dispatch(setError([]));
    } else if (editResult?.data?.status === 500) {
      const errorMessage = editResult.data?.error;
      if (errorMessage.includes("this email")) {
        setFieldErrors({ email: errorMessage });
      } else if (errorMessage.includes("this name")) {
        setFieldErrors({ organization_name: errorMessage });
      } else {
        setFieldErrors({ general: errorMessage });
      }
    }
  }, [editResult, handleModel, dispatch]);

  useEffect(() => {
    if (result?.data?.status === 200) {
      handleModel(false);
      dispatch(setError([]));
    } else if (result?.data?.status === 500) {
      const errorMessage = result.data?.error;
      if (errorMessage.includes("this email")) {
        setFieldErrors({ email: errorMessage });
      } else if (errorMessage.includes("this name")) {
        setFieldErrors({ organization_name: errorMessage });
      } else {
        setFieldErrors({ general: errorMessage });
      }
    }
  }, [result, handleModel, dispatch]);

  const validate = (formData: OrgState) => {
    const errors: Partial<OrgState> = {};

    const requiredField = <K extends keyof Partial<OrgState>>(field: K, message: string) => {
        if (!formData[field].trim()) {
            errors[field] = message;
        }
    };

    const validatePostalCode = (postalCode: string) => {
        if (!/^\d{6}$/.test(postalCode.trim())) {
            errors.postal_code = "Postal Code must be exactly 6 digits long!";
        }
    };

    const validateEmail = (email: string) => {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i.test(email)) {
            errors.email = "Invalid email format!";
        }
    };

    const validateContactNo = (contactNo: string) => {
        if (contactNo.length !== 10 || !/^\d{10}$/.test(contactNo)) {
            errors.contact_no = "Contact number must be exactly 10 digits long!";
        } else if (!/^([6789]\d{9})$/.test(contactNo)) {
            errors.contact_no = "For Indian numbers, the contact number must start with digits 6, 7, 8, or 9!";
        }
    };

    requiredField("organization_name", "Organization Name is required!");
    requiredField("address", "Address is required!");
    requiredField("postal_code", "Postal Code is required!");
    validatePostalCode(formData.postal_code);

    requiredField("email", "Email is required!");
    validateEmail(formData.email);

    if (!editId) {
        requiredField("contact_no", "Contact number is required!");
        if (formData.contact_no.trim()) {
            validateContactNo(formData.contact_no.trim());
        }
    }

    requiredField("country", "Country is required!");
    requiredField("state", "State is required!");
    requiredField("city", "City is required!");

    const aadhar = formData.aadhar_card_number?.trim();
    if (aadhar && (aadhar.length !== 12 || !/^\d{12}$/.test(aadhar))) {
        errors.aadhar_card_number = "Aadhar card number must be exactly 12 digits long!";
    }

    const pan = formData.pan_card_number?.trim();
    if (pan && !/^[A-Z]{5}\d{4}[A-Z]$/.test(pan)) {
        errors.pan_card_number = "PAN card number must be in the format ABCDE1234F!";
    }

    return errors;
};


  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.trimStart(),
    }));

    const errors = validate({ ...formData, [name]: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors[name as keyof OrgState],
    }));
  };

  const onPhoneChange = (value: string, parsedNumber: MuiTelInputInfo) => {
    let contactNo = parsedNumber?.nationalNumber ?? "";
    let iute164Phone = parsedNumber?.numberValue ?? "";
    let ccode = "+" + (parsedNumber?.countryCallingCode ?? "");
    const updatedFormData = {
      ...formData,
      contact_no: contactNo,
      iute164_phone: iute164Phone,
      ccode: ccode,
    };

    const contactNoErrors = validate({
      ...formData,
      contact_no: contactNo,
    });

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      contact_no: contactNoErrors.contact_no,
    }));

    setFormData(updatedFormData);
  };

  const handleSubmit = (
    event: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    event.preventDefault();
    const checkError = validate(formData);
    if (Object.values(checkError)?.length > 0) {
      setFormErrors(checkError);
    } else {
      setFormErrors({});
      let data = new FormData();
      for (const item of formData.images) {
        data.append("images", item);
      }
      for (const item of formData.logo_image) {
        data.append("logo_image", item);
      }
      if (editId) {
        for (const item of formData?.deleted_images ?? []) {
          data.append("deleted_images", item);
        }
      }
      data.append("organization_name", formData.organization_name.trim());
      data.append("postal_code", formData?.postal_code);
      data.append("email", formData.email.toLowerCase().trim());
      data.append("address", formData.address.trim());
      data.append("country", formData.country);
      data.append("state", formData.state);
      data.append("city", formData.city);
      data.append("ccode", formData.ccode);
      data.append("contact_no", formData.contact_no.trim());
      data.append("aadhar_card_number", formData.aadhar_card_number.trim());
      data.append("pan_card_number", formData.pan_card_number.trim());
      data.append("id", editId || (null as any));
      editId ? editOrganization(data) : createOrganization(data);
    }
  };

  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="form-outers karuModal">
            <Typography
              id="transition-modal-title"
              variant="h5"
              className="inner-headings"
              component="h2"
            >
              {editId ? "Edit" : "Add"} Organization
            </Typography>
            <HighlightOffIcon
              className="closeicon"
              onClick={() => handleModel(false)}
            />

            <Box className="modalBody">
              <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="organization_name"
                      label="Organization Name *"
                      name="organization_name"
                      type="text"
                      size="small"
                      autoComplete="organization_name"
                      value={formData.organization_name}
                      onChange={handleChange}
                      error={
                        !!(
                          formErrors.organization_name ??
                          fieldErrors.organization_name
                        )
                      }
                      helperText={
                        formErrors.organization_name ??
                        fieldErrors.organization_name
                      }
                    />
                    <Error current_key="organization_name" />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="address"
                      label="Address *"
                      name="address"
                      type="text"
                      size="small"
                      autoComplete="address"
                      value={formData.address}
                      onChange={handleChange}
                      error={!!formErrors.address}
                      helperText={formErrors.address}
                    />
                    <Error current_key="address" />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="country-field"
                      label="Country"
                      value={defaultCountry ? defaultCountry.name : ""}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      error={!!formErrors.country}
                      helperText={formErrors.country}
                    />
                    {formErrors.country && (
                      <Typography variant="body1" className="error-alert-msg">
                        {formErrors.country}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      id="country-select-demo"
                      options={addressData.state}
                      size="small"
                      onChange={(event, newValue) => {
                        handleStateChange(newValue);
                      }}
                      PaperComponent={({ children }) => (
                        <Paper style={{ background: "#0a243d" }}>
                          {children}
                        </Paper>
                      )}
                      value={defaultState}
                      autoHighlight
                      getOptionLabel={(option: any) => option?.name}
                      renderOption={(props, option: any) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option?.name} ({option?.isoCode})
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose a State"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          required={formErrors?.state ? false : true}
                        />
                      )}
                    />
                    {formErrors.state && (
                      <Typography variant="body1" className="error-alert-msg">
                        {formErrors.state}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      id="country-select-demo"
                      options={addressData.city}
                      size="small"
                      onChange={(event, newValue) => {
                        handleCityChange(newValue);
                      }}
                      PaperComponent={({ children }) => (
                        <Paper style={{ background: "#0a243d" }}>
                          {children}
                        </Paper>
                      )}
                      value={defaultCity}
                      autoHighlight
                      getOptionLabel={(option: any) => option?.name}
                      renderOption={(props, option: any) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option?.name} ({option?.stateCode})
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose a City"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          required={formErrors?.city ? false : true}
                        />
                      )}
                    />
                    {formErrors.city && (
                      <Typography variant="body1" className="error-alert-msg">
                        {formErrors.city}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="postal_code"
                      label="Postal Code *"
                      name="postal_code"
                      type="text"
                      size="small"
                      inputProps={{maxLength:6}}
                      autoComplete="postal_code"
                      value={formData.postal_code}
                      onChange={handleChange}
                      error={!!formErrors.postal_code}
                      helperText={formErrors.postal_code}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="email"
                      label="Email Address *"
                      name="email"
                      type="text"
                      size="small"
                      InputProps={{
                        readOnly: editId 
                      }}
                      autoComplete="email"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!(formErrors.email ?? fieldErrors.email)}
                      helperText={formErrors.email ?? fieldErrors.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MuiTelInput
                      value={formData?.iute164_phone}
                      onChange={onPhoneChange}
                      defaultCountry={"IN"}
                      size="small"
                      fullWidth={true}
                      inputProps={{maxLength:15}}
                      error={!!formErrors.contact_no}
                      helperText={formErrors.contact_no}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="aadhar_card_number"
                      label="Aadhar Card"
                      name="aadhar_card_number"
                      type="text"
                      size="small"
                      inputProps={{maxLength:12}}
                      autoComplete="aadhar_card_number"
                      value={formData.aadhar_card_number}
                      onChange={handleChange}
                      error={!!formErrors.aadhar_card_number}
                      helperText={formErrors.aadhar_card_number}
                    />
                    <Error current_key="aadhar_card_number" />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="pan_card_number"
                      label="Pan Card"
                      name="pan_card_number"
                      type="text"
                      size="small"
                      autoComplete="pan_card_number"
                      value={formData.pan_card_number}
                      inputProps={{maxLength:10}}
                      onChange={handleChange}
                      error={!!formErrors.pan_card_number}
                      helperText={formErrors.pan_card_number}
                    />
                    <Error current_key="pan_card_number" />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="images"
                      name="images"
                      label="Choose profile image"
                      type="file"
                      className="sites_img"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ accept: "image/*" }}
                      autoComplete="images"
                      onChange={handleChangeImage}
                    />
                    <Error current_key="images" />
                    <Grid item xs={12} className="imageDiv">
                      {formData?.image_url?.length
                        ? formData.image_url.map(
                            (image: string, index: number) => (
                              <div key={index}>
                                <img
                                  src={image}
                                  alt={`sites-img-${index}`}
                                  className="preview-img"
                                />
                                <div
                                  onClick={() => handleRemoveImage(index)}
                                  title="Remove Image"
                                >
                                  <ClearIcon />
                                </div>
                              </div>
                            )
                          )
                        : ""}
                    </Grid>
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <TextField
                      fullWidth
                      id="logo_image"
                      name="logo_image"
                      label="Choose Logo"
                      type="file"
                      className="sites_img"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ accept: "image/*" }}
                      autoComplete="logo_image"
                      onChange={handleChangeLogoImage}
                    />
                    <Error current_key="images" />
                    <Grid item xs={12} className="imageDiv" marginTop={2}>
                      {formData?.logo_image_url?.length
                        ? formData.logo_image_url.map(
                            (image: string, index: number) => (
                              <div key={index}>
                                <img
                                  src={image}
                                  alt={`sites-img-${index}`}
                                  className="preview-img"
                                />
                                <div
                                  onClick={() => handleRemoveLogoImage(index)}
                                  title="Remove Image"
                                >
                                  <ClearIcon />
                                </div>
                              </div>
                            )
                          )
                        : ""}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                  <Divider />
                </Grid>
                <Grid item xs={12} marginTop={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="save-btn"
                    style={{ marginRight: "10px" }}
                    disabled={Object.values(formErrors).some(
                      (error) => !!error
                    )}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="close-btn"
                    onClick={() => handleModel(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
  );
};
export default memo(AddEditOrg);
