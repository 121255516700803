import { createApi } from "@reduxjs/toolkit/query/react";

import { AirPressureCheck, PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";


export const AirPressureCheckApi = createApi({
    reducerPath: "airPressureCheckApi",

    baseQuery: axiosBaseQuery(),

    tagTypes: ["airPressureChecksTag"],

    endpoints: (builder) => ({
        airPressureCheckList: builder.query<Response<PaginatedData<any>>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/airpressurecheck/getlist`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                },
            }),
            providesTags: ["airPressureChecksTag"],
        }),

        createAirPressureCheck: builder.mutation<Response<any>, AirPressureCheck>({
            query: (data: AirPressureCheck) => ({
                url: `/airpressurecheck/create`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["airPressureChecksTag"],
        }),

        getAirPressureCheckById: builder.query<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/airpressurecheck/${id}`,
                method: "GET",
            }),
            providesTags: ["airPressureChecksTag"],
        }),

        editAirPressureCheck: builder.mutation<Response<any>, AirPressureCheck>({
            query: (data: AirPressureCheck) => ({
                url: `/airpressurecheck/edit/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["airPressureChecksTag"],
        }),
        deleteAirPressureCheck: builder.mutation<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/airpressurecheck/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["airPressureChecksTag"],
        }),
    })
});

export const {
    useAirPressureCheckListQuery,
    useCreateAirPressureCheckMutation,
    useGetAirPressureCheckByIdQuery,
    useEditAirPressureCheckMutation,
    useDeleteAirPressureCheckMutation,
} = AirPressureCheckApi;
