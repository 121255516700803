import { createApi } from "@reduxjs/toolkit/query/react";

import { Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const FrequentCallApi = createApi({
  reducerPath: "frequentCallApi",

  baseQuery: axiosBaseQuery(),

  keepUnusedDataFor: 0,

  tagTypes: ["frequentCallTag"],

  endpoints: (builder) => ({
    findPreviousFuel: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/fuel/findPreviousRecord/${id}`,
        method: "GET",
      }),
      providesTags: ["frequentCallTag"],
    }),

    getUserDetail: builder.query<Response<any>, void>({
      query: () => ({
        url: `/user/user_detail`,
        method: "GET",
      }),
      providesTags: ["frequentCallTag"],
    }),

    findPreviousMachineService: builder.query<
      Response<any>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/machine_service/previous-reading/${id}`,
        method: "GET",
      }),
      providesTags: ["frequentCallTag"],
    }),

    getMachineryDetail: builder.query<Response<any>, void>({
      query: () => ({
        url: `/machine_info/machinery_name`,
        method: "GET",
      }),
      providesTags: ["frequentCallTag"],
    }),

    getMachineryNamesBySiteId: builder.query<Response<any>, string | undefined>(
      {
        query: (siteId: string | undefined) => ({
          url: `/machine_info/machinery_data/${siteId}`,
          method: "GET",
        }),
        providesTags: ["frequentCallTag"],
      }
    ),

    getUserRoles: builder.query<Response<any>, any>({
      query: (data: { orgId: string }) => ({
        url: `/user_role/role_name`,
        method: "GET",
        params: {
          orgId: data.orgId,
        },
      }),
      providesTags: ["frequentCallTag"],
    }),

    getMachineNamesForMachineLog: builder.query<Response<any>, any>({
      query: (data: any) => ({
        url: `/machine_log/machinery_data`,
        method: "GET",
        params: {
          site_id: data.site_id,
          work_item_id: data.work_item_id,
        },
      }),
      providesTags: ["frequentCallTag"],
    }),
  }),
});

export const {
  useFindPreviousFuelQuery,
  useGetUserDetailQuery,
  useFindPreviousMachineServiceQuery,
  useGetMachineryDetailQuery,
  useGetUserRolesQuery,
  useGetMachineryNamesBySiteIdQuery,
  useGetMachineNamesForMachineLogQuery,
} = FrequentCallApi;
