import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./AxiosBaseQuery";
import { Response } from "../../types/CommonTypes";
export const MachineDocApi = createApi({
  reducerPath: "machineDocApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["machineDocTypes"],
  endpoints: (builder) => ({
    createMachineDoc: builder.mutation<any, FormData>({
      query: (data: FormData) => ({
        url: `/machine_docs/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["machineDocTypes"],
    }),
    getAllMachineDocs: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/machine_docs/getDocument/${id}`,
        method: "GET",
      }),
      providesTags: ["machineDocTypes"],
    }),
    deleteMachineDoc: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/machine_docs/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["machineDocTypes"],
    }),
    getDocumentInfoById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/machine_docs/getDocInfo/${id}`,
        method: "GET",
      }),
      providesTags: ["machineDocTypes"],
    }),
    updateMachineDocumentStatus: builder.mutation<
      Response<any>,
      { id: string; status: boolean }
    >({
      query: ({ id, status }) => ({
        url: `/machine_info/updateStatus/${id}`,
        method: "POST",
        body: { status },
      }),
      invalidatesTags: ["machineDocTypes"],
    }),
    editMachineDocs: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/machine_docs/editMachineDoc/${data.get("id")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["machineDocTypes"],
    }),
  }),
});

export const {
  useCreateMachineDocMutation,
  useGetAllMachineDocsQuery,
  useUpdateMachineDocumentStatusMutation,
  useDeleteMachineDocMutation,
  useGetDocumentInfoByIdQuery,
  useEditMachineDocsMutation,
} = MachineDocApi;
