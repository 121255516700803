import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const WorkItemApi = createApi({
  reducerPath: "workItemApi",

  baseQuery: axiosBaseQuery(),

  tagTypes: ["workItemTag"],

  endpoints: (builder) => ({
    workItemList: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/work_item/list`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
          select_date: pageData.select_date ?? "",
          shift_id: pageData.shift_id ?? "",
          id: pageData.id ?? "",
          organization_id: pageData.organization_id,
        },
      }),
      providesTags: ["workItemTag"],
    }),

    createWorkItem: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/work_item/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["workItemTag"],
    }),

    getWorkItemById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/work_item/${id}`,
        method: "GET",
      }),
      providesTags: ["workItemTag"],
    }),

    editWorkItem: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/work_item/edit/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["workItemTag"],
    }),
    deleteWorkItem: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/work_item/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["workItemTag"],
    }),
    getWorkItemDetail: builder.query<Response<any>, void>({
      query: () => ({
        url: `/work_item/work_item_list`,
        method: "GET",
      }),
      providesTags: ["workItemTag"],
    }),

    findPreviousWorkItem: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/work_item/previous_work_item`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["workItemTag"],
    }),
  }),
});

export const {
  useWorkItemListQuery,
  useCreateWorkItemMutation,
  useEditWorkItemMutation,
  useGetWorkItemByIdQuery,
  useDeleteWorkItemMutation,
  useGetWorkItemDetailQuery,
  useFindPreviousWorkItemMutation,
} = WorkItemApi;
