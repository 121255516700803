import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, StyledEngineProvider, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Tooltip, Typography, Alert } from '@mui/material'
import React, { FC, useCallback, useState } from 'react'
import { useDailyLogListQuery, useGetSitesByIdQuery, useDownloadEmployeeAttendanceQuery } from '../../../../redux-services';
import { TableHeadElement } from '../../../../components/TableBody/TableHeadElement';
import { TableBodyElement } from '../../../../components/TableBody/TableBodyElement';
import { ColumnsType, Order, PageMeta, SortingData } from '../../../../types/CommonTypes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditAttendance from './EditAttendance';
import EmpMarkAttendance from './EmpMarkAttendance';
import DuplicateAttendance from './DuplicateAttendance';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import * as XLSX from 'xlsx'; // Importing xlsx library for Excel generation

export interface UserState {
    site_id: any;
    shift_id: any;
    date: string | Record<string, any>;
    select_date: string;
    to_date: string; // Added to_date for range selection
}

const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "employee_name",
        sort: true,
        label: "Employee Name",
    },
    {
        id: "contact_no",
        sort: true,
        label: "Contact No",
    },
    {
        id: 'shift',
        sort: false,
        label: "Shift Name",
    },
    {
        id: "attendance_status",
        sort: false,
        label: "Attendance Status",
    },
    {
        id: "comment",
        sort: false,
        label: "Comment"
    },
    {
        id: "createdAt",
        sort: false,
        label: "Date"
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "15%" },
    },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const TakeAttendance: FC<{ open: boolean, setOpenAttendanceModal: any, siteInfo: { id: string, name: string } }> = ({ open, setOpenAttendanceModal, siteInfo }) => {

    const organization_id = useSelector((state: RootState) => state.auth.siteOrgId);
    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,

    };
    const intialState = {
        site_id: siteInfo,
        shift_id: "",
        date: "",
        select_date: "",
        to_date: "", // Added to_date
    }
    let { id: siteId } = useParams();

    const [formData, setFormData] = useState<UserState>(intialState);
    const [showEmployeeList, setShowEmployeeList] = useState(false);
    const { data: getData } = useGetSitesByIdQuery(siteId);



    let navigate = useNavigate();
    let query = useLocation();

    const { id } = useParams();

    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const [userList, setUserList] = useState<PageMeta>(initialState);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [download, setDownload] = useState(false);

    const siteOrgId = useSelector((state: RootState) => state.auth.siteOrgId);

    const {data } = useDailyLogListQuery({
        per_page: userList.per_page ?? rowsPerPage,
        page: query.state ?? userList.page,
        order: userList.order ?? order,
        order_by: userList.order_by ?? orderBy,
        searchText: userList.searchText ?? "",
        select_date: formData?.select_date ?? '',
        shift_id: formData?.shift_id?._id ?? '',
        id: id,
        organization_id: organization_id ?? siteOrgId,
        site_id: siteInfo.id,
    });
   

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setUserList({
            ...userList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setUserList({ ...userList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setUserList({
            ...userList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const getTodayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');  
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;

        if (name === "date") {
            let inputDate = value;
            let date = new Date(inputDate);
            let currentTime = new Date();
            date.setHours(currentTime.getHours());
            date.setMinutes(currentTime.getMinutes());
            date.setSeconds(currentTime.getSeconds());
            date.setMilliseconds(currentTime.getMilliseconds());
            let unixTimeStamp = date.getTime();
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: unixTimeStamp
            }));
        }
        else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.trimStart()
            }));
        }
    };

    const handleShowEmployeeAttendanceModel = useCallback((isOpen: boolean) => {
        setShowEmployeeList(isOpen);
    }, []);

    const selectChange = (event: (SelectChangeEvent | any)) => {
        setFormData({
            ...formData,
            shift_id: event.target.value as string
        });
    };

    const handleMarkAttendance = () => {
        setDownload(false);
        const today = new Date();

        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        setShowEmployeeList(true);
        setFormData({ ...formData, date: formattedDate });
    }

    const handleDuplicateAttendanceModel = useCallback((isOpen: boolean) => {
        setShowDuplicateModal(isOpen);
    }, []);

    const handleModel = useCallback((isOpen: boolean) => {
        setOpenEdit(isOpen);
    }, []);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { refetch: downloadAttendance } = useDownloadEmployeeAttendanceQuery({
        select_date: formData.select_date,
        to_date: formData.to_date, 
        shift_id: formData.shift_id?._id ?? '',
        site_id: siteInfo.id,
    }, {skip: (!formData.select_date || !formData.to_date || !formData.shift_id?._id )}); // Using refetch instead of automatic query call

    
    const handleDownloadAttendance = async () => {
        setDownload(true);
        if (!formData.select_date || !formData.to_date || !formData.shift_id?._id) {
            setErrorMessage("Please select all fields (From Date, To Date, and Shift) to download attendance.");
            setTimeout(() => {
                setErrorMessage(null);
            }, 3000); 
            
            return; 
        }
        setErrorMessage(null);
        
        try {
            const response = await downloadAttendance(); 
            console.log("response of attendance",response?.data);
            if (response?.data?.response) {
                generateExcel(response.data.response); 
            }
        } catch (error) {
            console.error("Error downloading attendance:", error);
        }
    };

    // Excel generation function
    const generateExcel = (attendanceData: any) => {
        const worksheetData = attendanceData.map((log: any) => ({
            "Employee Number": log.employee_no,
            "Employee Name": log.employee_name,
            "In Date": log.in_date,
            "In Time": log.in_time,
            "Out Date": log.out_date,
            "Out Time": log.out_time,
        }));
        
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendance');

        // // Download Excel
        XLSX.writeFile(workbook, `Attendance_${formData.select_date}_to_${formData.to_date}.xlsx`);
        // console.log("Downloaded");
    };

    const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, to_date: e.target.value });
    };
    
    return (
        <>
            <Button
                variant="contained"
                color="primary"
                className="secondary-btn"
                style={{ maxWidth: '60px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                onClick={() => setOpenAttendanceModal(false)}
            >
                <ArrowBackIcon />
            </Button>
            {errorMessage && (
  <Alert severity="error">
    <Typography style={{ textAlign: "right", width: "100%" }}>
      {errorMessage}
    </Typography>
  </Alert>
)}


            <div className='table'>
                <Paper className="table-outers mt-3">
                    <Toolbar style={{ display: "flex", justifyContent: "flex-end" }}>
                        <div className="flex-boxinner" style={{ gap: "10px" }}>
                          { download &&  <TextField
                                id="select_date"
                                name="select_date"
                                label="Select Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    max: getTodayDate(),
                                }}
                                value={formData.select_date}
                                size='small'
                                onChange={handleDateChange}
                            />}
                           {download && <TextField
                                id="to_date"
                                name="to_date"
                                label="To Date"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={formData.to_date} // Added to_date field
                                size='small'
                                onChange={handleToDateChange}
                            />}
                            <Box sx={{ minWidth: 150 }}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">Select Shifts *</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formData.shift_id !== '' ? formData.shift_id : "None"}
                                        label="Select Shifts"
                                        required
                                        onChange={selectChange}
                                        renderValue={() => formData.shift_id !== "" ? formData.shift_id?.name : "None"}
                                    >
                                        <MenuItem value={""}>None</MenuItem>
                                        {getData && getData?.response?.shiftNames?.map(
                                            (item: any, index: number) => (
                                                <MenuItem value={item} key={index}>{item?.name}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <div>
                                <Tooltip title={`Mark Fresh Attendance`} arrow className="me-1 mt-1">
                                    <Button style={{ height: "100%" }} className="secondary-btn" onClick={handleMarkAttendance} type="submit" variant="contained" color="primary">Mark Fresh Attendace</Button>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={`Fetch With Previous`} arrow className="me-1 mt-1">
                                    <Button style={{ height: "100%" }} className="secondary-btn" onClick={() => { setDownload(false); setShowDuplicateModal(true); }} type="submit" variant="contained" color="primary">Fetch With Previous</Button>
                                </Tooltip>
                            </div>
                            <div>
                            <Tooltip title={`Download Attendance`} arrow className="me-1 mt-1">
                                <Button
                                    style={{ height: "100%" }}
                                    className="secondary-btn"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleDownloadAttendance}
                                >
                                    Download Attendance
                                </Button>
                            </Tooltip>
                            </div>
                        </div>
                    </Toolbar>
                    <StyledEngineProvider>
                        <TableContainer component={Paper} className="table-outers">
                            <Table
                                sx={{ minWidth: 650 }}
                                size="small" aria-label="a dense table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableHeadElement
                                            setHead={tableColumns ? tableColumns : []}
                                            order={order}
                                            orderBy={orderBy}
                                            sortFn={(event: any, id: string) =>
                                                createSortHandler(event, id as keyof SortingData)
                                            }
                                        />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableBodyElement
                                        selectedColumns={[
                                            "employee_name",
                                            "contact_no",
                                            "shift",
                                            "attendance_status",
                                            "comment",
                                            "createdAt",
                                        ]}
                                        setData={data?.response.data ? data?.response.data : []}
                                        editFn={(id: string) => {
                                            setOpenEdit(true);
                                            setEditId(id);
                                        }}
                                        pageData={{
                                            limit:
                                                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                            page: data?.response.page as number,
                                        }}
                                    />
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={data?.response.total ?? 0}
                                page={data?.response.page ?? 0}
                                rowsPerPageOptions={[20, 50, 100]}
                                onPageChange={handleChangePage}
                                rowsPerPage={
                                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                                }
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </StyledEngineProvider>
                </Paper>

                {openEdit &&
                    <EditAttendance
                        open={openEdit}
                        handleModel={handleModel}
                        siteInfo={siteInfo}
                        id={editId}
                    />
                }
                {showEmployeeList &&
                    <EmpMarkAttendance
                        open={showEmployeeList}
                        handleModel={handleShowEmployeeAttendanceModel}
                        siteInfo={siteInfo}
                    />
                }
                {showDuplicateModal &&
                    <DuplicateAttendance
                        open={showDuplicateModal}
                        handleModel={handleDuplicateAttendanceModel}
                        siteId={siteInfo.id ?? ''}
                        setAttendanceState={setFormData}
                    />
                }
            </div>
        </>
    )
}

export default TakeAttendance