import { createApi } from "@reduxjs/toolkit/query/react";

import {
  FuelType,
  PageMeta,
  PaginatedData,
  Response,
} from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const FuelTypeApi = createApi({
  reducerPath: "fuelTypeApi",

  baseQuery: axiosBaseQuery(),

  keepUnusedDataFor: 0,

  tagTypes: ["fuelTypeTag"],

  endpoints: (builder) => ({
    fuelTypeList: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/fuel_type`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
          id: pageData.id ?? "",
        },
      }),
      providesTags: ["fuelTypeTag"],
    }),

    createFuelType: builder.mutation<Response<any>, FuelType>({
      query: (data: FuelType) => ({
        url: `/fuel_type/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fuelTypeTag"],
    }),

    getFuelTypeById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/fuel_type/${id}`,
        method: "GET",
      }),
      providesTags: ["fuelTypeTag"],
    }),

    getFuelTypeList: builder.query<Response<any>, any>({
      query: (data: { machineId: string | any; orgId: string | any, siteId: string | any }) => ({
        url: `/fuel_type/fuel_type_list`,
        method: "GET",
        params: {
          machineId: data.machineId ?? "",
          orgId: data.orgId ?? "",
          siteId: data.siteId ?? "",
        },
      }),
      providesTags: ["fuelTypeTag"],
    }),
    getFuelTypeListbyOrgId: builder.query<Response<any>, string | undefined>({
      query: (orgId: string | undefined) => ({
        url: `/fuel_type/fuelType_list/${orgId}`,
        method: "GET",
      }),
      providesTags: ["fuelTypeTag"],
    }),

    editFuelType: builder.mutation<Response<any>, FuelType>({
      query: (data: FuelType) => ({
        url: `/fuel_type/edit/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["fuelTypeTag"],
    }),

    deleteFuelType: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/fuel_type/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["fuelTypeTag"],
    }),
    updateFuelTypeStatus: builder.mutation<
      Response<any>,
      { id: string; status: boolean }
    >({
      query: ({ id, status }) => ({
        url: `/fuel_type/updateStatus/${id}`,
        method: "POST",
        body: { status },
      }),
      invalidatesTags: ["fuelTypeTag"],
    }),
  }),
});

export const {
  useFuelTypeListQuery,
  useCreateFuelTypeMutation,
  useEditFuelTypeMutation,
  useGetFuelTypeByIdQuery,
  useDeleteFuelTypeMutation,
  useGetFuelTypeListQuery,
  useGetFuelTypeListbyOrgIdQuery,
  useUpdateFuelTypeStatusMutation,
} = FuelTypeApi;
