import React, { useState } from 'react';
import { TextField, Button, Grid, FormControlLabel, Checkbox, Box,  Typography, Divider, Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setError, useCreateMachineTypeMutation } from '../../../../redux-services';
import { useNavigate } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
export interface FormData {
    name: string;
    number_plate: boolean;
    volume_capacity: boolean;
    tank_capacity: boolean;
}

interface FormErrors {
    name?: string;
}

const AddSiteMachineType = () => {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };
    const navigate = useNavigate();

    const initialState: FormData = {
        name: "",
        number_plate: false,
        volume_capacity: false,
        tank_capacity: false
    };

    const dispatch = useDispatch();
    const [formData, setFormData] = useState<FormData>(initialState);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [submitted, setSubmitted] = useState(false);
    const [createMachineType] = useCreateMachineTypeMutation();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(setError([]));
        setSubmitted(true);
        let data: any = formData;

        let formattedData = {
            name: data?.name ?? "",
            number_plate: data?.number_plate ?? false,
            volume_capacity: data?.volume_capacity ?? false,
            tank_capacity: data?.tank_capacity ?? false
        }

        createMachineType(formattedData);
    };

    const validate = (formData: FormData) => {
        const errors: FormErrors = {};
        if (!formData.name.trim()) {
            errors.name = "Name is required!";
        }
        return errors;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value.trimStart()
        }));
        if (name === 'name') {
            const errors = validate({ ...formData, [name]: value });
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                [name]: errors[name as keyof FormErrors]
            }));
        }
    };

    return (
        <React.Fragment>
            <Container maxWidth={false} className='p-0'>
                {/* <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={open}> */}
                <Box sx={style} className='form-outers'>
                    <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                        Add Machine Type
                    </Typography>
                    <HighlightOffIcon className="closeicon" onClick={() => navigate('/admin/site/siteMachineInfo')} />

                    <Box>
                        <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="name"
                                        label="Name"
                                        name="name"
                                        size='small'
                                        autoComplete="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        error={!!formErrors.name}
                                        helperText={formErrors.name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox checked={formData.number_plate} onChange={handleChange} name="number_plate" />}
                                        label="Number Plate"
                                        sx={{ color: 'white' }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={formData.volume_capacity} onChange={handleChange} name="volume_capacity" />}
                                        label="Volume Capacity"
                                        sx={{ color: 'white' }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={formData.tank_capacity} onChange={handleChange} name="tank_capacity" />}
                                        label="Tank Capacity"
                                        sx={{ color: 'white' }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="save-btn"
                                        style={{ marginRight: '10px' }}
                                        disabled={Object.values(formErrors).some(error => !!error)}
                                        onClick={() => navigate('/admin/site/siteMachineInfo')}

                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="close-btn"
                                        onClick={() => navigate('/admin/site/siteMachineInfo')}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
                {/* </Fade> */}
                {/* </Modal> */}
            </Container>
        </React.Fragment>
    );
}

export default AddSiteMachineType;