import { FC, useEffect, useRef, useState } from "react";
import LeafMap, { IconOptions, LeafletEvent } from "leaflet";
import useLeafletMap from "../LeafLetMap/useLeafletMap";
import { MapConfig, MapIcon } from "../../ constants";

export interface LatLng {
  latitude: number;
  longitude: number;
}

const Maps: FC<any> = ({ setFormData, formData, siteName, siteLatLon }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [marker, setMarker] = useState<LeafMap.Marker | null>(null);
  let map = useLeafletMap(mapRef, MapConfig.tileLayer.uri);

  useEffect(() => {
    if (formData?.latitude && formData?.longitude && map) {
      const lat = parseFloat(formData.latitude);
      const lng = parseFloat(formData.longitude);

      if (!isNaN(lat) && !isNaN(lng)) {
        const newPosition = { lat, lng };
        const siteInfoRows = getSiteInfoRows(formData, lat, lng);
        const popupContent = createPopupContent(siteInfoRows);

        addOrUpdateMarker(newPosition, popupContent);
      }
      if (marker && (isNaN(lat) || isNaN(lng))) {
        map?.removeLayer(marker);
        setMarker(null);
      }
    } else {
      clearAllMarkers();
    }
  }, [formData?.latitude, formData?.longitude, siteName]);

  // Helper functions
  const getSiteInfoRows = (formData: any, lat: number, lng: number) => {
    if (formData.road_site) {
      return `
        <tr><td>Bore Name:</td><td>${formData.name}</td></tr>
        <tr><td>Lat:</td><td>${lat}</td></tr>
        <tr><td>Lon:</td><td>${lng}</td></tr>`;
    }
    return `
      <tr><td>Site Name:</td><td>${formData.name}</td></tr>
      <tr><td>Lat:</td><td>${lat}</td></tr>
      <tr><td>Lon:</td><td>${lng}</td></tr>`;
  };

  const createPopupContent = (siteInfoRows: string) => {
    return `
      <div class="c-tooltip">
        <table width="100%" class="vaolum-data-table">
          <thead><tr><th colspan='2'>Detail</th></tr></thead>
          <tbody>${siteInfoRows}</tbody>
        </table>
      </div>`;
  };

  const addOrUpdateMarker = (
    position: { lat: number; lng: number },
    popupContent: string
  ) => {
    if (marker) {
      map?.removeLayer(marker);
      setMarker(null);
    }

    const newMarker = LeafMap.marker(position, { draggable: true });
    newMarker.setIcon(LeafMap.icon(MapIcon as IconOptions));
    newMarker.bindPopup(popupContent).openPopup();

    newMarker.on("drag", handleMarkerDrag);
    map?.invalidateSize();
    map?.addLayer(newMarker);
    map?.flyTo(position, map.getZoom());

    setMarker(newMarker);
  };

  const handleMarkerDrag = (event: LeafletEvent) => {
    const newLatLng = event.target.getLatLng();
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      latitude: newLatLng.lat,
      longitude: newLatLng.lng,
    }));
  };

  const clearAllMarkers = () => {
    map?.eachLayer((layer) => {
      if (layer instanceof LeafMap.Marker) {
        map?.removeLayer(layer);
      }
    });
    setMarker(null);
  };

  return (
    <div
      ref={mapRef}
      id="map"
      style={{
        height: "400px",
        display:
          (Object.keys(formData).includes("road_site") &&
            formData.name !== "" &&
            formData.latitude &&
            formData.longitude) ||
          (!Object.keys(formData).includes("road_site") &&
            siteName !== "" &&
            formData.latitude &&
            formData.longitude)
            ? "block"
            : "none",
      }}
    ></div>
  );
};

export default Maps;
