import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import navigation from "../_nav";
import AppSidebarNav from './AppSidebarNav';
import { RootState } from "../store/store";

const Sidebar = ({ openSidebarToggle, OpenSidebar, CloseSidebar }: any) => {

    const userDetail = useSelector((state: RootState) => state.auth.user);
    const [navItem, setNavItem] = useState<any>([]);

    useEffect(() => {
        if (userDetail !== null) {
            if (userDetail?.organization_id === '') {
                if (userDetail?.role_type === 0) {
                    setNavItem(navigation?.superAdminNav);
                } else {
                    setNavItem(navigation?.subSuperAdminNav);
                }
            } else {
                let updatedOrgIdArr = [];
                if (navigation?.orgNav) {
                for (let nav of navigation.orgNav) {
                    if (nav?.name === 'Sites') {
                        nav.to = `/admin/organization/${userDetail?.organization_id}`
                        updatedOrgIdArr.push(nav);
                    }
                    else {
                        updatedOrgIdArr.push(nav);
                    }
                }}
                setNavItem(updatedOrgIdArr);
            }
        } else {
            setNavItem(navigation?.superAdminNav);
        }
    }, [userDetail]);


    return (
        <aside id="sidebar" className={openSidebarToggle ? "openSidebar" : ""}>
            {/* <span className='icon close_icon' onClick={() => OpenSidebar(openSidebarToggle)}>X</span> */}
            <AppSidebarNav items={navItem} CloseSidebar={CloseSidebar} />
        </aside>
    )
}

export default Sidebar;