import React, { useCallback, useEffect, useState } from 'react';
import { Container, TextField, Button, Grid, Card, CardContent, CardHeader, Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Paper, Autocomplete } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setError, useCreateSitesMutation, useEditSitesMutation, useFindOrganizationNamesQuery, useGetSitesByIdQuery } from '../../redux-services';
import Error from '../../components/ErrorBox/Error';
import ClearIcon from '@mui/icons-material/Clear';
import { Country, State, City } from 'country-state-city';


export interface SiteState {
    name: string;
    organization_id: any;
    latitude: string;
    location?: any;
    longitude: string;
    address: string;
    city: string;
    state: string;
    country: string;
    postal_code: string;
    images: any[];
    image_url: any[];
    urls?: any[];
    deleted_images?: any;
}
interface FormErrors {
    name?: string;
    address?: string;
    postal_code?: string;
    latitude?: string;
    longitude?: string

}
const AddEditSites = () => {

    const [addressData, setAddressData] = useState<any>({
        country: [],
        state: [],
        city: [],
    });

    const [addressCode, setAddressCode] = useState<any>({
        countryCode: '',
        stateCode: '',
    });

    const navigate = useNavigate();
    const { id } = useParams();

    const intialState = {
        name: "",
        organization_id: "",
        address: "",
        latitude: "",
        longitude: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        images: [],
        image_url: [],
        urls: [],
        deleted_images: [],
    }
    let dispatch = useDispatch();
    const [formData, setFormData] = useState<SiteState>(intialState);
    const [formErrors, setFormErrors] = useState<FormErrors>({});

    const [createSites, result] = useCreateSitesMutation();
    const [editSites, editResult] = useEditSitesMutation();

    const OrganizationData = useFindOrganizationNamesQuery();
    const { data: SitesData } = useGetSitesByIdQuery(id, {
        skip: !id,
    });
    const [defaultCountry, setDefaultCountry] = useState<any>(null);
    const [defaultState, setDefaultState] = useState<any>(null);
    const [defaultCity, setDefaultCity] = useState<any>(null);

    useEffect(() => {
        setDefaultCountry(addressData.country.find((country: any) => country.name === formData?.country));
    }, [formData])

    useEffect(() => {
        if (defaultCountry !== undefined) {
            setAddressCode({ ...addressCode, countryCode: defaultCountry?.isoCode });
        }
    }, [defaultCountry])

    useEffect(() => {
        if (defaultState !== undefined) {
            setAddressCode({ ...addressCode, stateCode: defaultState?.isoCode });
        }
    }, [defaultState])

    useEffect(() => {
        const stateList = State.getStatesOfCountry(addressCode?.countryCode);
        if (stateList.length > 0) {
            setDefaultState(stateList.find((state: any) => state.name === formData?.state))
        }
        const cityList = City.getCitiesOfState(addressCode.countryCode, addressCode.stateCode);
        if (cityList.length > 0) {
            setDefaultCity(cityList.find((city: any) => city.name === formData?.city))
        }
    }, [addressCode]);

    useEffect(() => {
        setAddressData({
            ...addressData,
            country: Country.getAllCountries() ?? [],
            state: State.getStatesOfCountry(addressCode.countryCode) ?? [],
            city: City.getCitiesOfState(addressCode.countryCode, addressCode.stateCode) ?? []
        })
    }, [Country, State, City, addressCode])

    useEffect(() => {
        if (SitesData?.status === 200) {
            let data: any = SitesData?.response ;
            setFormData({
                ...formData,
                name: data?.name ?? "",
                organization_id: data?.organization_id?? [],
                address: data?.address ?? "",
                city: data?.city ?? "",
                state: data?.state ?? "",
                country: data?.country ?? "",
                postal_code: data?.postal_code ?? "",
                images: data?.images?.length ? data.images.map((image: any) => image.path) : [],
                image_url: data?.encodedUrl?.length ? data.encodedUrl : [],
                urls: data.images?.length ? data?.images.map((image: any) => image.fullUrl) : [],
                latitude: data?.location?.coordinates ? String(data?.location?.coordinates[0]) : '',
                longitude: data?.location?.coordinates ? String(data?.location?.coordinates[1]) : '',
            });
        }
        return () => {
            dispatch(setError([]));
        };
    }, [SitesData]);

    if (result.isSuccess && result.data.status === 200) {
        navigate("/admin/sites");
    }

    if (editResult.isSuccess && editResult.data.status === 200) {
        navigate("/admin/sites");
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        dispatch(setError([]));
        let data = new FormData;
        for (const item of formData.images) {
            data.append("images", item);
        }
        if (id) {
            for (const item of formData.deleted_images) {
                data.append("deleted_images", item);
            }
        }

        data.append("name", formData.name.trim());
        data.append("organization_id", formData.organization_id?._id ?? "");
        data.append("organization_name", formData.organization_id?.organization_name ?? "");
        data.append('latitude', formData.latitude);
        data.append('longitude', formData.longitude);
        data.append("postal_code", formData.postal_code);
        data.append("address", formData.address.trim());
        data.append("country", formData.country.trim());
        data.append("state", formData.state.trim());
        data.append("city", formData.city.trim());
        data.append('id', id ? id : null as any);

        id ? editSites(data) : createSites(data);
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value.trimStart()
        }));

        const errors = validate({ ...formData, [name]: value });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errors[name as keyof SiteState]
        }));
    };



    const validate = (formData: SiteState) => {
        const errors: Partial<SiteState> = {};
        const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/;

        if (!formData.name.trim()) {
            errors.name = "Name is required!";
        } else if (regex.test(formData.name)) {
            errors.name = "Name cannot contain special characters!";
        }
        if (!formData.latitude.trim()) {
            errors.latitude = "Latitude is required!";
        }
        if (!formData.longitude.trim()) {
            errors.longitude = "Longitude is required!";
        }
        if (!formData.address.trim()) {
            errors.address = "Address is required!";
        }

        if (!formData.postal_code.trim()) {
            errors.postal_code = "Postal Code is required!";
        } else if (!/^\d+$/.test(formData.postal_code.trim())) {
            errors.postal_code = "Postal Code must contain only digits!";
        } else if (formData.postal_code.trim().length !== 6) {
            errors.postal_code = "Postal Code must be exactly 6 digits long!";
        }

        return errors;
    };

    const handleCountryChange = (data: any) => {
        setFormData({
            ...formData,
            country: data !== null ? data.name : '',
            state: '',
            city: ''
        });
        setDefaultCountry(data);
        setDefaultState(null);
        setDefaultCity(null);
        setAddressCode({ ...addressCode, countryCode: data?.isoCode });
    }

    const handleStateChange = (data: any) => {
        setFormData({
            ...formData,
            state: data !== null ? data.name : '',
            city: ''
        });
        setDefaultState(data);
        setDefaultCity(null);
        setAddressCode({ ...addressCode, stateCode: data?.isoCode });
    }

    const handleCityChange = (data: any) => {
        setDefaultCity(data);
        setFormData({ ...formData, city: data !== null ? data.name : '' });
    }

    const selectOrganizationChange = (event: SelectChangeEvent) => {
        setFormData({ ...formData, organization_id: event.target.value as string });
    }

    const handleChangeImage = (event: any) => {
        let setFileUrl: string[] = [];
        let file = Array.from(event.target.files);
        for (const element of event.target.files) {
            setFileUrl.push(URL.createObjectURL(element));
        }
        setFormData({
            ...formData,
            images: [...formData.images, ...file],
            image_url: [...formData.image_url, ...setFileUrl],
        });
        const thumbnailElement = document.getElementById('images') as HTMLInputElement | null;

        if (thumbnailElement) {
            thumbnailElement.value = '';
        }
    }

    const handleRemoveImage = useCallback((index: number) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);

        let deletedImgArr = [];
        if (formData.urls?.length) {
            deletedImgArr.push(formData.urls[index]);
        }

        const updatedFiles = [...formData.image_url];
        updatedFiles.splice(index, 1);
        setFormData({
            ...formData,
            images: updatedImages,
            image_url: updatedFiles,
            deleted_images: [...formData.deleted_images, ...deletedImgArr],
        });
    }, [formData]);

    return (
            <Container maxWidth={false} className="p-0" >
                <Card elevation={3} className="form-outers">
                    <CardHeader className="inner-headings" title={id ? 'Edit Site' : 'Add Site'} />
                    <CardContent>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                {!id && <Grid item xs={12} md={6}>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth size='small'>
                                            <InputLabel id="demo-simple-select-label">Select Organization Name*</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formData.organization_id}
                                                label="Select Organization Name"
                                                required
                                                onChange={selectOrganizationChange}
                                                renderValue={() => formData.organization_id?.organization_name}
                                            >
                                                {OrganizationData && OrganizationData?.data?.response.map(
                                                    (item: any, index: number) => (
                                                        <MenuItem value={item} key={index} selected={formData?.organization_id?._id == item?._id ? true : false}>
                                                            {item?.organization_name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="name"
                                        label="Name"
                                        name="name"
                                        type="text"
                                        size='small'
                                        autoComplete="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        error={!!formErrors.name}
                                        helperText={formErrors.name}
                                    />
                                    <Error current_key="name" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="address"
                                        label="Address"
                                        name="address"
                                        type="text"
                                        size='small'
                                        autoComplete="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                        error={!!formErrors.address}
                                        helperText={formErrors.address}
                                    />
                                    <Error current_key="address" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="latitude"
                                        label="Latitude"
                                        name="latitude"
                                        type="number"
                                        size='small'
                                        autoComplete="latitude"
                                        value={formData.latitude}
                                        onChange={handleChange}
                                        error={!!formErrors.latitude}
                                        helperText={formErrors.latitude}
                                    />
                                    <Error current_key="latitude" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="longitude"
                                        label="Longitude"
                                        name="longitude"
                                        type="number"
                                        size='small'
                                        autoComplete="longitude"
                                        value={formData.longitude}
                                        onChange={handleChange}
                                        error={!!formErrors.longitude}
                                        helperText={formErrors.longitude}
                                    />
                                    <Error current_key="longitude" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        id="country-select-demo"
                                        options={addressData.country}
                                        size='small'
                                        value={defaultCountry}
                                        onChange={(event, newValue) => {
                                            handleCountryChange(newValue)
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#0a243d" }}>{children}</Paper>
                                        )}

                                        className="raju"
                                        autoHighlight
                                        getOptionLabel={(option: any) => option?.name}
                                        renderOption={(props, option: any) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.flag} {' '}
                                                {option.name} ({option.isoCode}) +{option?.phonecode}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Choose a country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', 
                                                }}
                                                value={formData.country}
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        id="country-select-demo"
                                        options={addressData.state}
                                        size='small'
                                        onChange={(event, newValue) => {
                                            handleStateChange(newValue)
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#0a243d" }}>{children}</Paper>
                                        )}
                                        value={defaultState}
                                        autoHighlight
                                        getOptionLabel={(option: any) => option?.name}
                                        renderOption={(props, option: any) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option?.name} ({option?.isoCode})
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Choose a State"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password',
                                                }}
                                                value={formData.state}
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        id="country-select-demo"
                                        options={addressData.city}
                                        size='small'
                                        onChange={(event, newValue) => {
                                            handleCityChange(newValue)
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#0a243d" }}>{children}</Paper>
                                        )}
                                        value={defaultCity}
                                        autoHighlight
                                        getOptionLabel={(option: any) => option?.name}
                                        renderOption={(props, option: any) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option?.name} ({option?.stateCode})
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Choose a City"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', 
                                                }}
                                                value={formData.city}
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="postal_code"
                                        label="Postal Code"
                                        name="postal_code"
                                        type="text"
                                        size='small'
                                        autoComplete="postal_code"
                                        value={formData.postal_code}
                                        onChange={handleChange}
                                        error={!!formErrors.postal_code}
                                        helperText={formErrors.postal_code}
                                    />
                                    <Error current_key="postal_code" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="images"
                                            name="images"
                                            label="Sites Images"
                                            type="file"
                                            className='sites_img'
                                            size='small'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{ accept: 'image/*', multiple: true }}
                                            autoComplete="images"
                                            onChange={handleChangeImage}
                                        />
                                        <Error current_key="images" />
                                    </Grid>
                                    <Grid item xs={12} className='imageDiv'>
                                        {formData?.image_url?.length ? formData.image_url.map((image: string, index: number) => (
                                            <div key={image}>
                                                <img src={image} alt={`sites-img-${index}`} className='preview-img' />
                                                <button onClick={() => handleRemoveImage(index)} title='Remove Image'><ClearIcon /></button>
                                            </div>
                                        )) : ''}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="save-btn"
                                        onSubmit={handleSubmit}
                                        style={{ marginRight: '10px' }}
                                        disabled={Object.values(formErrors).some(error => !!error)}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="close-btn"
                                        onClick={() => navigate('/admin/sites')}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
    )
}

export default AddEditSites;