import { Box, Button, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import React, { useCallback, useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import TrackerDetails from './TrackerDetails';
import arrowImage from "../../../../../assests/img/arrow.svg"
import TrackerVehicleInfo from './VehicleDetails';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGetSitesByIdQuery, useGetTrackerInfoByIdQuery } from '../../../../../redux-services';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

/**
 * Custom component for a tab panel.
 * @param {TabPanelProps} props - Properties for the tab panel.
 * @returns {JSX.Element} JSX element representing the tab panel.
 */
let CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tracker-tabpanel-${index}`}
            aria-labelledby={`tracker-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

/**
 * Generates accessibility properties for tab components based on the provided index.
 * These properties include unique identifiers for each tab and associations with their corresponding panels.
 * @param {number} index - The index of the tab.
 * @returns {Object} An object containing accessibility properties for the tab.
 */
let a11yProps = (index: number) => {
    return {
        id: `tracker-tab-${index}`,
        'aria-controls': `tracker-tabpanel-${index}`,
    };
}

/**
 * Functional component representing the organization detail view with a tabbed interface.
 * @returns {JSX.Element} JSX element representing the organization detail view.
 */
const ViewTracker: React.FC<{ siteDetail: { id: string, name: string } }> = ({ siteDetail }) => {
    const { id } = useParams<string>();
    const [value, setValue] = useState<number>(
        localStorage.getItem('selectedTrackerTab') ? parseInt(localStorage.getItem('selectedTrackerTab') || '0', 10) : 0
    );
    const [siteid, setSiteId] = useState(null);
    const [organizationName, setOrganizationName] = useState<string>('');
    const [siteName, setSiteName] = useState<string>('');

    window.history.pushState({ prevUrl: window.location.href }, "");

    const [trackerDetail, setTrackerDetail] = useState({
        id: '',
        authorization_id: '',
        organization_id: '',
        vehicle_type: 1,
        payload: ''
    });

    const loc = useLocation();
    const siteID = loc?.state?.siteId;
    const navigate = useNavigate();

    useEffect(() => {
        if (siteID) {
            setSiteId(siteID)
        }

    }, [siteID])
    const { data: siteData } = useGetSitesByIdQuery(siteID, {
        skip: !siteID,
    });
    const { data: getData } = useGetTrackerInfoByIdQuery(id, {
        skip: !id,
    });
    const userDetail = useSelector((state: RootState) => state.auth.user);
    useEffect(() => {
        if (siteData?.status === 200) {
            let data = siteData?.response
            setSiteName(data.name)
        }

    })
    useEffect(() => {
        if (getData?.status === 200) {
            let data = getData?.response
            setOrganizationName(data.organization_name)
        }

    }, [getData]);
    const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        localStorage.setItem('selectedTrackerTab', newValue.toString());
    }, []);

    const handleBackButton = () => {
        if (siteID) {
            navigate(`/admin/organization/sites/${siteID}`)
        } else {
            navigate('/admin/tracker')
        }
    }
    const breadcrumbs = [
        { name: 'Dashboard', to: '/admin/dashboard' },
        ...(siteID && userDetail?.role_type === 0 ? [{ name: 'Organizations', to: '/admin/organization' }] : []),
        ...(siteID ? [{ name: organizationName, to: `/admin/organization/${trackerDetail.organization_id}` }] : []),
        ...(siteID ? [{ name: siteName, to: `/admin/organization/sites/${siteID}` }] : []),
        { name: 'Tracker Detail', to: siteID ? `/admin/organization/sites/${siteID}` : '/admin/tracker' },
        { name: trackerDetail?.vehicle_type === 1 ? "Tata Edge" : "Mahindra Edge", to: `/admin/tracker/${id}` },
    ];

    return (
        <>
            <Toolbar>
                <div className="breadcrumbs" style={{ display: 'flex', alignItems: 'center' }}>
                    {breadcrumbs.map((crumb, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <img src={arrowImage} alt="arrow" style={{ margin: '0 8px', verticalAlign: 'middle' }} />}
                            {index === breadcrumbs.length - 1 ? (
                                <Typography variant="body2" component="span" style={{ color: 'white' }}>{crumb.name}</Typography>
                            ) : (
                                <Link to={crumb.to} className="breadcrumb-link" style={{ color: 'white' }}>{crumb.name}</Link>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Toolbar>
            <Button
                variant="contained"
                color="primary"
                className="secondary-btn"
                style={{ maxWidth: '60px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                onClick={handleBackButton}
            >
                <ArrowBackIcon />
            </Button>
            <Box sx={{ flexGrow: 1 }} className='mt-3'>
                {/* Container for the tabs */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    {/* Tabs component to render the tab headers */}
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Tracker Details" {...a11yProps(0)} />
                        <Tab label="Vehicle Details" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    {typeof id !== 'undefined' && <TrackerDetails id={id} setTrackerDetail={setTrackerDetail} trackerDetail={trackerDetail} />}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Box>
                        <TrackerVehicleInfo siteID={siteid} />
                    </Box>
                </CustomTabPanel>
            </Box>
        </>
    )
}

export default ViewTracker