import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Grid, Card, CardContent, CardHeader, Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, TextareaAutosize } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setError, useCreateShiftLogsMutation, useEditShiftLogsMutation, useGetMachineryDetailQuery, useGetShiftLogsByIdQuery, useGetShiftDetailQuery, useGetUserDataDetailQuery } from '../../redux-services';
import Error from '../../components/ErrorBox/Error';

export interface UserState {
    user_id: any;
    machine_id: any;
    shift_id: any;
    from_time: string;
    to_time: string;
    comment: string;
}
interface FormErrors{
    from_time?: string;
    to_time?: string;
    comment?: string;
}
const AddEditShiftLogs = () => {

    const navigate = useNavigate();
    const { id } = useParams();

    const intialState = {
        user_id: "",
        machine_id: "",
        shift_id: "",
        from_time: "",
        to_time: "",
        comment: "",
    }
    let dispatch = useDispatch();
    const [formData, setFormData] = useState<UserState>(intialState);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [createShiftLogs, result] = useCreateShiftLogsMutation();
    const [editShiftLogs, editResult] = useEditShiftLogsMutation();
    const { data: ShiftData } = useGetShiftLogsByIdQuery(id, {
        skip: !id,
    });

    const ShiftDetailData = useGetShiftDetailQuery();

    const MachineryData = useGetMachineryDetailQuery();

    const UserData = useGetUserDataDetailQuery();

    useEffect(() => {
        if (ShiftData?.status === 200) {
            let data: any = ShiftData?.response;
            setFormData({
                ...formData,
                user_id: data?.userData[0] ?? "",
                machine_id: data?.machineData[0] ?? "",
                shift_id: data?.shiftData[0] ?? "",
                from_time: data?.from_time ?? "",
                to_time: data?.to_time ?? "",
                comment: data?.comment ?? "",
            });
        }
        return () => {
            dispatch(setError([]));
        };
    }, [ShiftData]);

    if (result.isSuccess && result.data.status === 200) {
        navigate("/admin/shift/shift-logs");
    }

    if (editResult.isSuccess && editResult.data.status === 200) {
        navigate("/admin/shift/shift-logs");
    }

    const selectChange = (event: (SelectChangeEvent | any)) => {
        setFormData({
            ...formData,
            shift_id: event.target.value as string,
            from_time: event.target.value.from_time as string,
            to_time: event.target.value.to_time as string,
        });
    };

    const selectUserChange = (event: (SelectChangeEvent | any)) => {
        setFormData({ ...formData, user_id: event.target.value as string });
    };

    const selectMachineryChange = (event: (SelectChangeEvent | any)) => {
        setFormData({ ...formData, machine_id: event.target.value as string });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        dispatch(setError([]));
        let data: any = formData;
        let formattedData = {
            user_id: data?.user_id?._id ?? "",
            machine_id: data?.machine_id?._id ?? "",
            shift_id: data?.shift_id?._id ?? "",
            from_time: data?.from_time ?? "",
            to_time: data?.to_time ?? "",
            comment: data?.comment.trim() ?? "",
            types: "Machine",
            id: id ? id : null,
        }

        id ? editShiftLogs(formattedData) : createShiftLogs(formattedData);
    };

    const validate = (formData: UserState) => {
        const errors: Partial<UserState> = {};
        if (!formData.from_time.trim()) {
            errors.from_time = "From Time is required!";
        } 
       
        if (!formData.to_time.trim()) {
            errors.to_time = "To Time is required!";
        } 
       
        const startTime = formData.from_time.trim();
        const endTime = formData.to_time.trim();

        if (!startTime) {
            errors.from_time = "Start time is required!";
        } else if (!endTime) {
            errors.to_time = "End time is required!";
        } else {
            const startTimeParts = startTime.split(':');
            const endTimeParts = endTime.split(':');

            const startHour = parseInt(startTimeParts[0]);
            const startMinute = parseInt(startTimeParts[1]);
            const endHour = parseInt(endTimeParts[0]);
            const endMinute = parseInt(endTimeParts[1]);

            const startTimeValue = startHour * 60 + startMinute;
            const endTimeValue = endHour * 60 + endMinute;

            if (endTimeValue <= startTimeValue) {
                errors.to_time = "End time must be greater than start time!";
            }
        }
        
      
        return errors;
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value.trimStart()
        }));

        const errors = validate({ ...formData, [name]: value });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errors[name as keyof UserState]
        }));
    };
    
    return (
            <Container maxWidth={false} className="p-0" >
                <Card elevation={3} className="form-outers">
                    <CardHeader className="inner-headings" title={id ? 'Edit Shift Logs' : 'Add Shift Logs'} />
                    <CardContent>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth size='small'>
                                            <InputLabel id="demo-simple-select-label">User Names</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formData.user_id as any}
                                                label="User Name"
                                                onChange={selectUserChange}
                                                renderValue={() => formData.user_id?.firstname + ` ${formData.user_id?.lastname} (${formData.user_id?.ccode + '-' + formData.user_id?.contact_no})`}
                                            >
                                                {UserData && UserData?.data?.response.map(
                                                    (item: any, index: number) => (
                                                        <MenuItem value={item} key={index} selected={formData?.user_id?._id == item?._id ? true : false}>
                                                            {item?.firstname + ` ${item?.lastname} (${item?.ccode + '-' + item?.contact_no})`}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth size='small'>
                                            <InputLabel id="demo-simple-select-label">Shifts *</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formData.shift_id}
                                                label="Shifts"
                                                required
                                                onChange={selectChange}
                                                renderValue={() => formData.shift_id?.shift_name}
                                            >
                                                {ShiftDetailData && ShiftDetailData?.data?.response.map(
                                                    (item: any, index: number) => (
                                                        <MenuItem value={item} key={index}>{item?.shift_name}</MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth size='small'>
                                            <InputLabel id="demo-simple-select-label">Machinery Names *</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formData.machine_id}
                                                label="Machinery Name"
                                                required
                                                onChange={selectMachineryChange}
                                                renderValue={() => formData.machine_id?.machine_type + ` (${formData.machine_id?.number_plate})`}
                                            >
                                                {MachineryData && MachineryData?.data?.response.map(
                                                    (item: any, index: number) => (
                                                        <MenuItem value={item} key={index}>{item?.machine_type + ` (${item?.number_plate})`}</MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="from_time"
                                            label="Shift From Time"
                                            name="from_time"
                                            type="time"
                                            className="calendradate"
                                            size='small'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            autoComplete="from_time"
                                            value={formData.from_time}
                                            onChange={handleChange}
                                            error={!!formErrors.from_time}
                                            helperText={formErrors.from_time}
                                        />
                                        <Error current_key="from_time" />
                                    </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="to_time"
                                        label="Shift To Time"
                                        name="to_time"
                                        className="calendradate"
                                        type="time"
                                        size='small'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        autoComplete="to_time"
                                        value={formData.to_time}
                                        onChange={handleChange}
                                        error={!!formErrors.to_time}
                                        helperText={formErrors.to_time}
                                    />
                                    <Error current_key="to_time" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="comment"
                                        label="Comment"
                                        InputProps={{
                                            inputComponent: TextareaAutosize,
                                            rows: 3
                                        }}
                                        size='small'
                                        name="comment"
                                        type="textarea"
                                        autoComplete="comment"
                                        value={formData.comment}
                                        onChange={handleChange}
                                     
                                    />
                                    <Error current_key="comment" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="save-btn"
                                        onSubmit={handleSubmit}
                                        style={{ marginRight: '10px' }}
                                        disabled={Object.values(formErrors).some(error => !!error)} 

                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="close-btn"
                                        onClick={() => navigate(-1)}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
    );
};

export default AddEditShiftLogs;
