import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  Toolbar,
  Typography,
  FormHelperText,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setError,
  useCreateShiftMutation,
  useEditShiftMutation,
  useGetShiftByIdQuery,
  useGetSitesDetailQuery,
} from "../../redux-services";
import Error from "../../components/ErrorBox/Error";
import arrowImage from "../../assests/img/arrow.svg";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export interface UserState {
  shift_name: string;
  site_id: any;
  isSiteChange: boolean;
  site_name: any;
  from_time: any;
  to_time: any;
}
interface FormErrors {
  shift_name?: string;
  from_time?: string;
  to_time?: string;
}
const AddEditShift = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const intialState = {
    shift_name: "",
    from_time: "",
    site_id: "",
    site_name: "",
    isSiteChange: false,
    to_time: "",
  };
  let dispatch = useDispatch();
  const [formData, setFormData] = useState<UserState>(intialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const SitesData = useGetSitesDetailQuery();
  const [createShift, result] = useCreateShiftMutation();
  const [editShift, editResult] = useEditShiftMutation();
  const { data: ShiftData } = useGetShiftByIdQuery(id, {
    skip: !id,
  });

  useEffect(() => {
    if (ShiftData && ShiftData?.status === 200) {
      let data = ShiftData?.response;
      const site = SitesData?.data?.response?.find(
        (site: any) => site._id === ShiftData.response.site_id
      );
      const fromTime = dayjs(data?.from_time, "h:mm A");
      const toTime = dayjs(data?.to_time, "h:mm A");
      setFormData({
        ...formData,
        site_id: data?.site_id ?? "",
        site_name: site?.name,
        shift_name: data?.shift_name ?? "",
        from_time: fromTime ?? "",
        to_time: toTime ?? "",
      });
    }
    return () => {
      dispatch(setError([]));
    };
  }, [ShiftData, SitesData]);

  if (result.isSuccess && result.data.status === 200) {
    navigate("/admin/shift");
  }

  if (editResult.isSuccess && editResult.data.status === 200) {
    navigate("/admin/shift");
  }
  const handleSelectChange = (
    e: SelectChangeEvent<string>
  ) => {
    e.preventDefault();
    const { name, value } = e.target;
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name!]: value.trimStart(), 
    }));
  
    const updatedFormData = { ...formData, [name!]: value };
    const errors = validate(updatedFormData);
  
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name!]: errors[name as keyof UserState],
    }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.trimStart(),
    }));
    const updatedFormData = { ...formData, [name]: value };
    const errors = validate(updatedFormData);

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors[name as keyof UserState],
    }));
  };

  const selectSitesChange = (event: SelectChangeEvent<string>) => {
    const selectedSiteId = event.target.value;
    const selectedSite = SitesData?.data?.response.find(
      (site: any) => site._id === selectedSiteId
    );

    if (!selectedSite) return;

    const currentSiteId = ShiftData?.response?.site_id;

    if (currentSiteId === selectedSiteId) {
      setFormData({
        ...formData,
        site_id: selectedSite._id,
        site_name: selectedSite.name,
        isSiteChange: false,
      });
    } else {
      setFormData({
        ...formData,
        site_id: selectedSite._id,
        site_name: selectedSite.name,
        isSiteChange: true,
      });
    }
  };

  const validate = (formData: UserState) => {
    const errors: Partial<UserState> = {};
    const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (!formData.shift_name.trim()) {
      errors.shift_name = "Shift Name is required!";
    } else if (regex.test(formData.shift_name)) {
      errors.shift_name = "Shift Name cannot contain special characters!";
    }
    const startTime = formData.from_time.trim();
    const endTime = formData.to_time.trim();

    if (!startTime) {
      errors.from_time = "Start time is required!";
    }
    if (!endTime) {
      errors.to_time = "End time is required!";
    }

    return errors;
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(setError([]));
    const fromTime =
      formData.from_time instanceof Object
        ? new Date(formData.from_time as any).toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
        : formData.from_time;
    const toTime =
      formData.to_time instanceof Object
        ? new Date(formData.to_time as any).toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
        : formData.to_time;

    let formattedData = {
      shift_name: formData?.shift_name ?? "",
      site_id: formData?.site_id ?? "",
      from_time: fromTime ?? "",
      to_time: toTime ?? "",
      id: id ?? null,
    };
    id ? editShift(formattedData) : createShift(formattedData);
  };
  const breadcrumbs = [
    { name: "Dashboard", to: "/admin/dashboard" },
    { name: "Shift-List", to: "/admin/shift" },
    {
      name: id ? "Edit-Shift" : "Add-Shift",
      to: id ? `/admin/shift/edit/${id}` : "/admin/shift/add",
    },
  ];

  const handleFromTimeChange = (fieldName: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: new Date(value).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
    }));
  };

  return (
    <React.Fragment>
      <Toolbar>
        <div
          className="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          {breadcrumbs.map((crumb, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <img
                  src={arrowImage}
                  alt="arrow"
                  style={{ margin: "0 8px", verticalAlign: "middle" }}
                />
              )}
              {index === breadcrumbs.length - 1 ? (
                <Typography
                  variant="body2"
                  component="span"
                  style={{ color: "white" }}
                >
                  {crumb.name}
                </Typography>
              ) : (
                <Link
                  to={crumb.to}
                  className="breadcrumb-link"
                  style={{ color: "white" }}
                >
                  {crumb.name}
                </Link>
              )}
            </React.Fragment>
          ))}
        </div>
      </Toolbar>
      <Container maxWidth={false} className="p-0">
        <Card elevation={3} className="form-outers">
          <CardHeader
            className="inner-headings"
            title={id ? "Edit Shift" : "Add Shift"}
          />
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Sites Names
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.site_id || ""}
                        label="Sites Name"
                        required
                        onChange={selectSitesChange}
                        renderValue={() => formData.site_name || ""}
                      >
                        {SitesData?.data?.response.map((item: any) => (
                          <MenuItem value={item._id} key={item._id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl required fullWidth size="small" error={!!formErrors.shift_name}>
                    <InputLabel id="shift_name_label">Shift Name</InputLabel>
                    <Select
                      labelId="shift_name_label"
                      id="shift_name"
                      value={formData.shift_name}
                      onChange={handleSelectChange}
                      label="Shift Name"
                      name="shift_name"
                      autoComplete="shift_name"
                    >
                      <MenuItem value="morning">Morning</MenuItem>
                      <MenuItem value="evening">Evening</MenuItem>
                    </Select>
                    {formErrors.shift_name && (
                      <FormHelperText>{formErrors.shift_name}</FormHelperText>
                    )}
                  </FormControl>
                  <Error current_key="shift_name" />
                </Grid>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className="custom-datepicker"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <TimePicker
                      label="From Time"
                      value={formData.from_time}
                      name="from_time"
                      onChange={(newValue) =>
                        handleFromTimeChange("from_time", newValue)
                      }
                    />
                  </Grid>
                  <Error current_key="from_time" />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className="custom-datepicker"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <TimePicker
                      label="To Time"
                      value={formData.to_time}
                      name="to_time"
                      onChange={(newValue) =>
                        handleFromTimeChange("to_time", newValue)
                      }
                    />
                  </Grid>
                  <Error current_key="from_time" />
                </LocalizationProvider>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="save-btn"
                    onSubmit={handleSubmit}
                    style={{ marginRight: "10px" }}
                    disabled={Object.values(formErrors).some(
                      (error) => !!error
                    )}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="close-btn"
                    onClick={() => navigate("/admin/shift")}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default AddEditShift;
