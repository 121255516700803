import { TableCell, TableSortLabel } from "@mui/material";
import { ColumnsType, Order, SortingData } from "../../types/CommonTypes";

export interface TableHeadData {
  setHead: ColumnsType[];
  order: string;
  orderBy: string;
  sortFn: (event: React.MouseEvent<unknown>, id: string) => void;
}
export const TableHeadElement: React.FunctionComponent<TableHeadData> = (
  props: TableHeadData
) => {
  return (
    <>
      {props.setHead &&
        props.setHead.map((column: ColumnsType) => (
          <TableCell
            key={column.id}
            style={column?.style}
            onClick={(event: any) => column.sort && props.sortFn(event, column.id)}
          >
            {column.sort && (
              <TableSortLabel
                active={props.orderBy === (column.id as keyof SortingData)}
                direction={props.order as Order}
              >
                {column.label}
              </TableSortLabel>
            )}
            {!column.sort && column.label}
          </TableCell>
        ))}
    </>
  );
};
