import { Box, Button, List, ListItem, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { useGetTrackerInfoByIdQuery } from '../../../../../redux-services';
import TrackerAddEditModal from '../../../../../components/Models/TrackerAddEditModal';

interface TrackerInfoState {
    authorization_id: string;
    organization_id: string;
    organization_name: string;
    site_id: string;
    vehicle_type: number;
    payload: string;
    base_url:string;
}

const TrackerDetails: React.FC<{ id: string, setTrackerDetail: any, trackerDetail: any }> = ({ id, setTrackerDetail, trackerDetail }) => {
    const intialState = {
        authorization_id: "",
        organization_id: "",
        site_id:"",
        vehicle_type: 1,
        payload: "",
        base_url:"",
    }
    
   
    
    const [open, setOpen] = useState<boolean>(false);
    const [trackerData, setTrackerData] = useState<TrackerInfoState>();
    const { data: getData } = useGetTrackerInfoByIdQuery(id);

    useEffect(() => {
        if (getData?.status === 200) {
            let data = getData?.response
            data = { ...data }
        
            setTrackerData(data);
            setTrackerDetail({
                id: data._id,
                authorization_id: data.authorization_id,
                base_url: data?.base_url,
                site_id: data.site_id,
                organization_id: data.organization_id,
                vehicle_type: data.vehicle_type,
                payload: data.payload,
            });
        }

    }, [getData]);

    const handleModel = useCallback((isOpen: boolean) => {
        setOpen(isOpen);
    }, []);

    return (
        <>
            <Box className="heading-bar">
                <Box className="action-icons">
                    <List>
                        <ListItem>
                            <Button onClick={() => handleModel(true)}><EditIcon /></Button>
                        </ListItem>
                    </List>
                </Box>
            </Box>
            <Box className="tracker-details">
                <List>
                    <ListItem>
                        <Typography variant='h5'>{"Organization_name"}</Typography>
                        <Box className="tracker-detail-value">{trackerData?.organization_name}</Box>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5'>{"Vehicle Type"}</Typography>
                        <Box className="tracker-detail-value">{trackerData?.vehicle_type === 1 ? "Tata Edge": "Mahindra Edge"}</Box>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5'>{"Base Url"}</Typography>
                        <Box className="tracker-detail-value">{trackerData?.base_url}</Box>
                    </ListItem>
                </List>
                {open && <TrackerAddEditModal
                    open={open}
                    trackerData={trackerData as any}
                    handleModel={handleModel}
                />}
            </Box>
        </>
    )
}
export default TrackerDetails 