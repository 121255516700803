import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  debounce,
} from "@mui/material";
import { TableBodyElement } from "../../components/TableBody/TableBodyElement";
import { TableHeadElement } from "../../components/TableBody/TableHeadElement";
import {
  ColumnsType,
  Order,
  PageMeta,
  SortingData,
} from "../../types/CommonTypes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import { SearchElement } from "../../components/Search/Search";
import arrowImage from "../../assests/img/arrow.svg";
import {
  useDeleteShiftMutation,
  useMachineServiceListQuery,
  useShiftListQuery,
  useSitesListByOrgIdQuery,
  useUpdateShiftStatusMutation,
} from "../../redux-services";
interface MachineType {
  _id: string;
  status: boolean;
}
const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "site_name",
    sort: true,
    label: "Site Name",
  },
  {
    id: "shift_name",
    sort: true,
    label: "Shift",
  },
  {
    id: "from_time",
    sort: true,
    label: "From Time",
  },
  {
    id: "to_time",
    sort: true,
    label: "To Time",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
    style: { width: "15%" },
  },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const Shift = () => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };
  let navigate = useNavigate();
  let query = useLocation();
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [activeMachineId, setActiveMachineId] = useState<string>("");
  const [machineStatus, setMachineStatus] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [search, setSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [sitesList, setSitesList] = useState<PageMeta>(initialState);
  const [filteredData, setFilteredData] = useState(null);

  const [shiftList, setShiftList] = useState<PageMeta>(initialState);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>("");
  const [names, setNames] = useState<string[]>([]);
  const [selectedName, setSelectedName] = useState<string>("");
  const { data } = useShiftListQuery({
    per_page: shiftList.per_page ?? rowsPerPage,
    page: query.state ?? shiftList.page,
    order: shiftList.order ?? order,
    order_by: shiftList.order_by ?? orderBy,
    searchText: shiftList.searchText ?? search,
  });

  const { data: sitesData } = useSitesListByOrgIdQuery({
    per_page: sitesList.per_page ?? rowsPerPage,
    page: query.state ?? sitesList.page,
    order: sitesList.order ?? order,
    order_by: sitesList.order_by ?? orderBy,
    searchText: sitesList.searchText ?? search,
  });

  const { data: machineryServiceList } = useMachineServiceListQuery({
    per_page: sitesList.per_page ?? rowsPerPage,
    page: query.state ?? sitesList.page,
    order: sitesList.order ?? order,
    order_by: sitesList.order_by ?? orderBy,
    searchText: sitesList.searchText ?? search,
    id: Id ?? null,
  });

  const [deleteShift] = useDeleteShiftMutation();
  const [updateActiveStatus] = useUpdateShiftStatusMutation();
  useEffect(() => {
    if (sitesData?.response?.data) {
      const extractedNames = sitesData?.response?.data?.map(
        (item: any) => item.name
      );
      setNames(extractedNames);
    }
  }, [sitesData]);

  const handleNameChange = (event: { target: { value: any } }) => {
    const newSelectedName = event.target.value;
    setSelectedName(newSelectedName);

    const filterData = sitesData?.response?.data?.find(
      (item: { name: any }) => item.name === newSelectedName
    );

    if (filterData) {
      const shiftData = data?.response?.data?.filter(
        (shift: { site_id: any }) => shift.site_id === filterData._id
      );
      setFilteredData(shiftData);
    } else {
      setFilteredData(null);
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setShiftList({
      ...shiftList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setShiftList({ ...shiftList, page: newPage + 1 });

    if (query.state !== null) {
      navigate(query.pathname, {});
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setShiftList({
      ...shiftList,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setShiftList({
        page: 1,
        per_page: shiftList.per_page,
        order: initialOrder,
        searchText: event.target.value,
      });
    }, 800);
  }, []);

  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      
      deleteShift(Id);
    } else {
      setIsClose(res);
    }
  };
  const handleToggleActive = (id: string) => {
    const machine = data?.response.data.find(
      (machine: MachineType) => machine._id === id
    );
    if (machine) {
      setActiveMachineId(id);
      setIsConfirmationOpen(true);
      setMachineStatus(machine.is_active);
    }
  };
  const handleConfirmationResponse = (confirmed: boolean) => {
    if (confirmed) {
      const newStatus = !machineStatus;
      updateActiveStatus({ id: activeMachineId, status: newStatus });
    }
    setActiveMachineId("");
    setIsConfirmationOpen(false);
  };
  const tableData = filteredData ? filteredData : data?.response.data || [];
  const breadcrumbs = [
    { name: "Dashboard", to: "/admin/dashboard" },
    { name: "Shift List", to: "/admin/shift" },
  ];

  let actionMessage = "";

  if (activeMachineId) {
    actionMessage = `Are you sure you want to ${
      machineStatus ? "deactivate" : "activate"
    } this Shift?`;
  }

  return (
    <div>
      <Paper className="form-outers">
        <Toolbar>
          <div
            className="breadcrumbs"
            style={{ display: "flex", alignItems: "center" }}
          >
            {breadcrumbs.map((crumb, index) => (
              <React.Fragment key={crumb.name}>
                {index > 0 && (
                  <img
                    src={arrowImage}
                    alt="arrow"
                    style={{ margin: "0 8px", verticalAlign: "middle" }}
                  />
                )}
                {index === breadcrumbs.length - 1 ? (
                  <Typography
                    variant="body2"
                    component="span"
                    style={{ color: "white" }}
                  >
                    {crumb.name}
                  </Typography>
                ) : (
                  <Link
                    to={crumb.to}
                    className="breadcrumb-link"
                    style={{ color: "white" }}
                  >
                    {crumb.name}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </div>
        </Toolbar>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 40%" }}
            variant="h5"
            id="tableTitle"
            className="inner-headings"
            component="div"
          >
            Shift List
          </Typography>
          <FormControl sx={{ marginRight: "15px", width: "15rem" }}>
            <InputLabel id="name-select-label" className="filterlabel">
              Filter Site
            </InputLabel>
            <Select
              labelId="name-select-label"
              id="name-select"
              value={selectedName}
              onChange={handleNameChange}
              style={{ height: "40px" }}
            >
              {names.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="flex-boxinner">
            <Typography sx={{ flex: "1 1 auto", marginRight: "0px" }}>
              <SearchElement
                searchFn={searchHandler}
                searchTag={"Search Shift..."}
              />
            </Typography>
            <Tooltip title="Add Shift" arrow className="me-1 mt-1">
              <Button
                className="secondary-btn"
                onClick={() => navigate("/admin/shift/add")}
                type="submit"
                variant="contained"
                color="primary"
              >
                Add Shift
              </Button>
            </Tooltip>
          </div>
        </Toolbar>
        <StyledEngineProvider>
          <TableContainer component={Paper} className="form-outers">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              className="table-outers"
            >
              <TableHead>
                <TableRow>
                  <TableHeadElement
                    setHead={tableColumns ? tableColumns : []}
                    order={order}
                    orderBy={orderBy}
                    sortFn={(event, id: string) =>
                      createSortHandler(event, id as keyof SortingData)
                    }
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableBodyElement
                  selectedColumns={[
                    "site_name",
                    "shift_name",
                    "from_time",
                    "to_time",
                  ]}
                  setData={tableData}
                  editFn={(id) =>
                    navigate(`/admin/shift/edit/${id}`, {
                      state: page,
                    })
                  }
                  deleteFn={(id) => {
                    setIsClose(true);
                    setId(id);
                  }}
                  isActivefn={(id) => handleToggleActive(id)}
                  pageData={{
                    limit:
                      rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: data?.response.page as number,
                  }}
                />
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={data?.response.total ?? 0}
              page={data?.response.page ?? 0}
              rowsPerPageOptions={[20, 50, 100]}
              onPageChange={handleChangePage}
              rowsPerPage={
                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </StyledEngineProvider>
      </Paper>
      <ConfirmBox
        title={`Shift ${
          machineStatus ? "Deactivate" : "Activate"
        } Confirmation`}
        message={actionMessage}
        open={isConfirmationOpen}
        onClose={handleConfirmationResponse}
      />
      <ConfirmBox
        title="Shift Delete Confirmation"
        message="Are you sure, you want to delete this Shift?"
        open={isClose}
        onClose={confirmBoxClose}
      />
    </div>
  );
};

export default Shift;
