import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import loadingImage from "./assests/img/loading-36.gif";
import { useEffect } from 'react';
import { setAuthLogout, setSiteOrganizationId } from './redux-services';
import { RootState } from './store/store';
import Layout from './components/Layout/Layout';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/WorkItem/ForgottenPassword';


const App = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const isLoading = useSelector((state: RootState) => state.alertbox.isLoading);
  let dispatch = useDispatch();

  window.history.pushState({ prevUrl: window.location.href }, "");
   if(!window.history.state.prevUrl.includes("/admin/organization/sites/")){
      localStorage.setItem('selectedTab','0')
   }
  if (!window.history.state.prevUrl.includes("/admin/site/machine/")) {
    localStorage.setItem('selectedTabMachineryDetails', '0')
  }
  if (!window.history.state.prevUrl.includes("/admin/tracker/")) {
    localStorage.setItem('selectedTrackerTab', '0')
  }
  if (!window.history.state.prevUrl.includes("/admin/site/work_item/")) {
    localStorage.setItem('selectedWorkItemTab', '0')
  }
  useEffect(() => {
    if (localStorage?.getItem('accessToken') === null) {
      dispatch(setAuthLogout());
    }
    if (localStorage?.getItem('orgId') !== null) {
      dispatch(setSiteOrganizationId(localStorage?.getItem('orgId')));
    }
  }, []);

  return (
    <>
      <Box className={`loader-outer ${isLoading ? "addloader" : ""}`}>
        <img alt="img" src={loadingImage} />
      </Box>
      <BrowserRouter>
        <Routes>
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/reset_password/:email" element={<ResetPassword />} />
          <Route path="/login" element={isAuthenticated ? <Navigate to={"/admin/dashboard"} /> : <Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to={isAuthenticated ? "/admin/dashboard" : "/login"} />} />
          <Route path="*" element={localStorage.getItem('accessToken') ? <Layout /> : <Navigate to={"/login"} />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App