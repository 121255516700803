import { createApi } from "@reduxjs/toolkit/query/react";
import { Response } from "../../types/CommonTypes";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export const RateChartApi = createApi({
  reducerPath: "RateChartLogApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["rateChartLogsTag"],
  
  endpoints: (builder) => ({
    createChart: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/rate_chart/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["rateChartLogsTag"],
    }),

    getChart: builder.query<Response<any>, string>({
      query: (siteId: string) => ({
        url: `/rate_chart/${siteId}`,
        method: "GET",
      }),
      providesTags: ["rateChartLogsTag"],
    }),

    updateChart: builder.mutation<Response<any>, { siteId: string; data: any }>({
        query: ({ siteId, data }) => ({
          url: `/rate_chart/update/${siteId}`,
          method: "PUT",
          body: data,
        }),
        invalidatesTags: ["rateChartLogsTag"],
      }),
  }),
});

export const {
  useCreateChartMutation,
  useGetChartQuery,
  useUpdateChartMutation,
} = RateChartApi;
