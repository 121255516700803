import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Grid, Card, CardHeader, CardContent } from '@mui/material';
import { useCreateEmployeeRoleMutation, useEditEmployeeRoleMutation, useGetEmployeeRoleByIdQuery } from '../../redux-services';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setError } from '../../redux-services';
import Error from '../../components/ErrorBox/Error';


export interface UserRoleState {
    role_name: string;
}

const AddEditEmployeeRole = () => {

    const navigate = useNavigate();
    const { id } = useParams();

    const intialState = {
        role_name: "",
    }
    let dispatch = useDispatch();
    const [formData, setFormData] = useState<UserRoleState>(intialState);
    const [createUserRole, result] = useCreateEmployeeRoleMutation();
    const [editUserRole, editResult] = useEditEmployeeRoleMutation();
    const { data: UserRoleData } = useGetEmployeeRoleByIdQuery(id, {
        skip: !id,
    });

    useEffect(() => {
        if (UserRoleData?.status === 200) {
            let data = UserRoleData?.response;
            setFormData({
                ...formData,
                role_name: data?.role_name,
            })
        }
    }, [UserRoleData]);

    useEffect(() => {
        if (result?.data?.status !== 200) {
            dispatch(setError(result?.data));
        } else {
            dispatch(setError([]));
        }
    }, [result])

    if (result.isSuccess && result.data.status === 200) {
        navigate("/admin/employee/employee-role");
    }

    if (editResult.isSuccess && editResult.data.status === 200) {
        navigate("/admin/employee/employee-role");
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let formattedData = {
            role_name: formData?.role_name ?? '',
            id: id ? id : null,
        }
        id ? editUserRole(formattedData) : createUserRole(formattedData);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    return (
            <Container maxWidth={false} className='p-0'>
                <Card elevation={3} className="table-outers">
                    <CardHeader className="inner-headings" title={id ? 'Edit Employee Role' : 'Add Employee Role'} />
                    <CardContent>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="role_name"
                                        label="Employee Role Name"
                                        name="role_name"
                                        type="role_name"
                                        size='small'
                                        autoComplete="role_name"
                                        value={formData.role_name}
                                        onChange={handleChange}
                                    />
                                    <Error current_key="role_name" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onSubmit={handleSubmit}
                                        className="save-btn"
                                        style={{marginRight: '10px'}}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="close-btn"
                                        onClick={() => navigate(-1)}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
    );
};

export default AddEditEmployeeRole;
