
import { RouteInfo } from "./types/CommonTypes";
import Dashboard from "./pages/Dashboard";
import AddEditUser from "./pages/User/AddEditUser";
import User from "./pages/User";
import AddEditUserRole from "./pages/UserRole/AddEditUserRole";
import UserRole from "./pages/UserRole";
import AddEditMachineInfo from "./pages/MachineInfo/AddEditMachineInfo";
import MachineInfo from "./pages/MachineInfo/Index";
import AddEditShift from "./pages/Shift/AddEditShift";
import Shift from "./pages/Shift";
import AddEditShiftLogs from "./pages/ShiftLogs/AddEditShiftLogs";
import ShiftLogs from "./pages/ShiftLogs";
import MachineFuelEntries from "./pages/MachineInfo/FuelEntry";
import AddEditAccessories from "./pages/Accessories/AddEditAccessories";
import MachineWorkLogs from "./pages/MachineInfo/WorkLogs";
import Accessories from "./pages/Accessories";
import MachineAccessories from "./pages/MachineInfo/AccessoriesEntries";
import AddEditSites from "./pages/Sites/AddEditSites";
import Employee from "./pages/Employee";
import AddEditEmployee from "./pages/Employee/AddEditEmployee";
import EmployeeRole from "./pages/EmployeeRole";
import AddEditEmployeeRole from "./pages/EmployeeRole/AddEditEmployeeRole";
import Sites from "./pages/Sites";
import AddEditOrganization from "./pages/Organiztion/AddEditOrganization";
import Organization from "./pages/Organiztion";
import Profile from "./pages/Profile";
import ViewSites from "./pages/Sites/ViewSites";
import ViewMachineInfo from "./pages/MachineInfo/ViewMachineInfo";
import ViewOrganizationInfo from "./pages/Organiztion/ViewOrganizationInfo";
import ViewSitesInfo from "./pages/Sites/ViewSitesInfo";
import TrackerDetails from "./pages/Sites/ViewSites/TrackerDetails";
import AddEditTrackerInfo from "./pages/Sites/ViewSites/TrackerDetails/AddEditTrackerInfo";
import ViewTracker from "./pages/Sites/ViewSites/TrackerDetails/ViewTracker";
import MachineHistory from "./pages/MachineInfo/MachineHistory";
import MachineReport from "./pages/MachineInfo/MachineReport";
import TrackVehicleMovement from "./pages/VehicleMovement";
import AddSiteMachineType from "./pages/Sites/ViewSites/SiteMachinery/MachineType";
import SiteMachineInfo from "./pages/Sites/ViewSites/SiteMachinery/indes"
import ResetPassword from "./pages/ResetPassword";
import MachineServiceHistory from "./pages/MachineInfo/ServiceHistory/MachineServiceHistory";
import FuelTypeList from "./pages/Sites/ViewSites/FuelType";

const SuperAdminRouting: RouteInfo[] = [
  { path: "/admin/reset_password/:id", exact: true, name: "Reset Password", element: ResetPassword },
  { path: "/admin/dashboard", exact: true, name: "Dashboard", element: Dashboard },
  { path: "/admin/organization/list", exact: true, name: "Organization", element: Organization },
  { path: '/admin/organization/add', exact: true, name: "Add Organization", element: AddEditOrganization },
  { path: '/admin/organization/edit/:id', exact: true, name: " Edit Organization", element: AddEditOrganization },

  { path: "/admin/organization", exact: true, name: "View Organization", element: ViewOrganizationInfo },
  { path: "/admin/organization/:id", exact: true, name: "View Sites", element: ViewSitesInfo },

  { path: "/admin/profile/:id", exact: true, name: "Profile", element: Profile },

  { path: '/admin/organization/sites/:id', exact: true, name: " View Sites", element: ViewSites },
  { path: "/admin/sites", exact: true, name: "Add Edit Sites", element: Sites },
  { path: '/admin/sites/add', exact: true, name: "Add Site", element: AddEditSites },
  { path: '/admin/sites/edit/:id', exact: true, name: " Edit Site", element: AddEditSites },

  { path: '/admin/user', exact: true, name: "User", element: User },
  { path: '/admin/user/add', exact: true, name: "Add User", element: AddEditUser },
  { path: '/admin/user/edit/:id', exact: true, name: " Edit User", element: AddEditUser },

  { path: '/admin/user/role', exact: true, name: "Role", element: UserRole },
  { path: '/admin/user/role/add', exact: true, name: "Add Role", element: AddEditUserRole },
  { path: '/admin/user/role/edit/:id', exact: true, name: " Edit Role", element: AddEditUserRole },


  { path: '/admin/user/employee', exact: true, name: "Employee", element: Employee },
  { path: `/admin/user/employee/add`, exact: true, name: "Add Employee", element: AddEditEmployee },
  { path: '/admin/user/employee/edit/:id', exact: true, name: " Edit Employee", element: AddEditEmployee },


  { path: '/admin/employee/employee-role', exact: true, name: "Employee List", element: EmployeeRole },
  { path: '/admin/employee/employee-role/add', exact: true, name: "Add Employee", element: AddEditEmployeeRole },
  { path: '/admin/employee/employee-role/edit/:id', exact: true, name: " Edit Employee", element: AddEditEmployeeRole },

  { path: '/admin/machine', exact: true, name: "Machinery List", element: MachineInfo },
  { path: '/admin/machine/add', exact: true, name: "Add Machinery", element: AddEditMachineInfo },
  { path: '/admin/machine/edit/:id', exact: true, name: " Edit Machinery", element: AddEditMachineInfo },
  { path: '/admin/machine/fuel/:id', exact: true, name: "Machine Fuel", element: MachineFuelEntries },
  { path: '/admin/machine/work-logs/:id', exact: true, name: "Machine Work Logs", element: MachineWorkLogs },
  { path: '/admin/machine/report', exact: true, name: "Machine Report", element: MachineReport },

  { path: '/admin/site/:siteId/machine/:id', exact: true, name: "View Machine Logs", element: ViewMachineInfo },
  { path: '/admin/site/machine/:machineId/history/:siteId', exact: true, name: "View Vehicle Day wise History", element: MachineHistory },
  { path: '/admin/site/machine/:machineId/history', exact: true, name: "View Vehicle Day wise History", element: MachineHistory },

  { path: '/admin/shift', exact: true, name: "Shift List", element: Shift },
  { path: '/admin/shift/add', exact: true, name: "Add Shift", element: AddEditShift },
  { path: '/admin/shift/edit/:id', exact: true, name: " Edit Shift", element: AddEditShift },


  { path: '/admin/shift/shift-logs', exact: true, name: "Shift List", element: ShiftLogs },
  { path: '/admin/shift/shift-logs/add', exact: true, name: "Add Shift", element: AddEditShiftLogs },
  { path: '/admin/shift/shift-logs/edit/:id', exact: true, name: " Edit Shift", element: AddEditShiftLogs },




  { path: '/admin/accessories', exact: true, name: "Accessories List", element: Accessories },
  { path: '/admin/accessories/add', exact: true, name: "Add Shift", element: AddEditAccessories },
  { path: '/admin/accessories/edit/:id', exact: true, name: " Edit Shift", element: AddEditAccessories },
  { path: '/admin/machine/accessories/:id', exact: true, name: "Machine Accessories Entry", element: MachineAccessories },

  { path: "/admin/tracker", exact: true, name: "Tracker Details", element: TrackerDetails },
  { path: '/admin/tracker/add', exact: true, name: "Add Tracker", element: AddEditTrackerInfo },
  { path: '/admin/tracker/edit/:id', exact: true, name: "Edit Tracker", element: AddEditTrackerInfo },
  { path: '/admin/tracker/:id', exact: true, name: " View Tracker Info", element: ViewTracker },

  { path: '/admin/site/machine/:machineId/servicehistory', exact: true, name: "View Vehicle Day wise History", element: MachineServiceHistory },
];

const OrgAdminRouting: RouteInfo[] = [
  { path: "/admin/dashboard", exact: true, name: "Dashboard", element: Dashboard },


  { path: "/admin/organization/:id", exact: true, name: "View Sites", element: ViewSitesInfo },

  { path: "/admin/profile/:id", exact: true, name: "Profile", element: Profile },

  { path: '/admin/organization/sites/:id', exact: true, name: " View Sites", element: ViewSites },
  { path: "/admin/sites", exact: true, name: "Add Edit Sites", element: Sites },
  { path: '/admin/sites/add', exact: true, name: "Add Site", element: AddEditSites },
  { path: '/admin/sites/edit/:id', exact: true, name: " Edit Site", element: AddEditSites },

  { path: '/admin/user', exact: true, name: "User", element: User },
  { path: '/admin/user/add', exact: true, name: "Add User", element: AddEditUser },
  { path: '/admin/user/edit/:id', exact: true, name: " Edit User", element: AddEditUser },

  { path: '/admin/user/role', exact: true, name: "Role", element: UserRole },
  { path: '/admin/user/role/add', exact: true, name: "Add Role", element: AddEditUserRole },
  { path: '/admin/user/role/edit/:id', exact: true, name: " Edit Role", element: AddEditUserRole },

  { path: '/admin/user/employee', exact: true, name: "Employee", element: Employee },
  { path: `/admin/user/employee/add`, exact: true, name: "Add Employee", element: AddEditEmployee },
  { path: '/admin/user/employee/edit/:id', exact: true, name: " Edit Employee", element: AddEditEmployee },


  { path: '/admin/machine', exact: true, name: "Machinery List", element: MachineInfo },
  { path: '/admin/machine/add', exact: true, name: "Add Machinery", element: AddEditMachineInfo },
  { path: '/admin/machine/edit/:id', exact: true, name: " Edit Machinery", element: AddEditMachineInfo },
  { path: '/admin/machine/fuel/:id', exact: true, name: "Machine Fuel", element: MachineFuelEntries },
  { path: '/admin/machine/work-logs/:id', exact: true, name: "Machine Work Logs", element: MachineWorkLogs },
  { path: '/admin/machine/report', exact: true, name: "Machine Report", element: MachineReport },
  { path: '/admin/site/machineType', exact: true, name: "Machine Type ", element: AddSiteMachineType },
  { path: '/admin/site/siteMachineInfo', exact: true, name: "Site Machinery List", element: SiteMachineInfo },

  { path: '/admin/site/:siteId/machine/:id', exact: true, name: "View Machine Logs", element: ViewMachineInfo },
  { path: '/admin/site/machine/:machineId/history', exact: true, name: "View Vehicle Day wise History", element: MachineHistory },
  { path: '/admin/site/machine/:machineId/history/:siteId', exact: true, name: "View Vehicle Day wise History", element: MachineHistory },

  { path: '/admin/shift', exact: true, name: "Shift List", element: Shift },
  { path: '/admin/shift/add', exact: true, name: "Add Shift", element: AddEditShift },
  { path: '/admin/shift/edit/:id', exact: true, name: " Edit Shift", element: AddEditShift },
  { path: '/admin/fuel-type', exact: true, name: "Shift List", element: FuelTypeList },


  { path: '/admin/shift/shift-logs', exact: true, name: "Shift List", element: ShiftLogs },
  { path: '/admin/shift/shift-logs/add', exact: true, name: "Add Shift", element: AddEditShiftLogs },
  { path: '/admin/shift/shift-logs/edit/:id', exact: true, name: " Edit Shift", element: AddEditShiftLogs },




  { path: '/admin/accessories', exact: true, name: "Accessories List", element: Accessories },
  { path: '/admin/accessories/add', exact: true, name: "Add Shift", element: AddEditAccessories },
  { path: '/admin/accessories/edit/:id', exact: true, name: " Edit Shift", element: AddEditAccessories },
  { path: '/admin/machine/accessories/:id', exact: true, name: "Machine Accessories Entry", element: MachineAccessories },

  { path: "/admin/tracker", exact: true, name: "Tracker Details", element: TrackerDetails },
  { path: '/admin/tracker/add', exact: true, name: "Add Tracker", element: AddEditTrackerInfo },
  { path: '/admin/tracker/edit/:id', exact: true, name: "Edit Tracker", element: AddEditTrackerInfo },
  { path: '/admin/tracker/:id', exact: true, name: " View Tracker Info", element: ViewTracker },
  { path: '/admin/vehiclemovement', exact: true, name: "Track Vehicle Movement", element: TrackVehicleMovement },

  { path: '/admin/site/machine/:machineId/servicehistory', exact: true, name: "View Vehicle Day wise History", element: MachineServiceHistory },
];


const SubSuperAdminRouting: RouteInfo[] = [
  { path: "/admin/dashboard", exact: true, name: "Dashboard", element: Dashboard },

  { path: "/admin/organization/list", exact: true, name: "Organization", element: Organization },
  { path: '/admin/organization/add', exact: true, name: "Add Organization", element: AddEditOrganization },
  { path: '/admin/organization/edit/:id', exact: true, name: " Edit Organization", element: AddEditOrganization },

  { path: "/admin/organization", exact: true, name: "View Organization", element: ViewOrganizationInfo },
  { path: "/admin/organization/:id", exact: true, name: "View Sites", element: ViewSitesInfo },

  { path: "/admin/profile/:id", exact: true, name: "Profile", element: Profile },

  { path: '/admin/organization/sites/:id', exact: true, name: " View Sites", element: ViewSites },
  { path: "/admin/sites", exact: true, name: "Add Edit Sites", element: Sites },
  { path: '/admin/sites/add', exact: true, name: "Add Site", element: AddEditSites },
  { path: '/admin/sites/edit/:id', exact: true, name: " Edit Site", element: AddEditSites },

  { path: '/admin/user', exact: true, name: "User", element: User },
  { path: '/admin/user/add', exact: true, name: "Add User", element: AddEditUser },
  { path: '/admin/user/edit/:id', exact: true, name: " Edit User", element: AddEditUser },


  { path: '/admin/user/employee', exact: true, name: "Employee", element: Employee },
  { path: `/admin/user/employee/add`, exact: true, name: "Add Employee", element: AddEditEmployee },
  { path: '/admin/user/employee/edit/:id', exact: true, name: " Edit Employee", element: AddEditEmployee },

  { path: '/admin/employee/employee-role', exact: true, name: "Employee List", element: EmployeeRole },
  { path: '/admin/employee/employee-role/add', exact: true, name: "Add Employee", element: AddEditEmployeeRole },
  { path: '/admin/employee/employee-role/edit/:id', exact: true, name: " Edit Employee", element: AddEditEmployeeRole },

  { path: '/admin/machine', exact: true, name: "Machinery List", element: MachineInfo },
  { path: '/admin/machine/add', exact: true, name: "Add Machinery", element: AddEditMachineInfo },
  { path: '/admin/machine/edit/:id', exact: true, name: " Edit Machinery", element: AddEditMachineInfo },
  { path: '/admin/machine/fuel/:id', exact: true, name: "Machine Fuel", element: MachineFuelEntries },
  { path: '/admin/machine/work-logs/:id', exact: true, name: "Machine Work Logs", element: MachineWorkLogs },
  { path: '/admin/machine/report', exact: true, name: "Machine Report", element: MachineReport },

  { path: '/admin/site/:siteId/machine/:id', exact: true, name: "View Machine Logs", element: ViewMachineInfo },
  { path: '/admin/site/machine/:machineId/history', exact: true, name: "View Vehicle Day wise History", element: MachineHistory },
  { path: '/admin/site/machine/:machineId/history/:siteId', exact: true, name: "View Vehicle Day wise History", element: MachineHistory },

  { path: '/admin/shift', exact: true, name: "Shift List", element: Shift },
  { path: '/admin/shift/add', exact: true, name: "Add Shift", element: AddEditShift },
  { path: '/admin/shift/edit/:id', exact: true, name: " Edit Shift", element: AddEditShift },


  { path: '/admin/shift/shift-logs', exact: true, name: "Shift List", element: ShiftLogs },
  { path: '/admin/shift/shift-logs/add', exact: true, name: "Add Shift", element: AddEditShiftLogs },
  { path: '/admin/shift/shift-logs/edit/:id', exact: true, name: " Edit Shift", element: AddEditShiftLogs },


  { path: '/admin/accessories', exact: true, name: "Accessories List", element: Accessories },
  { path: '/admin/accessories/add', exact: true, name: "Add Shift", element: AddEditAccessories },
  { path: '/admin/accessories/edit/:id', exact: true, name: " Edit Shift", element: AddEditAccessories },
  { path: '/admin/machine/accessories/:id', exact: true, name: "Machine Accessories Entry", element: MachineAccessories },

  { path: "/admin/tracker", exact: true, name: "Tracker Details", element: TrackerDetails },
  { path: '/admin/tracker/add', exact: true, name: "Add Tracker", element: AddEditTrackerInfo },
  { path: '/admin/tracker/edit/:id', exact: true, name: "Edit Tracker", element: AddEditTrackerInfo },
  { path: '/admin/tracker/:id', exact: true, name: " View Tracker Info", element: ViewTracker },

  { path: '/admin/vehiclemovement', exact: true, name: "Track Vehicle Movement", element: TrackVehicleMovement },
  { path: '/admin/site/machine/:machineId/servicehistory', exact: true, name: "View Vehicle Day wise History", element: MachineServiceHistory },
];

const routes = {
  SuperAdminRoute: SuperAdminRouting,
  OrgAdminRoute: OrgAdminRouting,
  SubSuperAdminRoute: SubSuperAdminRouting,
};

export default routes;
