import {
  DeleteForeverRounded,
  RemoveRedEye,
  Done,
  Close,
  Download,
  Settings,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import EvStationIcon from "@mui/icons-material/EvStation";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TableCell,
  TableRow,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material";
import { TableBodyData } from "../../types/CommonTypes";
import { useCallback, useEffect, useState } from "react";

export const TableBodyElement: React.FunctionComponent<TableBodyData> = (
  props: TableBodyData
) => {
  const [commentForm, setCommentForm] = useState({
    id: "",
    comment: "",
  });
  const [measurementLogId, setMeasurementLogId] = useState("");

  const handleChangeComment = (event: any) => {
    event.preventDefault();
    if (typeof props.editCommentFn === "function") {
      props.editCommentFn(commentForm.id, commentForm.comment);
    }
    setCommentForm({ id: "", comment: "" });
  };

  useEffect(() => {
    setCommentForm({ id: "", comment: "" });
  }, []);

  const getColumnValue = (columnName: string, column: any, index: number) => {
    if (columnName === "createdAt") {
      return dateFormatter(column[columnName]);
    }

    if (columnName === "description" || columnName === "comment") {
      return truncateText(column[columnName], 50, index);
    }

    return tableColumnsData(column[columnName]);
  };

  const getCheckedValue = (columnName: string, column: any) => {
    if (columnName === "checked") {
      return column[columnName] ? "Yes" : "No";
    }

    return "";
  };

  const tableColumnsData = useCallback((columnsData: any) => {
    const variableType: string[] = ["object"];
    if (variableType.includes(typeof columnsData)) {
      return columnsData ? columnsData.join(", ") : "";
    }
    return columnsData;
  }, []);

  const handleEditComment = (column: any, commentForm: any) => {
    if (column?.daily_log_id === commentForm?.id) {
      return commentForm.comment;
    }

    return column["comment"] || "";
  };

  let tableColumnWidth = props.styleFn;

  const dateFormatter = useCallback((unixTimestamp: number) => {
    const date = new Date(unixTimestamp);

    const year = date.getFullYear() ?? " ";
    const month = (date.getMonth() + 1).toString().padStart(2, "0") ?? " ";
    const day = date.getDate().toString().padStart(2, "0") ?? " ";
    const formattedDateTime = `${day}-${month}-${year}`;
    return formattedDateTime;
  }, []);

  const showAttendanceStatus = (status: boolean) => {
    if (status === true) {
      return (
        <Tooltip
          title="Present"
          arrow
          className="me-1 mt-1"
          style={{ color: "green" }}
        >
          <CheckCircleIcon />
        </Tooltip>
      );
    } else if (status === false) {
      return (
        <Tooltip
          title="Absent"
          arrow
          className="me-1 mt-1"
          style={{ color: "red" }}
        >
          <CancelIcon />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          title="Pending"
          arrow
          className="me-1 mt-1"
          style={{ color: "yellow" }}
        >
          <WarningIcon />
        </Tooltip>
      );
    }
  };

  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});

  const truncateText = (text: string, maxLength: number, rowIndex: number) => {
    const isTruncated = !expandedRows[rowIndex];

    if (text.length <= maxLength) return text;

    const toggleTruncate = () => {
      setExpandedRows((prevState) => ({
        ...prevState,
        [rowIndex]: !prevState[rowIndex],
      }));
    };

    return (
      <>
        {isTruncated ? text.slice(0, maxLength) + "..." : text}
        <button
          onClick={toggleTruncate}
          style={{
            color: "blue",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              toggleTruncate();
            }
          }}
        >
          {isTruncated ? " more" : " less"}
        </button>
      </>
    );
  };

  return (
    <>
      {(props.setData ? props.setData : []).map(
        (column: any, index: number) => (
          <TableRow
            key={column[index]}
            style={
              column.is_debited !== undefined
                ? { backgroundColor: column.is_debited ? "#bf3030" : "#399861" }
                : {}
            }
          >
            {typeof props.checkboxFn === "function" && (
              <Box>
                <Checkbox
                  onClick={() =>
                    typeof props.checkboxFn === "function" &&
                    props.checkboxFn(column._id)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
            )}
            {props.showSerialNumber !== false && (
              <TableCell>
                {props.pageData &&
                  props.pageData?.limit * props.pageData?.page + index + 1}
              </TableCell>
            )}
            {props.selectedColumns.map((columnName: any, indx: number) => (
              <TableCell
                key={columnName}
                align="left"
                style={{ width: tableColumnWidth }}
              >
                {getColumnValue(columnName, column, index)}
                {getCheckedValue(columnName, column)}
                {columnName === "attendance_status" &&
                  showAttendanceStatus(column[columnName])}
              </TableCell>
            ))}
            {typeof props.editMeasurementFn === "function" ? (
              <>
                {measurementLogId !== column?._id ? (
                  <TableCell
                    key={`cell-${column?._id}`}
                    align="left"
                    style={{ width: tableColumnWidth }}
                    onClick={(e) => {
                      e.preventDefault();
                      setMeasurementLogId(column?._id);
                    }}
                  >
                    <div style={{ border: "1px solid #ffff" }}>
                      {column["measurement"]}
                    </div>
                  </TableCell>
                ) : (
                  <TextField
                    fullWidth
                    id="measurement"
                    name="measurement"
                    type="textarea"
                    InputProps={{
                      inputComponent: TextareaAutosize,
                      rows: 5,
                    }}
                    size="small"
                    value={column["measurement_value"]}
                    onChange={(event: any) => {
                      event.preventDefault();
                      typeof props.editMeasurementFn === "function" &&
                        props.editMeasurementFn(
                          column?._id,
                          event.target.value
                        );
                    }}
                    onBlur={(event: any) => {
                      event.preventDefault();
                      setMeasurementLogId("");
                    }}
                  />
                )}
              </>
            ) : (
              ""
            )}
            {typeof props.radioAttendance === "function" && (
              <TableCell style={{ width: tableColumnWidth }}>
                <Button className="action-btn">
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                    style={{ color: "#fff" }}
                  >
                    <FormControlLabel
                      value="top"
                      control={<Radio style={{ color: "green" }} />}
                      checked={column?.attendance_status === true}
                      label="Yes"
                      onClick={() =>
                        typeof props.radioAttendance === "function" &&
                        props.radioAttendance(
                          column._id,
                          column.daily_log_id,
                          true
                        )
                      }
                      labelPlacement="top"
                    />
                    <FormControlLabel
                      value="top"
                      control={<Radio style={{ color: "red" }} />}
                      checked={column?.attendance_status === false}
                      label="No"
                      onClick={() =>
                        typeof props.radioAttendance === "function" &&
                        props.radioAttendance(
                          column._id,
                          column.daily_log_id,
                          false
                        )
                      }
                      labelPlacement="top"
                    />
                  </RadioGroup>
                </Button>
              </TableCell>
            )}
            {!props?.showAction && (
              <TableCell align="left">
                {typeof props.viewProfileFn === "function" && (
                  <Tooltip title="View Profile" arrow className="me-1 mt-1">
                    <Button
                      onClick={() =>
                        typeof props.viewProfileFn === "function" &&
                        props.viewProfileFn(column._id)
                      }
                      className="action-btn"
                    >
                      <RemoveRedEye className="icon" />
                    </Button>
                  </Tooltip>
                )}

                {typeof props.viewWorkItemFn === "function" && (
                  <Tooltip title="View Work Item" arrow className="me-1 mt-1">
                    <Button
                      onClick={() =>
                        typeof props.viewWorkItemFn === "function" &&
                        props.viewWorkItemFn(column._id, column)
                      }
                      className="action-btn"
                    >
                      <RemoveRedEye className="icon" />
                    </Button>
                  </Tooltip>
                )}

                {typeof props.editCommentFn === "function" && (
                  <div>
                    <TextField
                      fullWidth
                      id="comment"
                      name="comment"
                      type="textarea"
                      InputProps={{
                        inputComponent: TextareaAutosize,
                        rows: 5,
                      }}
                      size="small"
                      value={handleEditComment(column, commentForm)}
                      onChange={(event: any) => {
                        event.preventDefault();
                        typeof props.editCommentFn === "function" &&
                          setCommentForm({
                            id: column.daily_log_id,
                            comment: event.target.value,
                          });
                      }}
                      onBlur={handleChangeComment}
                    />
                  </div>
                )}
                {typeof props.editCommentDuplicateFn === "function" && (
                  <div>
                    <TextField
                      fullWidth
                      id="comment"
                      name="comment"
                      type="textarea"
                      InputProps={{
                        inputComponent: TextareaAutosize,
                        rows: 5,
                      }}
                      size="small"
                      value={column["comment"]}
                      onChange={(event: any) => {
                        event.preventDefault();
                        typeof props.editCommentDuplicateFn === "function" &&
                          props.editCommentDuplicateFn(
                            column._id,
                            event.target.value
                          );
                      }}
                      onBlur={handleChangeComment}
                    />
                  </div>
                )}

                {typeof props.editFn === "function" &&
                  (column.is_debited === false ||
                    column.is_debited === undefined) && (
                    <Tooltip title="Edit" arrow className="me-1 mt-1">
                      <Button
                        onClick={() =>
                          typeof props.editFn === "function" &&
                          props.editFn(column._id)
                        }
                        className="action-btn"
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  )}

                {typeof props.viewWorkLogsFn === "function" && (
                  <Tooltip
                    title="Machine Work Logs"
                    arrow
                    className="me-1 mt-1"
                  >
                    <Button
                      onClick={() =>
                        typeof props.viewWorkLogsFn === "function" &&
                        props.viewWorkLogsFn(column._id)
                      }
                      className="action-btn"
                    >
                      <WorkHistoryIcon className="icon" />
                    </Button>
                  </Tooltip>
                )}
                {typeof props.viewTrackerFn === "function" && (
                  <Tooltip
                    title="View Tracker Info"
                    arrow
                    className="me-1 mt-1"
                  >
                    <Button
                      onClick={() =>
                        typeof props.viewTrackerFn === "function" &&
                        props.viewTrackerFn(column._id)
                      }
                      className="action-btn"
                    >
                      <RemoveRedEye className="icon" />
                    </Button>
                  </Tooltip>
                )}
                {typeof props.viewAccessoriesFn === "function" && (
                  <Tooltip
                    title="Machine Accessories Logs"
                    arrow
                    className="me-1 mt-1"
                  >
                    <Button
                      onClick={() =>
                        typeof props.viewAccessoriesFn === "function" &&
                        props.viewAccessoriesFn(column._id)
                      }
                      className="action-btn"
                    >
                      <PrecisionManufacturingIcon className="icon" />
                    </Button>
                  </Tooltip>
                )}
                {typeof props.viewSitesFn === "function" && (
                  <Tooltip title="View Site" arrow className="me-1 mt-1">
                    <Button
                      onClick={() =>
                        typeof props.viewSitesFn === "function" &&
                        props.viewSitesFn(column._id, column.organization_id)
                      }
                      className="action-btn"
                    >
                      <RemoveRedEye className="icon" />
                    </Button>
                  </Tooltip>
                )}

                {column?.fuelReceipt_img !== null &&
                  typeof props.viewDownloadFn === "function" &&
                  (column.is_debited === false ||
                    column.is_debited === undefined) && (
                    <Tooltip title="Download " arrow className="me-1 mt-1">
                      <Button
                        onClick={() =>
                          typeof props.viewDownloadFn === "function" &&
                          props.viewDownloadFn(column._id)
                        }
                        className="action-btn"
                      >
                        <Download className="icon" />
                      </Button>
                    </Tooltip>
                  )}

                {column.is_debited === true && (
                  <Tooltip title="No Action" arrow className="me-1 mt-1">
                    <Button className="action-btn">---</Button>
                  </Tooltip>
                )}

                {column?.previous_images?.length > 0 &&
                  typeof props.viewDocumentFn === "function" && (
                    <Tooltip
                      title="View Document Detail"
                      arrow
                      className="me-1 mt-1"
                    >
                      <Button
                        onClick={() =>
                          props.viewDocumentFn &&
                          props.viewDocumentFn(column._id)
                        }
                        className="action-btn"
                      >
                        <HistoryIcon className="icon" />
                      </Button>
                    </Tooltip>
                  )}

                {typeof props.deleteFn === "function" &&
                  (column.is_debited === false ||
                    column.is_debited === undefined) && (
                    <Tooltip title="Delete" arrow className="me-1 mt-1">
                      <Button
                        onClick={() =>
                          typeof props.deleteFn === "function" &&
                          props.deleteFn(column._id)
                        }
                        className="action-btn"
                        style={{ color: "red" }}
                      >
                        <DeleteForeverRounded />
                      </Button>
                    </Tooltip>
                  )}
                {typeof props.isHandleDeactivatefn === "function" && (
                  <Tooltip
                    title="Complete Site"
                    arrow
                    className="me-1 mt-1"
                  >
                    <Button
                      onClick={() =>
                        typeof props.isHandleDeactivatefn === "function" &&
                        props.isHandleDeactivatefn(column._id)
                      }
                      className="action-btn"
                    >
                      <CheckCircleIcon className="icon" />
                    </Button>
                  </Tooltip>
                )}

                {typeof props.viewMachineFn === "function" && (
                  <Tooltip
                    title="View Machine Detail"
                    arrow
                    className="me-1 mt-1"
                  >
                    <Button
                      onClick={() =>
                        typeof props.viewMachineFn === "function" &&
                        props.viewMachineFn(column._id, column?.site_id)
                      }
                      className="action-btn"
                    >
                      <RemoveRedEye className="icon" />
                    </Button>
                  </Tooltip>
                )}

                {typeof props.viewStorageFn === "function" && (
                  <Tooltip
                    title="View Storage Transactions"
                    arrow
                    className="me-1 mt-1"
                  >
                    <Button
                      onClick={() =>
                        typeof props.viewStorageFn === "function" &&
                        props.viewStorageFn(column._id, column?.site_id)
                      }
                      className="action-btn"
                    >
                      <RemoveRedEye className="icon" />
                    </Button>
                  </Tooltip>
                )}

                {typeof props.viewFuelEntryFn === "function" && (
                  <Tooltip
                    title="Machine Fuel Entries"
                    arrow
                    className="me-1 mt-1"
                  >
                    <Button
                      onClick={() =>
                        typeof props.viewFuelEntryFn === "function" &&
                        props.viewFuelEntryFn(column._id)
                      }
                      className="action-btn"
                    >
                      <EvStationIcon className="icon" />
                    </Button>
                  </Tooltip>
                )}
                {typeof props.viewAddress === "function" && (
                  <Tooltip title="See on Map" arrow className="me-1 mt-1">
                    <Button
                      onClick={() =>
                        typeof props.viewAddress === "function" &&
                        props.viewAddress(column._id)
                      }
                      className="action-btn"
                    >
                      View On Map
                    </Button>
                  </Tooltip>
                )}

                {typeof props.viewMachineHistoryFn === "function" && (
                  <Tooltip
                    title="View Machine History"
                    arrow
                    className="me-1 mt-1"
                  >
                    <Button
                      onClick={() =>
                        typeof props.viewMachineHistoryFn === "function" &&
                        props.viewMachineHistoryFn(column._id)
                      }
                      className="action-btn"
                    >
                      <WorkHistoryIcon className="icon" />
                    </Button>
                  </Tooltip>
                )}
                {typeof props.viewServiceHistoryFunction === "function" && (
                  <Tooltip
                    title="View Service History"
                    arrow
                    className="me-1 mt-1"
                  >
                    <Button
                      onClick={() =>
                        typeof props.viewServiceHistoryFunction ===
                        "function" &&
                        props.viewServiceHistoryFunction(column._id)
                      }
                      className="action-btn"
                    >
                      <Settings className="icon" />
                    </Button>
                  </Tooltip>
                )}
                {typeof props.isActivefn === "function" && (
                  <Tooltip
                    title={column.is_active ? "Deactivate" : "Activate"}
                    arrow
                    className="me-1 mt-1"
                  >
                    <Switch
                      checked={column.is_active}
                      onChange={() =>
                        typeof props.isActivefn === "function" &&
                        props.isActivefn(column._id)
                      }
                      className="action-btn"
                    />
                  </Tooltip>
                )}
                {typeof props.statusFn === "function" && (
                  <Tooltip
                    title={column.status ? "Do Inactive" : "Do Active"}
                    arrow
                    className="me-1 mt-1"
                  >
                    <Button
                      onClick={() =>
                        typeof props.statusFn === "function" &&
                        props.statusFn(column._id, !column.status)
                      }
                      className="action-btn"
                    >
                      {column.status ? <Close /> : <Done />}
                    </Button>
                  </Tooltip>
                )}
              </TableCell>
            )}
          </TableRow>
        )
      )}
      {props.setData.length === 0 && (
        <TableRow>
          <TableCell style={{ textAlign: "center" }} colSpan={12}>
            <h3>No Record found</h3>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
