import React from 'react';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface MyComponentProps {
    current_key: string;
}

const Errors: React.FC<MyComponentProps> = ({ current_key }) => {
    let { errorList }: any = useSelector((state: RootState) => state.error);

    if (errorList === undefined || !Array.isArray(errorList)) {
        errorList = [];
    }

    if (errorList.some((obj: any) => current_key in obj)) {
        const errorMessage = errorList.map((obj: any) => obj[current_key]).join(', ');
        return (
            <Typography variant="body1" className="error-alert">
                {errorMessage}
            </Typography>
        );
    }

    return null;
};

export default Errors;
