import React, { memo, useEffect, useMemo, useState } from "react";
import AddEditService from "./AddEditService";
import {
  ColumnsType,
  Order,
  PageMeta,
  SortingData,
} from "../../../types/CommonTypes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useDeleteMachineServiceMutation,
  useGetMachineInfoByIdQuery,
  useMachineServiceListQuery,
} from "../../../redux-services";
import {
  debounce,
  Paper,
  Snackbar,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import { TableHeadElement } from "../../../components/TableBody/TableHeadElement";
import { TableBodyElement } from "../../../components/TableBody/TableBodyElement";
import { SearchElement } from "../../../components/Search/Search";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";
const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "user_name",
    sort: true,
    label: "User Name",
  },
  {
    id: "machine_name",
    sort: true,
    label: "Machine Name",
  },
  {
    id: "service_type",
    sort: true,
    label: "Service Type",
  },
  {
    id: "shift_name",
    sort: true,
    label: "Shift Name",
  },
  {
    id: "amount",
    sort: true,
    label: "Amount",
  },
  {
    id: "current_reading",
    sort: false,
    label: "Machine Reading",
  },
  {
    id: "description",
    sort: false,
    label: "Description",
  },
  {
    id: "createdAt",
    sort: true,
    label: "Service Time",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
    style: { width: "15%" },
  },
];
let initialOrder: string = process.env.REACT_APP_ORDER as string;

const ServiceLogs = () => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };
  let navigate = useNavigate();
  let query = useLocation();
  const { id, siteId } = useParams<{ id: string; siteId: string }>();

  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [search, setSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [shiftLogsList, setShiftLogsList] = useState<PageMeta>(initialState);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [machineEntryEditId, setMachineEntryEditId] = useState<string>("");
  const [Id, setId] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const { data } = useMachineServiceListQuery({
    per_page: shiftLogsList.per_page ?? rowsPerPage,
    page: query.state ?? shiftLogsList.page,
    order: shiftLogsList.order ?? order,
    order_by: shiftLogsList.order_by ?? orderBy,
    searchText: shiftLogsList.searchText ?? search,
    id: id,
  });
  const { data: getData } = useGetMachineInfoByIdQuery(id, {
    skip: !id,
  });
  console.log(data, "data");
  useEffect(() => {
    if (getData?.status === 200) {
      let data = getData?.response;
      setIsActive(data.is_active);
    }
  }, [getData]);

  const [deleteMachineService] = useDeleteMachineServiceMutation();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setShiftLogsList({
      ...shiftLogsList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setShiftLogsList({ ...shiftLogsList, page: newPage + 1 });

    if (query.state !== null) {
      navigate(query.pathname, {});
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setShiftLogsList({
      ...shiftLogsList,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };
  const handleShowMessage = () => {
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setShiftLogsList({
        page: 1,
        per_page: shiftLogsList.per_page,
        order: initialOrder,
        searchText: event.target.value,
      });
    }, 800);
  }, []);

  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteMachineService(Id);
    } else {
      setIsClose(res);
    }
  };

  return (
    <>
      <div>
        <Paper className="form-outers">
          <Toolbar>
            <Typography
              sx={{ flex: "1 1 40%" }}
              variant="h5"
              id="tableTitle"
              className="inner-headings"
              component="div"
            >
              Machine Service List
            </Typography>
            <div className="flex-boxinner">
              <Typography sx={{ flex: "1 1 auto" }}>
                <SearchElement
                  searchFn={searchHandler}
                  searchTag={"Search Service Logs..."}
                />
              </Typography>
              <Tooltip title="Add Shift Logs" arrow className="me-1 mt-1">
                <Button
                  className="secondary-btn"
                  onClick={() => {
                    setShowModal(true);
                    handleShowMessage();
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Add Machine Service
                </Button>
              </Tooltip>
              <Snackbar
                open={snackbarOpen && !isActive}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message="Please activate this machine to add service logs."
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                style={{ marginTop: "140px" }}
              />
            </div>
          </Toolbar>
          <StyledEngineProvider>
            <TableContainer component={Paper} className="form-outers">
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
                className="table-outers"
              >
                <TableHead>
                  <TableRow>
                    <TableHeadElement
                      setHead={tableColumns ?? []}
                      order={order}
                      orderBy={orderBy}
                      sortFn={(event, id: string) =>
                        createSortHandler(event, id as keyof SortingData)
                      }
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableBodyElement
                    selectedColumns={[
                      "user_name",
                      "machine_name",
                      "service_type",
                      "shift_name",
                      "amount",
                      "current_reading",
                      "description",
                      "createdAt",
                    ]}
                    setData={data?.response.data ? data?.response.data : []}
                    editFn={(id) => {
                      setMachineEntryEditId(id);
                      setShowModal(true);
                    }}
                    deleteFn={(id) => {
                      setIsClose(true);
                      setId(id);
                    }}
                    pageData={{
                      limit:
                        rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                      page: data?.response.page as number,
                    }}
                  />
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={data?.response.total ?? 0}
                page={data?.response.page ?? 0}
                rowsPerPageOptions={[20, 50, 100]}
                onPageChange={handleChangePage}
                rowsPerPage={
                  rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                }
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </StyledEngineProvider>
        </Paper>
        <ConfirmBox
          title="Machine Service Delete Confirmation"
          message="Are you sure, you want to delete this Machine Service?"
          open={isClose}
          onClose={confirmBoxClose}
        />
      </div>
      {showModal && isActive && (
        <AddEditService
          id={machineEntryEditId}
          mchnData={data?.response?.data}
          showModal={showModal}
          setShowModal={setShowModal}
          setMachineEntryEditId={setMachineEntryEditId}
          paramId={id}
          siteId={siteId}
        />
      )}
    </>
  );
};
export default memo(ServiceLogs);
