import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Box,
  Card,
  CardContent,
  CardHeader,
  Autocomplete,
} from "@mui/material";
import {
  useCreateOrganizationMutation,
  useEditOrganizationMutation,
  useGetOrganizationByIdQuery,
  setError,
} from "../../redux-services";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Error from "../../components/ErrorBox/Error";
import ClearIcon from "@mui/icons-material/Clear";
import { Country, State, City } from "country-state-city";
import { Image } from "@mui/icons-material";

export interface UserState {
  organization_name: string;
  email: string;
  address: string;
  city: string;
  state: string;
  country: string;
  images: any[];
  image_url: any[];
  urls?: any[];
  deleted_images?: any;
}
interface FormErrors {
  organization_name?: string;
  email?: string;
  address?: string;
}
const AddEditOrganization = () => {
  const [addressData, setAddressData] = useState<any>({
    country: [],
    state: [],
    city: [],
  });

  const [addressCode, setAddressCode] = useState<any>({
    countryCode: "",
    stateCode: "",
  });

  const navigate = useNavigate();
  const { id } = useParams();

  const intialState = {
    organization_name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    images: [],
    image_url: [],
    urls: [],
    deleted_images: [],
  };
  let dispatch = useDispatch();
  const [formData, setFormData] = useState<UserState>(intialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [createOrganization, result] = useCreateOrganizationMutation();
  const [editOrganization, editResult] = useEditOrganizationMutation();
  const [defaultCountry, setDefaultCountry] = useState<any>(null);
  const [defaultState, setDefaultState] = useState<any>(null);
  const [defaultCity, setDefaultCity] = useState<any>(null);

  useEffect(() => {
    setDefaultCountry(
      addressData.country.find(
        (country: any) => country.name === formData?.country
      )
    );
  }, [formData]);

  useEffect(() => {
    if (defaultCountry !== undefined) {
      setAddressCode({ ...addressCode, countryCode: defaultCountry?.isoCode });
    }
  }, [defaultCountry]);

  useEffect(() => {
    if (defaultState !== undefined) {
      setAddressCode({ ...addressCode, stateCode: defaultState?.isoCode });
    }
  }, [defaultState]);

  useEffect(() => {
    const stateList = State.getStatesOfCountry(addressCode?.countryCode);
    if (stateList.length > 0) {
      setDefaultState(
        stateList.find((state: any) => state.name === formData?.state)
      );
    }
    const cityList = City.getCitiesOfState(
      addressCode.countryCode,
      addressCode.stateCode
    );
    if (cityList.length > 0) {
      setDefaultCity(
        cityList.find((city: any) => city.name === formData?.city)
      );
    }
  }, [addressCode]);

  const { data: UserData } = useGetOrganizationByIdQuery(id, {
    skip: !id,
  });

  useEffect(() => {
    setAddressData({
      ...addressData,
      country: Country.getAllCountries() ?? [],
      state: State.getStatesOfCountry(addressCode.countryCode) ?? [],
      city:
        City.getCitiesOfState(addressCode.countryCode, addressCode.stateCode) ??
        [],
    });
  }, [Country, State, City, addressCode]);

  useEffect(() => {
    if (UserData?.status === 200) {
      let data = UserData?.response || UserData?.response[0];
      setFormData({
        ...formData,
        organization_name: data?.organization_name ?? "",
        email: data?.email ?? " ",
        address: data?.address ?? "",
        country: data?.country ?? "",
        state: data?.state ?? "",
        city: data?.city ?? "",
        images: data?.images?.length
          ? data.images.map((image: any) => image.path)
          : [],
        image_url: data?.encodedUrl?.length ? data.encodedUrl : [],
        urls: data.images?.length
          ? data?.images.map((image: any) => image.fullUrl)
          : [],
      });
    }
    return () => {
      dispatch(setError([]));
    };
  }, [UserData]);

  if (result.isSuccess && result.data.status === 200) {
    navigate("/admin/organization/list");
  }

  if (editResult.isSuccess && editResult.data.status === 200) {
    navigate("/admin/organization/list");
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(setError([]));
    let data = new FormData();
    for (const item of formData.images) {
      data.append("images", item);
    }
    if (id) {
      for (const item of formData.deleted_images) {
        data.append("deleted_images", item);
      }
    }
    data.append("organization_name", formData.organization_name);
    data.append("email", formData.email.toLowerCase());
    data.append("address", formData.address);
    data.append("country", formData.country);
    data.append("state", formData.state);
    data.append("city", formData.city);
    data.append("id", id ?? (null as any));

    id ? editOrganization(data) : createOrganization(data);
  };

  const validate = (formData: UserState) => {
    const errors: Partial<UserState> = {};
    const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/;

    if (!formData.organization_name.trim()) {
      errors.organization_name = "First Name is required!";
    } else if (regex.test(formData.organization_name)) {
      errors.organization_name =
        "First Name cannot contain special characters!";
    }

    if (!formData.address.trim()) {
      errors.address = "Address is required!";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required!";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(formData.email)) {
      errors.email = "Invalid email format!";
    }

    return errors;
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.trimStart(),
    }));

    const errors = validate({ ...formData, [name]: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors[name as keyof UserState],
    }));
  };

  const handleCountryChange = (data: any) => {
    setFormData({
      ...formData,
      country: data !== null ? data.name : "",
      state: "",
      city: "",
    });
    setDefaultCountry(data);
    setDefaultState(null);
    setDefaultCity(null);
    setAddressCode({ ...addressCode, countryCode: data?.isoCode });
  };

  const handleStateChange = (data: any) => {
    setFormData({
      ...formData,
      state: data !== null ? data.name : "",
      city: "",
    });
    setDefaultState(data);
    setDefaultCity(null);
    setAddressCode({ ...addressCode, stateCode: data?.isoCode });
  };

  const handleCityChange = (data: any) => {
    setDefaultCity(data);
    setFormData({ ...formData, city: data !== null ? data.name : "" });
  };

  const handleChangeImage = (event: any) => {
    let setFileUrl: string[] = [];
    let file = Array.from(event.target.files);
    for (const element of event.target.files) {
      setFileUrl.push(URL.createObjectURL(element));
    }
    setFormData({
      ...formData,
      images: [...formData.images, ...file],
      image_url: [...formData.image_url, ...setFileUrl],
    });
    const thumbnailElement = document.getElementById(
      "images"
    ) as HTMLInputElement | null;

    if (thumbnailElement) {
      thumbnailElement.value = "";
    }
  };

  const handleRemoveImage = useCallback(
    (index: number) => {
      const updatedImages = [...formData.images];
      updatedImages.splice(index, 1);

      let deletedImgArr = [];
      if (formData.urls?.length) {
        deletedImgArr.push(formData.urls[index]);
      }

      const updatedFiles = [...formData.image_url];
      updatedFiles.splice(index, 1);
      setFormData({
        ...formData,
        images: updatedImages,
        image_url: updatedFiles,
        deleted_images: [...formData.deleted_images, ...deletedImgArr],
      });
    },
    [formData]
  );

  return (
    <Container className="p-0">
      <Card className="form-outers" elevation={3}>
        <CardHeader
          className="inner-headings"
          title={id ? "Edit Organization" : "Add Organization"}
        />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  id="organization_name"
                  label="Organization Name"
                  name="organization_name"
                  type="text"
                  size="small"
                  autoComplete="organization_name"
                  value={formData.organization_name}
                  onChange={handleChange}
                  error={!!formErrors.organization_name}
                  helperText={formErrors.organization_name}
                />
                <Error current_key="organization_name" />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="text"
                  size="small"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  id="address"
                  label="Address"
                  name="address"
                  type="text"
                  size="small"
                  autoComplete="address"
                  value={formData.address}
                  onChange={handleChange}
                  error={!!formErrors.address}
                  helperText={formErrors.address}
                />
                <Error current_key="address" />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="country-select-demo"
                  options={addressData.country}
                  size="small"
                  onChange={(event, newValue) => {
                    handleCountryChange(newValue);
                  }}
                  value={defaultCountry}
                  className="raju"
                  autoHighlight
                  getOptionLabel={(option: any) => option?.name}
                  renderOption={(props, option: any) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.flag} {option.name} ({option.isoCode}) +
                      {option?.phonecode}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="country-select-demo"
                  options={addressData.state}
                  size="small"
                  onChange={(event, newValue) => {
                    handleStateChange(newValue);
                  }}
                  value={defaultState}
                  autoHighlight
                  getOptionLabel={(option: any) => option?.name}
                  renderOption={(props, option: any) => (
                    <Box
                      className="selectone"
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option?.name} ({option?.isoCode})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a State"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      value={formData.state}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="country-select-demo"
                  options={addressData.city}
                  size="small"
                  onChange={(event, newValue) => {
                    handleCityChange(newValue);
                  }}
                  value={defaultCity}
                  autoHighlight
                  getOptionLabel={(option: any) => option?.name}
                  renderOption={(props, option: any) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option?.name} ({option?.stateCode})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a City"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      value={formData.city}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="images"
                    name="images"
                    label="Images"
                    type="file"
                    className="sites_img"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ accept: "image/*", multiple: true }}
                    autoComplete="images"
                    onChange={handleChangeImage}
                  />
                  <Error current_key="images" />
                </Grid>
                <Grid item xs={12} className="imageDiv">
                  {formData?.image_url?.length
                    ? formData.image_url.map((image: string, index: number) => (
                        <div key={image}>
                          <img
                            src={image}
                            alt={`sites-img-${index}`}
                            className="preview-img"
                          />
                          <Image
                            onClick={() => handleRemoveImage(index)}
                            title="Remove Image"
                            component="img"
                          >
                            <ClearIcon />
                          </Image>
                        </div>
                      ))
                    : ""}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onSubmit={handleSubmit}
                  className="save-btn"
                  style={{ marginRight: "10px" }}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className="close-btn"
                  onClick={() => navigate("/admin/organization")}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default AddEditOrganization;
