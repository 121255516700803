import { useEffect, useState } from 'react';
import Leaflet, { Map, MapOptions } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'tilelayer-canvas';
import 'leaflet.fullscreen';
import { MapConfig } from '../../ constants';

const useLeafletMap = (mapRef: HTMLDivElement | any, mapUrl: any) => {
    const [map, setMap] = useState<Map>(); 

    useEffect(() => {
        if (!mapRef.current) return; 
        let maps: Map = Leaflet.map(mapRef.current, MapConfig.params as MapOptions);
        const LTileLayer: any = Leaflet.tileLayer;

        LTileLayer.canvas(mapUrl, MapConfig.tileLayer.params).addTo(maps);

        maps.addControl(Leaflet.control.fullscreen(
            {
                position: 'topright', 
                title: 'Full Screen', 
                titleCancel: 'Exit Full Screen', 
            }
        ));

        maps.attributionControl.setPrefix(false); 
        setMap(maps); 

        return () => {
            if (map) {
                map.remove();
            } else {
                maps.remove();
            }
        };
    }, [mapRef]);

    return map; 
};

export default useLeafletMap;