import React, { useMemo, useState, useEffect, useCallback, FC } from 'react'
import {
    Button,
    Paper,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
    debounce,
} from "@mui/material";
import { TableBodyElement } from '../../../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../../../components/TableBody/TableHeadElement';
import { ColumnsType, Order, PageMeta, SortingData } from '../../../../types/CommonTypes';
import { Link, NavigateOptions, useNavigate, useParams } from 'react-router-dom';
import ConfirmBox from '../../../../components/ConfirmBox/ConfirmBox';
import { SearchElement } from '../../../../components/Search/Search';
import { useDeleteTrackerInfoMutation, useGetSitesByIdQuery, useGetUserDetailQuery, useTrackerInfoListQuery } from '../../../../redux-services';
import arrowImage from "../../../../assests/img/arrow.svg"
import AddEditTrackerInfo from './AddEditTrackerInfo';

const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "organization_name",
        sort: true,
        label: "Organization Name",
    },
    {
        id: "vehicle_type",
        sort: true,
        label: "Vehicle Type",
    },
    {
        id: "base_url",
        sort: true,
        label: "Base Url"
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "18%" },
    },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

interface ExtendedNavigateOptions extends NavigateOptions {
    page?: any;
    siteId?: any;
}

const TrackerDetails: FC<{ siteDetail: { id: string, name: string } }> = ({ siteDetail }) => {

    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
        organization_id: "",
        id: ""
    };

    let navigate = useNavigate();
    const { id } = useParams();
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );

    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );

    const [trackerInfoList, setTrackerInfoList] = useState<PageMeta>(initialState);
    const [isClose, setIsClose] = useState<boolean>(false);
    const [Id, setId] = useState<string>("");

    const additionalOptions: ExtendedNavigateOptions = {
        page: page,
        siteId: siteDetail?.id,
    };

    const [open, setOpen] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>("");
    const [trackerData, setTrackerData] = useState<any>([]);

    const { data: getData } = useGetSitesByIdQuery(id,
        { skip: !id }
    );

    const { data: userDetail } = useGetUserDetailQuery();

    const { isSuccess, data } = useTrackerInfoListQuery({
        per_page: trackerInfoList.per_page ?? rowsPerPage,
        page: trackerInfoList.page,
        order: trackerInfoList.order ?? order,
        order_by: trackerInfoList.order_by ?? orderBy,
        searchText: trackerInfoList.searchText ?? search,
        organization_id: userDetail?.response?.organization_id,
        id: id
    });
    
    useEffect(() => {
        if (isSuccess && data?.response?.data) {
            const updatedData = data.response.data.map((item: any) => ({
                ...item,
                vehicle_type: item.vehicle_type === 1 ? "Tata Edge" : "Mahindra Edge"
            }));
            setTrackerData(updatedData);
        }
    }, [isSuccess, data?.response?.data]);

    const [deleteTrackerInfo] = useDeleteTrackerInfoMutation();

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setTrackerInfoList({
            ...trackerInfoList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setTrackerInfoList({ ...trackerInfoList, page: newPage + 1 });
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTrackerInfoList({
            ...trackerInfoList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const handleModel = useCallback((isOpen: boolean) => {
        if (!isOpen) setEditId("");
        setOpen(isOpen);
    }, []);

    const addForm = useCallback(() => {
        setOpen(true);
    }, []);

    const editForm = useCallback((id: string) => {
        setEditId(id);
        setOpen(true);
    }, []);

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setTrackerInfoList({
                page: 1,
                per_page: trackerInfoList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

    const confirmBoxClose = (res: boolean) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteTrackerInfo(Id);
        } else {
            setIsClose(res);
        }
    };
    const breadcrumbs = !id ? [
        { name: 'Dashboard', to: '/admin/dashboard' },
        { name: 'Tracker Details', to: '/admin/tracker' },
    ] : [];

    return (
        <div>
            <Paper className="table-outers mb-2">
                {!id && (
                    <Toolbar>
                        <div className="breadcrumbs" style={{ display: 'flex', alignItems: 'center' }}>
                            {breadcrumbs.map((crumb, index) => (
                                <React.Fragment key={index}>
                                    {index > 0 && <img src={arrowImage} alt="arrow" style={{ margin: '0 8px', verticalAlign: 'middle' }} />}
                                    {index === breadcrumbs.length - 1 ? (
                                        <Typography variant="body2" component="span" style={{ color: 'white' }}>{crumb.name}</Typography>
                                    ) : (
                                        <Link to={crumb.to} className="breadcrumb-link" style={{ color: 'white' }}>{crumb.name}</Link>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </Toolbar>
                )}
                <Toolbar>
                    <Typography
                        sx={{ flex: '1 1 40%' }}
                        variant="h5"
                        id="tableTitle"
                        className="inner-headings"
                    >
                        Tracker Details
                    </Typography>
                    <div className="flex-boxinner">
                        <Typography
                            sx={{ flex: '1 1 auto' }}
                        >
                            <SearchElement
                                searchFn={searchHandler}
                                searchTag={"Search Tracker Details..."}
                            />

                        </Typography>
                        <Tooltip title="Add Tracker Info" arrow className="me-1 mt-1">
                            <Button className="secondary-btn" onClick={addForm} type="submit" variant="contained" color="primary">Add Tracker Info</Button>
                        </Tooltip>
                    </div>
                </Toolbar>
                <StyledEngineProvider>
                    <TableContainer component={Paper} className="table-outers">
                        <Table
                            sx={{ minWidth: 650 }}
                            size="small" aria-label="a dense table"
                            className="table-outers"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableHeadElement
                                        setHead={tableColumns ? tableColumns : []}
                                        order={order}
                                        orderBy={orderBy}
                                        sortFn={(event, id: string) =>
                                            createSortHandler(event, id as keyof SortingData)
                                        }
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableBodyElement
                                    selectedColumns={[
                                        "organization_name",
                                        "vehicle_type",
                                        "base_url",
                                    ]}
                                    setData={trackerData}
                                    editFn={(id: string) => editForm(id)}
                                    deleteFn={(id: any) => {
                                        setIsClose(true);
                                        setId(id);
                                    }}
                                    viewTrackerFn={(id) => {
                                        navigate(`/admin/tracker/${id}`, {
                                            state: additionalOptions
                                        })
                                    }}

                                    pageData={{
                                        limit:
                                            rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                        page: data?.response.page as number,
                                    }}
                                />
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={data?.response.total ?? 0}
                            page={data?.response.page ?? 0}
                            rowsPerPageOptions={[20, 50, 100]}
                            onPageChange={handleChangePage}
                            rowsPerPage={
                                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                            }
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </StyledEngineProvider>
            </Paper>
            {open && <AddEditTrackerInfo open={open} handleModel={handleModel} editId={editId} siteOrgDetail={getData} trackerData={trackerData} />}
            <ConfirmBox
                title="Tracker Info Delete Confirmation"
                message="Are you sure, you want to delete this Tracker Info?"
                open={isClose}
                onClose={confirmBoxClose}
            />
        </div>
    )
}

export default TrackerDetails;