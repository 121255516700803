import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const UserApi = createApi({
  reducerPath: "userApi",

  baseQuery: axiosBaseQuery(),

  tagTypes: ["userTag", "userDetailTag"],

  endpoints: (builder) => ({
    userList: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/user/user_list`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
          user_id: pageData.user_id,
          site_id: pageData.site_id,
          organization_id: pageData.organization_id,
        },
      }),
      providesTags: ["userTag"],
    }),

    createUser: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/user/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["userTag"],
    }),

    getUserById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/user/getUser/${id}`,
        method: "GET",
      }),
      providesTags: ["userTag"],
    }),

    editUser: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/user/edit/${data.get("id")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["userTag"],
    }),
    deleteUser: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/user/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["userTag"],
    }),
    getDashboardData: builder.query<Response<any>, void>({
      query: () => ({
        url: `/user/dashboard`,
        method: "GET",
      }),
      providesTags: ["userTag"],
    }),
    getProfileDetail: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/user/profile/${id}`,
        method: "GET",
      }),
      providesTags: ["userTag"],
    }),

    getDocumentDetail: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/user/document/${id}`,
        method: "GET",
      }),
      providesTags: ["userTag"],
    }),

    findOrganizationNames: builder.query<Response<any>, void>({
      query: () => ({
        url: `/user/organization_name`,
        method: "GET",
      }),
      providesTags: ["userTag"],
    }),
    updateUserStatus: builder.mutation<
      Response<any>,
      { id: string; status: boolean }
    >({
      query: ({ id, status }) => ({
        url: `/user/updateStatus/${id}`,
        method: "POST",
        body: { status },
      }),
      invalidatesTags: ["userTag"],
    }),
  }),
});

export const {
  useUserListQuery,
  useCreateUserMutation,
  useGetUserByIdQuery,
  useEditUserMutation,
  useDeleteUserMutation,
  useGetDashboardDataQuery,
  useGetProfileDetailQuery,
  useFindOrganizationNamesQuery,
  useGetDocumentDetailQuery,
  useUpdateUserStatusMutation,
} = UserApi;
