import { Box, Button, Container, TextField, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setAuth, setError, useDoLoginMutation } from "../redux-services";
import { useDispatch } from "react-redux";
import AlertBox from "../components/AlertBox";
import Error from "../components/ErrorBox/Error";
import logoImage from "../assests/img/logo.png"
export interface LoginType {
    email: string;
    password: string;
}

const Login = () => {
    const initialState = {
        email: '',
        password: '',
    };
    const [doLogin, result] = useDoLoginMutation();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState<LoginType>(initialState);
    const [isShowPwd, setIsShowPwd] = useState<boolean>(false);
    const [checkSession, setCheckSession] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('accessToken')) {
            setCheckSession(false);
            navigate('/admin/dashboard');
        } else {
            setCheckSession(true);
        }
    }, [navigate]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const updatedFormData = {
            ...formData,
            email: formData.email.toLowerCase()
        };
        dispatch(setError([]));
        await doLogin(updatedFormData);
    };

    const forgotPasswordClick = () => {
        navigate('/forgot_password');
    };

    if (result?.isSuccess && result?.data?.status === 200) {
        dispatch(setAuth());
        navigate("/admin/dashboard");
    }

    return (
        <Box className="login-container">
            {checkSession &&
                <Container component="main" maxWidth="sm">
                    <Box className="text-center">
                        <img
                            src={logoImage}
                            alt="white_logo"
                            className="web-logo"
                        />
                    </Box>
                    <AlertBox />
                    <Box className="loginBox"
                        sx={{
                            boxShadow: 3,
                            borderRadius: 2,
                            px: 4,
                            py: 6,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography className="inner-headings" component="h1" variant="h5">
                            Sign In to Your Account
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                autoFocus
                                value={formData.email}
                                onChange={handleOnChange}
                            />
                            <span className="password-login">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={!isShowPwd ? "password" : 'text'}
                                    id="password"
                                    autoComplete="password"
                                    value={formData.password}
                                    onChange={handleOnChange}
                                />
                                <Box onClick={() => setIsShowPwd(!isShowPwd)} className="pwd-icon">
                                    {isShowPwd ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </Box>
                                <Error current_key="password" />
                            </span>
                            <Button
                                type="submit"
                                fullWidth
                                className="secondary-btn py-3"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                        </Box>
                        <button
                             style={{
                                background: "none",  
                                color:"white",
                                border: "none",      
                                cursor: "pointer",  
                                display: "inline-block",     
                                alignItems: "center", 
                                justifyContent: "center", 
                                width: "fit-content", 
                                padding: "0",      
                                margin: "0",        
                              }}
                            className="forgot-password-link"
                            onClick={forgotPasswordClick}
                        >
                            Forgot password?
                        </button>
                    </Box>
                </Container>
            }
        </Box>
    )
}

export default Login;
