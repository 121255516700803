import React from "react";
import { Alert, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAlert,
  removeAlert,
} from "../redux-services/slices/AlertBoxSlice";

const AlertBox: React.FunctionComponent = (
) => {
  const dispatch = useDispatch();

  const handleClose = (
    _event: React.SyntheticEvent<Element, Event>,
    index: number
  ) => {
    dispatch(removeAlert(index));
  };

  const alertValue = useSelector(GetAlert);
  
  return (
    <Stack className="alert-head" spacing={2}>
      {alertValue.map((item, index) => (
        <Alert
          key={index}
          className="alert-msg"
          severity={item.type}
          onClose={(e) => handleClose(e, index)}
        >
          {item.message}
        
        </Alert>
      ))}
    </Stack>
  );
};
export default AlertBox;
