import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Modal,
  Backdrop,
  Box,
  Fade,
  Typography,
  Divider,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setError,
  useCreateAccessoriesMutation,
  useEditAccessoriesMutation,
  useGetAccessoriesByIdQuery,
  useGetMachineInfoByIdQuery,
} from "../../../redux-services";
import Error from "../../../components/ErrorBox/Error";
import ClearIcon from "@mui/icons-material/Clear";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Image } from "@mui/icons-material";

export interface UserState {
  accessories_name: string;
  machine_id: any;
  machine_type: any;
  number_plate: any;
  billing_date: string;
  warranty_till_date: string;
  amount: any;
  images: any;
  deleted_images?: any;
  image_url: any[];
  urls?: any[];
  used_till_date: string;
}

interface FormErrors {
  accessories_name?: string;
  billing_date?: string;
  warranty_till_date?: string;
  amount?: any;
  used_till_date?: string;
}
const AddEditAccessory: React.FC<{
  id: string;
  showModal: boolean;
  setShowModal: any;
  setEditId: any;
  paramId: any;
}> = ({ id, showModal, setShowModal, setEditId, paramId }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };
  const intialState = {
    accessories_name: "",
    machine_id: "",
    machine_type: "",
    number_plate: "",
    billing_date: "",
    warranty_till_date: "",
    amount: "",
    images: [],
    deleted_images: [],
    image_url: [],
    urls: [],
    used_till_date: "",
  };
  let dispatch = useDispatch();
  const [formData, setFormData] = useState<UserState>(intialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [createAccessories, result] = useCreateAccessoriesMutation();
  const [editAccessories, editResult] = useEditAccessoriesMutation();
  const { data: MachineInfoData } = useGetMachineInfoByIdQuery(paramId, {
    skip: !paramId,
  });
  const { data: AccessoriesData } = useGetAccessoriesByIdQuery(id, {
    skip: !id,
  });
  useEffect(() => {
    if (AccessoriesData?.status === 200) {
      let accessoriesResponse = AccessoriesData?.response;
      let data: any = accessoriesResponse;
      setFormData({
        ...formData,
        accessories_name: data?.accessories_name ?? "",
        machine_id: paramId ?? "",
        billing_date: data?.billing_date ?? "",
        used_till_date: data?.used_till_date ?? "",
        warranty_till_date: data?.warranty_till_date ?? "",
        amount: data?.amount ?? "",
        images: data?.images?.length
          ? data.images.map((image: any) => image.path)
          : [],
        image_url: data?.encodedUrl?.length ? data?.encodedUrl : [],
        urls: data?.images?.length
          ? data?.images.map((image: any) => image.fullUrl)
          : [],
      });
    }
    return () => {
      dispatch(setError([]));
    };
  }, [AccessoriesData]);

  if (result.isSuccess && result.data.status === 200) {
    setShowModal(false);
    setEditId("");
  }

  if (editResult.isSuccess && editResult.data.status === 200) {
    setShowModal(false);
    setEditId("");
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(setError([]));
    let data = new FormData();
    for (const item of formData.images) {
      data.append("images", item);
    }
    if (id) {
      for (const item of formData.deleted_images) {
        data.append("deleted_images", item);
      }
    }
    data.append("accessories_name", formData.accessories_name.trim());
    data.append("machine_id", paramId);
    data.append("billing_date", formData.billing_date);
    data.append("warranty_till_date", formData.warranty_till_date);
    data.append("amount", formData.amount);
    data.append("used_till_date", formData.used_till_date);
    data.append(
      "organization_name",
      MachineInfoData?.response.organization_name
    );
    data.append("id", id ?? null);

    id ? editAccessories(data) : createAccessories(data);
  };

  const validate = (formData: UserState) => {
    const errors: Partial<UserState> = {};
    const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]+/;

    if (!formData.accessories_name.trim()) {
      errors.accessories_name = "Accessories Name is required!";
    } else if (regex.test(formData.accessories_name)) {
      errors.accessories_name = "Accessories Name contain special characters!";
    }

    if (!formData.amount) {
      errors.amount = "Accessory Amount is required";
    } else if (!regex.test(formData.amount)) {
      errors.amount = "Accessory Amount must contain only numeric characters";
    }

    if (!formData.billing_date.trim()) {
      errors.billing_date = "Billing Date is required!";
    }

    if (!formData.warranty_till_date.trim()) {
      errors.warranty_till_date = "Warranty Date is required!";
    }
    if (!formData.used_till_date.trim()) {
      errors.used_till_date = "Used Date is required!";
    }
    const billingDate = new Date(formData.billing_date);
    const warrantyDate = new Date(formData.warranty_till_date);
    if (billingDate && warrantyDate) {
      if (warrantyDate < billingDate) {
        errors.warranty_till_date =
          "Warranty Date cannot be less than Billing Date!";
      } else if (billingDate < warrantyDate) {
        errors.billing_date =
          "Billing Date cannot be greater than Warranty Date!";
      }
    }
    return errors;
  };
  const handleChange = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.trimStart(),
    }));

    const errors = validate({ ...formData, [name]: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors[name as keyof UserState],
    }));
  };
  const handleChangeImage = (event: any) => {
    let setFileUrl: string[] = [];
    let file = Array.from(event.target.files);
    for (const element of event.target.files) {
      setFileUrl.push(URL.createObjectURL(element));
    }
    setFormData({
      ...formData,
      images: [...formData.images, ...file],
      image_url: [...formData.image_url, ...setFileUrl],
    });
    const thumbnailElement = document.getElementById(
      "images"
    ) as HTMLInputElement | null;

    if (thumbnailElement) {
      thumbnailElement.value = "";
    }
  };

  const handleRemoveImage = useCallback(
    (index: number) => {
      const updatedImages = [...formData.images];
      updatedImages.splice(index, 1);

      let deletedImgArr = [];
      if (formData.urls?.length) {
        deletedImgArr.push(formData.urls[index]);
      }

      const updatedFiles = [...formData.image_url];
      updatedFiles.splice(index, 1);
      setFormData({
        ...formData,
        images: updatedImages,
        image_url: updatedFiles,
        deleted_images: [...formData.deleted_images, ...deletedImgArr],
      });
    },
    [formData]
  );

  return (
    <Container maxWidth={false} className="p-0">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showModal}>
          <Box sx={style} className="form-outers karuModal">
            <Typography
              id="transition-modal-title"
              variant="h5"
              className="inner-headings"
              component="h2"
            >
              {id ? "Edit Accessory" : "Add Accessory"}
            </Typography>
            <HighlightOffIcon
              className="closeicon"
              onClick={() => {
                setShowModal(false);
                setEditId("");
              }}
            />

            <Box className="modalBody">
              <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      fullWidth
                      id="accessories_name"
                      label="Accessory Name"
                      name="accessories_name"
                      type="text"
                      size="small"
                      autoComplete="accessories_name"
                      value={formData.accessories_name}
                      onChange={handleChange}
                      error={!!formErrors.accessories_name}
                      helperText={formErrors.accessories_name}
                    />
                    <Error current_key="accessories_name" />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="billing_date"
                      label="Billing Date"
                      className="calendradate"
                      name="billing_date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      required
                      autoComplete="billing_date"
                      value={formData.billing_date}
                      onChange={handleChange}
                      error={!!formErrors.billing_date}
                      helperText={formErrors.billing_date}
                    />
                    <Error current_key="billing_date" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="warranty_till_date"
                      label="Warranty Till Date"
                      className="calendradate"
                      name="warranty_till_date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      required
                      autoComplete="warranty_till_date"
                      value={formData.warranty_till_date}
                      onChange={handleChange}
                      error={!!formErrors.warranty_till_date}
                      helperText={formErrors.warranty_till_date}
                    />
                    <Error current_key="warranty_till_date" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="amount"
                      label="Amount"
                      name="amount"
                      size="small"
                      required
                      type="number"
                      autoComplete="amount"
                      value={formData.amount}
                      onChange={handleChange}
                      error={!!formErrors.amount}
                      helperText={formErrors.amount}
                    />
                    <Error current_key="amount" />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="images"
                        name="images"
                        label="Accessories Images"
                        className="accessory_img"
                        type="file"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        inputProps={{ accept: "image/*", multiple: true }}
                        autoComplete="images"
                        onChange={handleChangeImage}
                      />
                      <Error current_key="images" />
                    </Grid>
                    <Grid item xs={12} className="imageDiv">
                      {formData?.image_url?.length
                        ? formData.image_url.map(
                            (image: string, index: number) => (
                              <div key={image}>
                                <img
                                  src={image}
                                  alt={`accessories-img-${index}`}
                                  className="preview-img"
                                />
                                <Image
                                  onClick={() => handleRemoveImage(index)}
                                  title="Remove Image"
                                  component={"img"}
                                >
                                  <ClearIcon />
                                </Image>
                              </div>
                            )
                          )
                        : ""}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="save-btn"
                      onSubmit={handleSubmit}
                      style={{ marginRight: "10px" }}
                      disabled={Object.values(formErrors).some(
                        (error) => !!error
                      )}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="contained"
                      className="close-btn"
                      color="secondary"
                      onClick={() => {
                        setShowModal(false);
                        setEditId("");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default memo(AddEditAccessory);
