import { createApi } from "@reduxjs/toolkit/query/react";

import { MtUser, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export interface LoginRequest {
  email: string;

  password: string;
}

export interface LogoutRequest {
  token: string;
  userId:string;
}

export interface ResetPasswordRequest {
  password: string;
  confirm_password: string;
  email: string;
}

export interface ChangePasswordRequest {
  password: string;
  confirm_password: string;
  current_password: string;
  email: string;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface LoginResponse extends MtUser { }

export const LoginApi = createApi({
  reducerPath: "loginAPI",

  baseQuery: axiosBaseQuery(),

  tagTypes: ["LoginTag", "userDetailTag"],

  endpoints: (builder) => ({
    doLogin: builder.mutation<Response<LoginResponse>, LoginRequest>({
      query: (mtuser: LoginRequest) => ({
        url: `/auth/login`,

        method: "POST",

        body: mtuser,
      }),

      invalidatesTags: ["LoginTag", "userDetailTag"],

      transformResponse: (result: Response<LoginResponse>, _meta, _arg) => {
        if (result.status === 200) {
          localStorage.setItem("accessToken", result.response.token);
        }

        return result;
      },
    }),

    resetPassword: builder.mutation<
      Response<LoginResponse>,
      ResetPasswordRequest
    >({
      query: (mtuser: ResetPasswordRequest) => ({
        url: `/auth/reset_password/${mtuser.email}`,
        method: "PATCH",
        body: mtuser,
      }),
      invalidatesTags: ["LoginTag"],
    }),
    changePassword: builder.mutation<
      Response<LoginResponse>,
      ChangePasswordRequest
    >({
      query: (mtuser: ChangePasswordRequest) => ({
        url: `/auth/change_password/${mtuser.email}`,
        method: "PATCH",
        body: mtuser,
      }),
      invalidatesTags: ["LoginTag"],
    }),
    forgotPassword: builder.mutation<
      Response<LoginResponse>,
      ForgotPasswordRequest
    >({
      query: (forgotPasswordRequest: ForgotPasswordRequest) => ({
        url: `/auth/forgot_password`,
        method: "POST",
        body: forgotPasswordRequest,
      }),
      invalidatesTags: ["LoginTag"],
    }),
    logout: builder.mutation<void, LogoutRequest>({
      query: ({ token, userId }) => ({
          url: '/auth/logout',
          method: 'POST',
          body: {
              token,
              userId,
          },
          headers: {
              'Content-Type': 'application/json',
          },
      }),
      invalidatesTags: ['LoginTag'], 
  }),

   
    getEmailVerification: builder.query<Response<any>, string | undefined>({
      query: (email: string | undefined) => ({
        url: `/auth/check_email/${email}`,
        method: "GET",
      }),
      providesTags: ["LoginTag"],
    }),
  }),
});

export const {
  useDoLoginMutation,
  useResetPasswordMutation,
  useGetEmailVerificationQuery,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useLogoutMutation,
} = LoginApi;
