
import { Search } from "@mui/icons-material";
import { 
     Grid, TextField } from "@mui/material";

export interface SearchFields {
    searchFn: (event: React.ChangeEvent<HTMLInputElement>) => void;
    searchTag?: string;
}
export const SearchElement: React.FunctionComponent<SearchFields> = (
    props: SearchFields
) => {
    return (
        <Grid container className="search">
            <TextField
                id="search"
                type="text"
                placeholder={props.searchTag}
                name="name"
                autoComplete="off"
                onChange={props.searchFn}
            />
            <div className="search-icon">
                <Search />
            </div>
        </Grid>
    );
};
