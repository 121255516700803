import React, { FC, memo, useCallback, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Typography,
  Fade,
  Backdrop,
  Modal,
  TextareaAutosize,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setError,
  useCreateWorkItemMutation,
  useEditWorkItemMutation,
  useGetWorkItemByIdQuery,
  useGetBoreAreaDetailQuery,
  useGetSitesByIdQuery,
} from "../../../../redux-services";
import Error from "../../../../components/ErrorBox/Error";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { WorkItems } from "../../../../ constants";
import PreviousRecordWorkItem from "./PreviousRecordWorkItem";
import { RootState } from "../../../../store/store";
import { useParams } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
export interface UserState {
  bore_area_id: any;
  site_id: any;
  shift_id: any;
  work_type: any;
  chainage_start_point: any;
  chainage_end_point: any;
  entry_date: string | Record<string, any>;
  comment: string;
  total_volume_dump: number;
  lead_chainage_distance: any;
  error: any;
}
interface FormErrors {
  chainage_start_point?: any;
  chainage_end_point?: any;
  entry_date?: string;
  comment?: string;
}
const AddEditWorkItem: FC<{
  open: boolean;
  handleModel: any;
  siteInfo: { id: string; name: string };
  id: string;
}> = ({ open, handleModel, siteInfo, id }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };
  const intialState = {
    site_id: siteInfo,
    shift_id: "",
    bore_area_id: "",
    work_type: 0,
    chainage_start_point: 0,
    chainage_end_point: 0,
    entry_date: "",
    comment: "",
    total_volume_dump: 0,
    lead_chainage_distance: 0,
    error: "",
  };
  let dispatch = useDispatch();
  let { id: siteId } = useParams();

  const [formData, setFormData] = useState<UserState>(intialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [submitted, setSubmitted] = useState(false);

  const [showPrevious, setShowPrevious] = useState(false);

  const [createWorkItem, result] = useCreateWorkItemMutation();
  const [editWorkItem, editResult] = useEditWorkItemMutation();
  const siteOrgId = useSelector((state: RootState) => state.auth.siteOrgId);

  const { data: SiteWorkLogsData } = useGetWorkItemByIdQuery(id, {
    skip: !id,
  });

  const { data: getData } = useGetSitesByIdQuery(siteId);
  console.log("gtttt",getData?.response?.shiftNames);

  const userDetail = useSelector((state: RootState) => state.auth.user);

  const { data: BoreAreaNames } = useGetBoreAreaDetailQuery({
    organization_id: userDetail?.organization_id ?? "",
    site_id: siteInfo.id,
  });

  useEffect(() => {
    if (SiteWorkLogsData?.status === 200) {
      const data = SiteWorkLogsData?.response;
      const unixTimestamp1 = data?.entry_date;
      const dateTime = dayjs(new Date(unixTimestamp1));
      let workType = WorkItems.find((item: any) => item.id === data?.work_type);

      setFormData({
        ...formData,
        site_id: data.site_id,
        chainage_start_point: data.chainage_start_point,
        chainage_end_point: data.chainage_end_point,
        entry_date: dateTime,
        shift_id: data.shiftData[0],
        bore_area_id: data.boreAreaData[0],
        comment: data.comment,
        work_type: workType ?? {},
        total_volume_dump: 0,
        lead_chainage_distance: data?.lead_chainage_distance ?? 0,
      });
    }
  }, [SiteWorkLogsData]);

  if (result.isSuccess && result.data.status === 200) {
    handleModel(false);
  }

  if (editResult.isSuccess && editResult.data.status === 200) {
    handleModel(false);
  }

  const validate = (formData: UserState) => {
    const errors: Partial<UserState> = {};
    if (formData.chainage_start_point === "") {
      errors.chainage_start_point = "Chainage Start Point is required!";
    }
    return errors;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitted(true);
    dispatch(setError([]));

    let date_time: any;
    if (typeof formData.entry_date === "object") {
      date_time = formData.entry_date.valueOf();
    } else {
      date_time = formData.entry_date;
    }

    const leadChainageDistance = Math.abs(
      formData.bore_area_id.chainage_point - formData.chainage_start_point
    );

    let data = {
      ...formData,
      entry_date: date_time,
      site_id: siteInfo?.id,
      bore_area_id: formData?.bore_area_id?._id,
      shift_id: formData?.shift_id?._id,
      work_type: formData?.work_type?.id ?? 1,
      lead_chainage_distance: leadChainageDistance ?? 0,
      id: id ?? null,
      organization_id: siteOrgId,
    };

    id ? editWorkItem(data) : createWorkItem(data);
  };
  const handleDateTimeChange = (fieldName: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: new Date(value).valueOf(),
    }));
  };

  const selectChange = (event: any) => {
    setFormData({
      ...formData,
      shift_id: event.target.value as string,
    });
  };

  const selectChangeBoreArea = (event: any) => {
    setFormData({
      ...formData,
      bore_area_id: event.target.value as string,
    });
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.trimStart(),
    }));

    const errors = validate({ ...formData, [name]: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors[name as keyof UserState],
    }));
  };

  const selectWorkType = (event: any) => {
    setFormData({ ...formData, work_type: event.target.value as string });
  };

  const handlePreviousModal = useCallback((isOpen: boolean) => {
    setShowPrevious(isOpen);
  }, []);

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="form-outers karuModal">
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                id="transition-modal-title"
                variant="h5"
                className="inner-headings"
                component="h2"
              >
                {(id ? "Edit" : "Add") + ` Work Item`}
              </Typography>

              {!id && (
                <Button
                  variant="contained"
                  color="primary"
                  className="secondary-btn me-5"
                  onClick={() => setShowPrevious(true)}
                >
                  Fill Previous Date Record
                </Button>
              )}

              {id && (
                <HighlightOffIcon
                  className="closeicon"
                  onClick={() => handleModel(false)}
                />
              )}
            </Box>
            <Box>
              <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className="custom-datepicker"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%",
                        color: "#ffff",
                      }}
                    >
                      <DatePicker
                        label="Entry Date *"
                        name="entry_date"
                        disableFuture={true}
                        slotProps={{
                          textField: {
                            error: !formData.entry_date,
                            helperText:
                              submitted && !formData.entry_date
                                ? "Entry Date is required"
                                : "",
                          },
                        }}
                        value={formData.entry_date}
                        onChange={(newValue: any) =>
                          handleDateTimeChange("entry_date", newValue)
                        }
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                  </LocalizationProvider>
                  <Grid item xs={12} md={12}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Shifts *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.shift_id}
                          label="Shifts"
                          required
                          onChange={selectChange}
                          renderValue={() => formData.shift_id?.name}
                        >
                          {getData?.response?.shiftNames?.length > 0 ? (
                              getData?.response?.shiftNames
                              .filter((item: any) => !item.is_delete)  // Filter shifts where is_delete is false
                              .map((item: any) => (
                                <MenuItem
                                  value={item}
                                  key={item?._id}
                                  selected={formData.shift_id?._id === item?._id}
                                >
                                  {item?.name}
                                </MenuItem>
                              ))
                          ) : (
                            <MenuItem>
                              No Record Found{" "}
                              <a
                                href={`${process.env.REACT_APP_API_URL}/admin/shift`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#fff" }}
                              >
                                (Click here to add shift)
                              </a>
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      fullWidth
                      id="chainage_start_point"
                      label="Chainage Start Point"
                      name="chainage_start_point"
                      type="number"
                      size="small"
                      autoComplete="chainage_start_point"
                      value={formData.chainage_start_point}
                      onChange={handleChange}
                      error={!!formErrors.chainage_start_point}
                      helperText={formErrors.chainage_start_point}
                    />
                    <Error current_key={formData?.chainage_start_point} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      fullWidth
                      id="chainage_end_point"
                      label="Chainage End Point"
                      name="chainage_end_point"
                      type="number"
                      size="small"
                      autoComplete="chainage_end_point"
                      value={formData.chainage_end_point}
                      onChange={handleChange}
                      error={!!formErrors.chainage_end_point}
                      helperText={formErrors.chainage_end_point}
                    />
                    <Error current_key={formData?.chainage_end_point} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Work Types*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.work_type}
                          label="Work Types"
                          required
                          onChange={selectWorkType}
                          renderValue={() => formData.work_type?.title}
                        >
                          {WorkItems?.map((item: any, index: number) => (
                            <MenuItem value={item} key={item}>
                              {item?.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  {formData.work_type?.id === 1 && (
                    <Grid item xs={12} md={6}>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Bore Areas *
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.bore_area_id}
                            label="Bore Areas"
                            required
                            onChange={selectChangeBoreArea}
                            renderValue={() => formData.bore_area_id?.name}
                          >
                            {BoreAreaNames?.response?.map(
                              (item: any, index: number) => (
                                <MenuItem value={item} key={item}>
                                  {item?.name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                          {!id &&
                            BoreAreaNames &&
                            BoreAreaNames?.response?.length === 0 && (
                              <Typography
                                variant="body1"
                                className="error-alert"
                              >
                                Bore area does not exist for this site.
                              </Typography>
                            )}
                        </FormControl>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      id="comment"
                      label="Comment"
                      InputProps={{
                        inputComponent: TextareaAutosize,
                        rows: 3,
                      }}
                      size="small"
                      name="comment"
                      type="textarea"
                      autoComplete="comment"
                      value={formData.comment}
                      onChange={handleChange}
                    />
                    <Error current_key="comment" />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="save-btn"
                      onSubmit={handleSubmit}
                      disabled={Object.values(formErrors).some(
                        (error) => !!error
                      )}
                      style={{ marginRight: "10px" }}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="close-btn"
                      onClick={() => handleModel(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {showPrevious && (
        <PreviousRecordWorkItem
          open={showPrevious}
          handleModel={handlePreviousModal}
          siteId={siteInfo.id ?? ""}
          setState={setFormData}
        />
      )}
    </React.Fragment>
  );
};

export default memo(AddEditWorkItem);
