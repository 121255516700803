import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
    Button,
    Paper,
    Snackbar,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
    debounce,
} from "@mui/material";
import { TableBodyElement } from '../../../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../../../components/TableBody/TableHeadElement';
import { ColumnsType, Order, PageMeta, SortingData } from '../../../../types/CommonTypes';
import { useDeleteEmployeeMutation, useUpdateEmployeeStatusMutation, useEmployeeListQuery } from '../../../../redux-services';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmBox from '../../../../components/ConfirmBox/ConfirmBox';
import { SearchElement } from '../../../../components/Search/Search';
import EmployeeAddEditModal from './EmployeeAddEditModal';
import { useGetSitesByIdQuery } from '../../../../redux-services';
import TakeAttendance from './TakeAttendance';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

interface MachineType {
    _id: string;
    status: boolean;
}
const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "username",
        sort: true,
        label: "Employee Name",
    },
    {
        id: "phone",
        sort: false,
        label: "Contact No."
    },
    {
        id: "role_name",
        sort: false,
        label: "Employee Role"
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "15%" },
    },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const SiteUserList: FC<{ siteDetail: { id: string, name: string } }> = ({ siteDetail }) => {

    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,

    };
    let navigate = useNavigate();
    let query = useLocation();

    const { id } = useParams();

    const [page, setPage] = useState<number>(1);

    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
    const [activeMachineId, setActiveMachineId] = useState<string>("");
    const [machineStatus, setMachineStatus] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const [userList, setUserList] = useState<PageMeta>(initialState);
    const [isClose, setIsClose] = useState<boolean>(false);
    const [Id, setId] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>('');
    const [isActive, setIsActive] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const siteOrgId = useSelector((state: RootState) => state.auth.siteOrgId);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [openAttendanceModal, setOpenAttendanceModal] = useState(false);

    const { data } = useEmployeeListQuery({
        per_page: userList.per_page ?? rowsPerPage,
        page: query.state ?? userList.page,
        order: userList.order ?? order,
        order_by: userList.order_by ?? orderBy,
        searchText: userList.searchText ?? search,
        site_id: id,
        organization_id: siteOrgId
    }, { skip: !siteOrgId });
    const { data: getData } = useGetSitesByIdQuery(id);
    useEffect(() => {
        if (getData?.status === 200) {
            let data = getData?.response;
            setIsButtonDisabled(data.is_deactivate)
            setIsActive(data.is_active)
        }
    }, [getData]);

    const [deleteEmployee] = useDeleteEmployeeMutation();
    const [updateEmployeeStatus] = useUpdateEmployeeStatusMutation();

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setUserList({
            ...userList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setUserList({ ...userList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setUserList({
            ...userList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setUserList({
                page: 1,
                per_page: userList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

    const confirmBoxClose = (res: boolean) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteEmployee(Id);
        } else {
            setIsClose(res);
        }
    };
    const handleModel = useCallback((isOpen: boolean) => {
        setOpen(isOpen);
    }, []);
    const handleToggleActive = (id: string) => {
        const machine = data?.response.data.find((machine: MachineType) => machine._id === id);
        if (machine) {
            setActiveMachineId(id);
            setIsConfirmationOpen(true);
            setMachineStatus(machine.is_active);
        }
    };
    const handleConfirmationResponse = (confirmed: boolean) => {
        if (confirmed) {
            const newStatus = !machineStatus;
            updateEmployeeStatus({ id: activeMachineId, status: newStatus });
        }
        setActiveMachineId("");
        setIsConfirmationOpen(false);
    };
    const handleShowMessage = () => {
        setSnackbarOpen(true);
        setTimeout(() => {
            setSnackbarOpen(false);
        }, 3000);
    };
    const isActivefn = (id: any) => {
        if (isButtonDisabled) {
            alert("Cannot toggle active status (site is deactivated)");
            return;
        }
        handleToggleActive(id);
    };
    const deleteFn = (id: string) => {
        if (isButtonDisabled) {
            alert("Site cannot be deleted as its deactivated")
            return;
        }
        setIsClose(true);
        setId(id);
    };
    const editFn = (id: string) => {
        if (isButtonDisabled) {
            alert("Site cannot be edited as its deactivated")
            return;
        }
        setOpen(true);
        setEditId(id);
    };
    return (
        <>
            {(openAttendanceModal) ?
                <TakeAttendance
                    open={openAttendanceModal}
                    setOpenAttendanceModal={setOpenAttendanceModal}
                    siteInfo={siteDetail}
                />
                :
                <div className='table'>
                    <Paper className="table-outers">
                        <Toolbar>
                            <Typography
                                sx={{ flex: '1 1 40%' }}
                                variant="h5"
                                id="tableTitle"
                                component="div"
                                className="inner-headings"
                            >
                                Employee List
                            </Typography>

                            <div className="flex-boxinner">
                                <Typography
                                    sx={{ flex: '1 1 auto' }}
                                >
                                    <SearchElement
                                        searchFn={searchHandler}
                                        searchTag={`Search Employee...`}
                                    />

                                </Typography>
                                {isButtonDisabled ? null : (
                                    <>                                
                                    <Tooltip title={`Add Employee`} arrow className="me-1 mt-1">
                                        <Button className="secondary-btn" onClick={() => { setOpen(true); setEditId(''); handleShowMessage(); }} type="submit" variant="contained" color="primary" disabled={isButtonDisabled}>Add Employee</Button>
                                    </Tooltip>
                                        <Tooltip title={`Explore Employee Attendance`} arrow className="me-1 mt-1">
                                            <Button className="secondary-btn" onClick={() => { setOpenAttendanceModal(true); setEditId(''); handleShowMessage(); }} type="submit" variant="contained" color="primary" disabled={isButtonDisabled}>Explore Employee Attendance</Button>
                                        </Tooltip>
                                    </>
                                )}
                                <Snackbar
                                    open={snackbarOpen && !isActive}
                                    autoHideDuration={3000}
                                    onClose={() => setSnackbarOpen(false)}
                                    message="Please activate this site to add Employee."
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    style={{ marginTop: '140px' }}
                                />
                            </div>
                        </Toolbar>
                        {isButtonDisabled ? null : (
                            <StyledEngineProvider>
                            <TableContainer component={Paper} className="table-outers">
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small" aria-label="a dense table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableHeadElement
                                                setHead={tableColumns ? tableColumns : []}
                                                order={order}
                                                orderBy={orderBy}
                                                sortFn={(event: any, id: string) =>
                                                    createSortHandler(event, id as keyof SortingData)
                                                }
                                            />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableBodyElement
                                            selectedColumns={[
                                                "username",
                                                "phone",
                                                "role_name"
                                            ]}
                                            setData={data?.response.data ? data?.response.data : []}
                                            viewProfileFn={(id: string) => {
                                                navigate(`/admin/profile/${id}`, {
                                                    state: page,
                                                })
                                            }}
                                            editFn={editFn}
                                            deleteFn={deleteFn}

                                            isActivefn={(id) => isActivefn(id)}
                                            pageData={{
                                                limit:
                                                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                                page: data?.response.page as number,
                                            }}
                                        />
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={data?.response.total ?? 0}
                                    page={data?.response.page ?? 0}
                                    rowsPerPageOptions={[20, 50, 100]}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={
                                        rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                                    }
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </StyledEngineProvider>
                        )}
                    </Paper>
                    <ConfirmBox
                        title={`Employee ${machineStatus ? 'Deactivate' : 'Activate'} Confirmation`}
                        message={activeMachineId ? `Are you sure you want to ${machineStatus ? 'deactivate' : 'activate'} this Employee?` : ''}
                        open={isConfirmationOpen}
                        onClose={handleConfirmationResponse}
                    />
                    <ConfirmBox
                        title="Employee Delete Confirmation"
                        message="Are you sure, you want to delete this Employee?"
                        open={isClose}
                        onClose={confirmBoxClose}
                    />
                    {open && isActive &&
                        <EmployeeAddEditModal
                            open={open}
                            handleModel={handleModel}
                            siteInfo={siteDetail}
                            id={editId}
                        />
                    }
                </div>
            }
        </>
    )
}

export default SiteUserList