import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";


export const AccessoriesApi = createApi({
    reducerPath: "accessoriesApi",

    baseQuery: axiosBaseQuery(),

    tagTypes: ["accessoriesTag"],

    endpoints: (builder) => ({
        accessoriesList: builder.query<Response<PaginatedData<any>>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/accessories/getlist`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                },
            }),
            providesTags: ["accessoriesTag"],
        }),

        machineAccessoriesList: builder.query<Response<PaginatedData<any>>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/accessories/getMachineAccessories`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                    id: pageData.id ?? "",
                },
            }),
            providesTags: ["accessoriesTag"],
        }),

        createAccessories: builder.mutation<Response<any>, FormData>({
            query: (data: FormData) => ({
                url: `/accessories/create`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["accessoriesTag"],
        }),

        getAccessoriesById: builder.query<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/accessories/getAccessories/${id}`,
                method: "GET",
            }),
            providesTags: ["accessoriesTag"],
        }),

        editAccessories: builder.mutation<Response<any>, FormData>({
            query: (data: FormData) => ({
                url: `/accessories/edit/${data.get("id")}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["accessoriesTag"],
        }),
        deleteAccessories: builder.mutation<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/accessories/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["accessoriesTag"],
        }),
    })
});

export const {
    useAccessoriesListQuery,
    useCreateAccessoriesMutation,
    useDeleteAccessoriesMutation,
    useEditAccessoriesMutation,
    useGetAccessoriesByIdQuery,
    useMachineAccessoriesListQuery,
} = AccessoriesApi;
