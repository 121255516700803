import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";


export const BreakDownApi = createApi({
    reducerPath: "breakDownApi",

    baseQuery: axiosBaseQuery(),

    tagTypes: ["breakDownTag"],

    endpoints: (builder) => ({
        breakDownList: builder.query<Response<PaginatedData<any>>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/breakDown/getlist`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "", 
                       
                },
            }),
            providesTags: ["breakDownTag"],
        }),

        machineBreakDownList: builder.query<Response<PaginatedData<any>>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/breakDown/getMachineBreakDown`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                    id: pageData.id ?? "",
                },
            }),
            providesTags: ["breakDownTag"],
        }),

        createBreakDownList: builder.mutation<Response<any>, any>({
            query: (data: any) => ({
                url: `/breakDown/create`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["breakDownTag"],
        }),

        getBreakDownListById: builder.query<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/breakDown/getBreakDownList/${id}`,
                method: "GET",
            }),
            providesTags: ["breakDownTag"],
        }),

        editBreakDownList: builder.mutation<Response<any>, any>({
            query: (data: any) => ({
                url: `/breakDown/edit/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["breakDownTag"],
        }),
        deleteBreakDownList: builder.mutation<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/breakDown/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["breakDownTag"],
        }),
    })
});

export const {
    useBreakDownListQuery,
    useCreateBreakDownListMutation,
    useDeleteBreakDownListMutation,
    useEditBreakDownListMutation,
    useGetBreakDownListByIdQuery,
    useMachineBreakDownListQuery,
} = BreakDownApi;
