import React, { useCallback, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import { Box, Toolbar, IconButton, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import logoImage from "../../assests/img/logo.png";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  setAuthLogout,
  setUserDetail,
  useGetUserDetailQuery,
  useGetOrganizationByIdQuery,
} from "../../redux-services";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { RootState } from "../../store/store";
import AddEditOrg from "../../pages/Organiztion/ViewOrganizationInfo/AddEditOrg";
import ChangePassword from "../../pages/WorkItem/ChangePassword";
import ConfirmBox from "../ConfirmBox/ConfirmBox";

export interface ProfileMenuItem {
  key: string;
  eventHandler: React.MouseEventHandler<HTMLAnchorElement>;
  href: string;
}

export interface HeaderProps {
  openSidebarToggle: boolean;
  OpenSidebar: any;
}
const Header: React.FunctionComponent<HeaderProps> = (props: HeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useGetUserDetailQuery();
  const userId = userData?.response?.organization_id;
  const { data: organizationData, isSuccess } = useGetOrganizationByIdQuery(
    userId,
    { skip: !userId }
  );

  const username = localStorage.getItem("username");

  const userDetail = useSelector((state: RootState) => state.auth.user);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showChangePasswordModal, setShowChangePasswordModal] =
    useState<boolean>(false);
  const [editId, setEditId] = useState("");
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [logoUrl, setlogoUrl] = useState<string>();
  const [profileUrl, setProfileUrl] = useState<string>();
  const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess && organizationData?.response?.[0]?.logoEncodedUrl?.[0]) {
      setlogoUrl(organizationData?.response?.[0]?.logoEncodedUrl?.[0]);
    }
    if (isSuccess && organizationData?.response?.[0]?.encodedUrl?.[0]) {
      setProfileUrl(organizationData?.response?.[0]?.encodedUrl?.[0]);
    }
  }, [isSuccess, organizationData, profileUrl]);

  useEffect(() => {
    if (userData?.status === 200) {
      let data = userData?.response;
      dispatch(setUserDetail(data));
    }
  }, [userData, dispatch]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleProfileClick = () => {
    setAnchorElUser(null);
    navigate(`/admin/profile/${userDetail?._id}`);
  };

  const handleResetPassword = () => {
    setShowChangePasswordModal(true);
    setAnchorElUser(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoutDialogOpen = () => {
    setAnchorElUser(null);
    setOpenLogoutDialog(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(setAuthLogout());
    localStorage.removeItem("accessToken");
    localStorage.clear();
    navigate("/login");
  };

  const handleCloseLogoutDialog = (status: boolean) => {
    if (status) {
      handleLogout();
      setOpenLogoutDialog(false);
    } else {
      setOpenLogoutDialog(false);
    }
  };

  const handleModel = useCallback((isOpen: boolean) => {
    setShowModal(isOpen);
    setEditId("");
  }, []);

  const handleOpenModel = useCallback(() => {
    if (userDetail !== null) {
      if (userDetail?.organization_id !== "") {
        setEditId(userDetail?.organization_id);
        setShowModal(true);
      }
    }
  }, [userDetail]);

  const menuItems: ProfileMenuItem[] = [
    {
      key: "Profile",
      eventHandler: handleProfileClick,
      href: `/admin/profile/${userDetail?._id}`,
    },
    {
      key: "Change Password",
      eventHandler: handleResetPassword,
      href: "",
    },
    {
      key: "Logout",
      eventHandler: handleLogoutDialogOpen,
      href: "",
    },
  ];

  return (
    <AppBar position="static" className="topbar-appbar">
      <Container maxWidth="xl" className="topbar-container">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={logoUrl ?? logoImage} alt="Logo" className="logo-img" />
          </Typography>

          <Box
            className="mobile-menu"
            sx={{ flexGrow: 1, display: { xs: "flex" } }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls={anchorElNav ? "menu" : undefined}
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              {props.openSidebarToggle ? (
                <MenuIcon
                  className="icon"
                  onClick={() => props.OpenSidebar(props.openSidebarToggle)}
                />
              ) : (
                <CloseIcon
                  className="icon"
                  onClick={() => props.OpenSidebar(props.openSidebarToggle)}
                />
              )}
            </IconButton>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            className="mobile-logo"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={profileUrl} alt="Logo" className="logo-img" />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box
            sx={{ flexGrow: 0 }}
            className="topbar-appbar-right"
            style={{ display: "flex" }}
          >
            {userDetail?.organization_name !== "" && (
              <button
                onClick={handleOpenModel}
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                  background: "none",
                  color: "white",
                  border: "none",
                  padding: 0,
                }}
                aria-label="Open model"
              >
                <h3>{userDetail?.organization_name}</h3>
              </button>
            )}

            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src={profileUrl} />
                <p
                  className={`profile-title ${
                    userDetail?.organization_name === ""
                      ? "super-admin-logout"
                      : ""
                  }`}
                >
                  {username}
                </p>
                <KeyboardArrowDown />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {menuItems.map((item: ProfileMenuItem) => (
                <MenuItem
                  key={item.key}
                  onClick={item.eventHandler}
                  href={item.href}
                >
                  <Typography textAlign="center">{item.key}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      {showModal && (
        <AddEditOrg
          open={showModal}
          handleModel={handleModel}
          editId={editId !== "" ? editId : null}
        />
      )}
      {showChangePasswordModal && (
        <ChangePassword
          open={showChangePasswordModal}
          setShowModal={setShowChangePasswordModal}
        />
      )}
      <ConfirmBox
        title="Logout Confirmation"
        message="Are you sure, you want to Logout?"
        open={openLogoutDialog}
        onClose={handleCloseLogoutDialog}
      />
    </AppBar>
  );
};

export default Header;
