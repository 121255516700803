import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response, TrackerInfo } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";


export const TrackerInfoApi = createApi({
    reducerPath: "TrackerInfoApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["trackerInfoTag"],
    endpoints: (builder) => ({
        trackerInfoList: builder.query<Response<PaginatedData<any>>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/trackerinfo/getlist`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                    id: pageData.id ?? "",
                    organization_id: pageData.organization_id ?? ""
                },
            }),
            providesTags: ["trackerInfoTag"],
        }),
        createTrackerInfo: builder.mutation<Response<any>, FormData>({
            query: (data: FormData) => ({
                url: `/trackerinfo/create`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["trackerInfoTag"],
        }),
        getTrackerInfoById: builder.query<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/trackerinfo/getTrackerInfo/${id}`,
                method: "GET",
            }),
            providesTags: ["trackerInfoTag"],
        }),
        deleteTrackerInfo: builder.mutation<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/trackerinfo/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["trackerInfoTag"],
        }),
        editTrackerInfo: builder.mutation<Response<any>, any>({
            query: (data: TrackerInfo) => ({
                url: `/trackerinfo/edit/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["trackerInfoTag"],
        }),
    })
    
})


export const {
    useTrackerInfoListQuery,
    useCreateTrackerInfoMutation,
    useGetTrackerInfoByIdQuery,
    useDeleteTrackerInfoMutation,
    useEditTrackerInfoMutation,
} = TrackerInfoApi;