import React, { useMemo, useState } from "react";
import {
  Button,
  Paper,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  debounce,
} from "@mui/material";
import { TableBodyElement } from "../../components/TableBody/TableBodyElement";
import { TableHeadElement } from "../../components/TableBody/TableHeadElement";
import {
  ColumnsType,
  Order,
  PageMeta,
  SortingData,
} from "../../types/CommonTypes";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import {
  useEmployeeRoleListQuery,
  useDeleteEmployeeRoleMutation,
} from "../../redux-services";
import { SearchElement } from "../../components/Search/Search";

const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "role_name",
    sort: true,
    label: "Employee Role Name",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
    style: { width: "15%" },
  },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const EmployeeRole = () => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER ?? "asce",
    order_by: process.env.REACT_APP_ORDER_BY ?? "role_name",
  };
  let navigate = useNavigate();
  let query = useLocation();
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [search, setSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [userList, setUserList] = useState<PageMeta>(initialState);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>("");

  const { data } = useEmployeeRoleListQuery({
    per_page: userList.per_page ?? rowsPerPage,
    page: query.state ?? userList.page,
    order: userList.order ?? order,
    order_by: userList.order_by ?? orderBy,
    searchText: userList.searchText ?? search,
  });

  const [deleteUserRole] = useDeleteEmployeeRoleMutation();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setUserList({
      ...userList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setUserList({ ...userList, page: newPage + 1 });
    if (query.state !== null) {
      navigate(query.pathname, {});
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserList({
      ...userList,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setUserList({
        page: 1,
        per_page: userList.per_page,
        order: initialOrder,
        searchText: event.target.value,
      });
    }, 800);
  }, []);

  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteUserRole(Id);
    } else {
      setIsClose(res);
    }
  };

  return (
    <div>
      <Paper className="table-outers">
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 40%" }}
            variant="h5"
            id="tableTitle"
            component="div"
            className="inner-headings"
          >
            Employee Roles List
          </Typography>
          <div className="flex-boxinner">
            <Typography sx={{ flex: "1 1 auto" }}>
              <SearchElement
                searchFn={searchHandler}
                searchTag={"Search Employee Role..."}
              />
            </Typography>
            <Tooltip title="Add Employee Role" arrow className="me-1 mt-1">
              <Button
                className="secondary-btn"
                onClick={() => navigate("/admin/employee/employee-role/add")}
                type="submit"
                variant="contained"
                color="primary"
              >
                Add Employee Role
              </Button>
            </Tooltip>
          </div>
        </Toolbar>
        <StyledEngineProvider>
          <TableContainer component={Paper} className="table-outers">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableHeadElement
                    setHead={tableColumns ?? []}
                    order={order}
                    orderBy={orderBy}
                    sortFn={(event, id: string) =>
                      createSortHandler(event, id as keyof SortingData)
                    }
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableBodyElement
                  selectedColumns={["role_name"]}
                  setData={data?.response.data ? data?.response.data : []}
                  editFn={(id) =>
                    navigate(`/admin/employee/employee-role/edit/${id}`, {
                      state: page,
                    })
                  }
                  deleteFn={(id) => {
                    setIsClose(true);
                    setId(id);
                  }}
                  pageData={{
                    limit:
                      rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: data?.response.page as number,
                  }}
                />
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={data?.response.total ?? 0}
              page={data?.response.page ?? 0}
              rowsPerPageOptions={[20, 50, 100]}
              onPageChange={handleChangePage}
              rowsPerPage={
                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </StyledEngineProvider>
      </Paper>
      <ConfirmBox
        title="Employee Role Delete Confirmation"
        message="Are you sure, you want to delete this Employee Role?"
        open={isClose}
        onClose={confirmBoxClose}
      />
    </div>
  );
};

export default EmployeeRole;
