import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const ShiftApi = createApi({
  reducerPath: "shfitApi",

  baseQuery: axiosBaseQuery(),

  tagTypes: ["shiftTag"],

  endpoints: (builder) => ({
    shiftList: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/shift/getlist`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
        },
      }),
      providesTags: ["shiftTag"],
    }),

    createShift: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/shift/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["shiftTag"],
    }),

    getShiftById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/shift/getShift/${id}`,
        method: "GET",
      }),
      providesTags: ["shiftTag"],
    }),

    editShift: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/shift/edit/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["shiftTag"],
    }),
    deleteShift: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/shift/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["shiftTag"],
    }),
    updateShiftStatus: builder.mutation<
      Response<any>,
      { id: string; status: boolean }
    >({
      query: ({ id, status }) => ({
        url: `/shift/updateStatus/${id}`,
        method: "POST",
        body: { status },
      }),
      invalidatesTags: ["shiftTag"],
    }),
  }),
});

export const {
  useCreateShiftMutation,
  useEditShiftMutation,
  useShiftListQuery,
  useGetShiftByIdQuery,
  useDeleteShiftMutation,
  useUpdateShiftStatusMutation,
} = ShiftApi;
