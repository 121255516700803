import React, { FC, memo, useEffect, useState } from 'react';
import { TextField, Button, Grid, Box, Divider, Typography, Fade, Backdrop, Modal, FormGroup, FormControlLabel, Checkbox, SelectChangeEvent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setError, useCreateBoreAreaMutation, useEditBoreAreaMutation, useGetBoreAreaByIdQuery } from '../../../../redux-services';
import Error from '../../../../components/ErrorBox/Error';
import Maps from '../../../../components/Models/Maps';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
export interface UserState {
    site_id: any;
    name: string;
    latitude: string;
    longitude: string;
    road_site: boolean;
    soil_assigned: number | any;
    chainage_point: number | any;
    distance: number | any;
    error: any;
}
interface FormErrors {
    name?: string;
    latitude?: string;
    longitude?: string;
    soil_assigned?: number;
    distance?: number;
}
const AddEditBoreArea: FC<{ open: boolean, handleModel: any, siteInfo: { id: string, name: string }, id: string }> = ({ open, handleModel, siteInfo, id }) => {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };
    const intialState = {
        site_id: siteInfo,
        name: "",
        latitude: "",
        longitude: "",
        road_site: false,
        chainage_point: 0,
        soil_assigned: 0,
        distance: 0,
        error: "",
    }
    let dispatch = useDispatch();
    const [formData, setFormData] = useState<UserState>(intialState);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [createBoreArea, result] = useCreateBoreAreaMutation();
    const [editBoreArea, editResult] = useEditBoreAreaMutation();

    const { data: BoreAreaData } = useGetBoreAreaByIdQuery(id, {
        skip: !id,
    });

    useEffect(() => {
        if (BoreAreaData?.status === 200) {
            let data = BoreAreaData?.response;

            setFormData({
                ...formData,
                name: data.name,
                latitude: data?.location?.coordinates ? String(data?.location?.coordinates[0]) : '',
                longitude: data?.location?.coordinates ? String(data?.location?.coordinates[1]) : '',
                soil_assigned: data?.soil_assigned ?? 0,
                distance: data?.distance ?? 0,
                chainage_point: data?.chainage_point ?? 0,
                road_site: data?.road_site ?? false,
            })
        }
    }, [BoreAreaData])


    if (result.isSuccess && result.data.status === 200) {
        handleModel(false);
    }

    if (editResult.isSuccess && editResult.data.status === 200) {
        handleModel(false);
    }

    const validate = (formData: UserState) => {
        const errors: Partial<UserState> = {};
        if (!formData.name) {
            errors.name = "Name is required!";
        }
        if (!formData.latitude) {
            errors.latitude = "Latitude is required!";
        }
        if (!formData.longitude) {
            errors.longitude = "Longitude is required!";
        }
        return errors;
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        dispatch(setError([]));
        let formatted = {
            ...formData,
            site_id: siteInfo?.id,
            id: id,
            soil_assigned: Number(formData.soil_assigned),
            chainage_point: Number(formData.chainage_point),
            distance: Number(formData.distance),
            road_site: Boolean(formData?.road_site),
        };
        id ? editBoreArea(formatted) : createBoreArea(formatted);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;
        const fieldNames = ["soil_assigned", 'chainage_point', "distance"]
        if (fieldNames?.includes(name)) {
            const positiveNumberRegex = /^[0-9]\d*(\.\d+)?$/;
            if (positiveNumberRegex.test(value)) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value
                }));
            }
            if (value === '') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value
                }));
            }
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.trimStart()
            }));
        }

        const errors = validate({ ...formData, [name]: value });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errors[name as keyof UserState]
        }));
    };
    const handleCheckboxChange = (event: (SelectChangeEvent<HTMLInputElement> | any)) => {
        const isChecked = event.target.checked;
        setFormData({ ...formData, road_site: isChecked });
    };

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className='form-outers karuModal'>
                        <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                            {(id ? 'Edit' : 'Add') + ` Bore Area`}
                        </Typography>
                        <HighlightOffIcon className="closeicon" onClick={() => handleModel(false)} />

                        <Box className="modalBody">
                            <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="name"
                                            label="Bore Area Name"
                                            name="name"
                                            type="text"
                                            size='small'
                                            autoComplete="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            error={!!formErrors.name}
                                            helperText={formErrors.name}
                                        />
                                        <Error current_key={formData.name} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="latitude"
                                            label="Latitude"
                                            name="latitude"
                                            type="number"
                                            size='small'
                                            autoComplete="latitude"
                                            value={formData.latitude}
                                            onChange={handleChange}
                                        />
                                        <Error current_key="latitude" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="longitude"
                                            label="Longitude"
                                            name="longitude"
                                            type="number"
                                            size='small'
                                            autoComplete="longitude"
                                            value={formData.longitude}
                                            onChange={handleChange}
                                        />
                                        <Error current_key="longitude" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                value="start"
                                                style={{ color: "#fff" }}
                                                control={
                                                    <Checkbox
                                                        id="checked"
                                                        name="checked"
                                                        value={formData.road_site}
                                                        checked={formData.road_site ? true : false}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                }
                                                label="Road Site"
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            id="soil_assigned"
                                            label={"Soil Assigned" + ' (' + "\u33A5" + ')'}
                                            name="soil_assigned"
                                            type="number"
                                            size='small'
                                            autoComplete="soil_assigned"
                                            value={formData.soil_assigned}
                                            onChange={handleChange}
                                        />
                                        <Error current_key="soil_assigned" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="chainage_point"
                                            label="Chainage Point"
                                            name="chainage_point"
                                            type="number"
                                            size='small'
                                            autoComplete="chainage_point"
                                            value={formData.chainage_point}
                                            onChange={handleChange}
                                        />
                                        <Error current_key={formData.chainage_point as any} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="distance"
                                            label="Lead Distance (Km)"
                                            name="distance"
                                            type="number"
                                            size='small'
                                            autoComplete="distance"
                                            value={formData.distance}
                                            onChange={handleChange}
                                            error={!!formErrors.distance}
                                            helperText={formErrors.distance}
                                        />
                                        <Error current_key={formData.distance} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Maps setFormData={setFormData} formData={formData} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className="save-btn"
                                            onSubmit={handleSubmit}
                                            disabled={Object.values(formErrors).some(error => !!error)}
                                            style={{ marginRight: '10px' }}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className="close-btn"
                                            onClick={() => handleModel(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </React.Fragment>
    );
};

export default memo(AddEditBoreArea);
