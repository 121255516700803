import { Alert, Backdrop, Box, Button, Divider, Fade, FormControl, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useFindPreviousWorkItemMutation, useGetShiftDetailQuery } from '../../../../redux-services';
import { WorkItems } from '../../../../ constants';
import dayjs from 'dayjs';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
export interface UserState {
    entry_date: string;
    shift_id: any;
    work_type: any;
    site_id: string;
}

const PreviousRecordWorkItem: FC<{ open: boolean, handleModel: any, siteId: string, setState: any }> = ({ open, handleModel, siteId, setState }) => {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };

    const intialState = {
        entry_date: "",
        shift_id: "",
        work_type: 0,
        site_id: siteId
    }
    const [formData, setFormData] = useState<UserState>(intialState);

    const [showError, setShowError] = useState(false);

    const [findPreviousWorkItem, result] = useFindPreviousWorkItemMutation();
    const ShiftDetailData = useGetShiftDetailQuery(siteId);

    useEffect(() => {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        setFormData({ ...formData, entry_date: formattedDate });
    }, []);

    useEffect(() => {
        if (result?.isSuccess && result?.data?.status === 200) {
            const data = result?.data?.response;
            let date = new Date();
            let unixTimestamp1 = date.getTime();
            const dateTime = dayjs(new Date(unixTimestamp1));

            let workType = WorkItems.find((item: any) => item.id === data?.work_type);

            setState({
                site_id: data.site_id,
                shift_id: data.shiftData[0],
                bore_area_id: data.boreAreaData[0],
                work_type: workType ?? {},
                chainage_start_point: data.chainage_start_point,
                chainage_end_point: data.chainage_end_point,
                entry_date: dateTime,
                comment: data.comment,
                total_volume_dump: 0,
                lead_chainage_distance: data?.lead_chainage_distance ?? 0,
            });
            handleModel(false);
        }

        if (result?.isSuccess && !result?.data?.response) {
            setShowError(true);
        }
    }, [result]);

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
    }, [showError]);

    const selectChange = (event: (SelectChangeEvent | any)) => {
        setFormData({
            ...formData,
            shift_id: event.target.value as string
        });
    };

    const getTodayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); 
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

  

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const formattedData = { ...formData, shift_id: formData?.shift_id?._id };
        findPreviousWorkItem(formattedData);
    };

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className='form-outers karuModal'>
                        <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                            Find Previous Record of Work Item
                        </Typography>
                        <HighlightOffIcon className="closeicon" onClick={() => handleModel(false)} />
                        <Box className="modalBody">
                            {showError && result?.isSuccess && !result?.data?.response &&
                                <Alert severity="error" style={{ marginTop: "5px" }}>{result?.data?.message}</Alert>
                            }
                            <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            id="entry_date"
                                            name="entry_date"
                                            label="Select Date of Replicate Date"
                                            type="date"
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                max: getTodayDate(),
                                            }}
                                            size='small'
                                            value={formData?.entry_date}
                                            onChange={handleDateChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth size='small'>
                                                <InputLabel id="demo-simple-select-label">Select Shifts *</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={formData.shift_id}
                                                    label="Select Shifts"
                                                    required
                                                    onChange={selectChange}
                                                    renderValue={() => formData.shift_id?.shift_name}
                                                >
                                                    {ShiftDetailData && ShiftDetailData?.data?.response.map(
                                                        (item: any, index: number) => (
                                                            <MenuItem value={item} key={index}>{item?.shift_name}</MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className="save-btn"
                                            style={{ marginRight: '10px' }}
                                        >
                                            Find Record
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className="close-btn"
                                            onClick={() => handleModel(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </React.Fragment >
    )
}

export default PreviousRecordWorkItem