import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { LoginApi } from "../redux-services/api/LoginApi";
import AlertBoxReducer from "../redux-services/slices/AlertBoxSlice";
import AuthReducer from "../redux-services/slices/AuthSlice";
import ErrorReducer from "../redux-services/slices/ErrorSlice";
import { UserApi } from "../redux-services/api/UserApi";
import { TollExpensesApi } from "../redux-services/api/TollExpensesApi";
import { AccessoriesApi, AirPressureCheckApi, EmployeeApi, EmployeeRoleApi, FrequentCallApi, FuelEntriesApi, MachineInfoApi, OrganizationApi, ShiftApi, ShiftLogsApi, SitesApi, TrackerInfoApi, UserRoleApi, VehicleInfoApi, FuelTypeApi, SiteWorkLogsApi, BoreAreaApi, WorkItemApi, DailyLogApi, MachineWorkLogApi, MachineTypeApi, MonthlyReportApi } from "../redux-services";
import { BreakDownApi } from "../redux-services/api/BreakDownApi";
import { MachineServiceApi } from "../redux-services/api/MachineServiceApi";
import { MachineDocApi } from "../redux-services/api/MachineDocApi";
import { MachineDocumentTypeApi } from "../redux-services/api/MachineDocumentTypeApi";
import { VehicleMovementApi } from "../redux-services/api/VehicleMovementApi";
import { RateChartApi } from "../redux-services/api/RateChartApi";

export const store = configureStore({
  reducer: {
    alertbox: AlertBoxReducer,
    auth: AuthReducer,
    error: ErrorReducer,
    [LoginApi.reducerPath]: LoginApi.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [UserRoleApi.reducerPath]: UserRoleApi.reducer,
    [MachineInfoApi.reducerPath]: MachineInfoApi.reducer,
    [ShiftApi.reducerPath]: ShiftApi.reducer,
    [ShiftLogsApi.reducerPath]: ShiftLogsApi.reducer,
    [FuelEntriesApi.reducerPath]: FuelEntriesApi.reducer,
    [TollExpensesApi.reducerPath]: TollExpensesApi.reducer,
    [AccessoriesApi.reducerPath]: AccessoriesApi.reducer,
    [MachineDocApi.reducerPath]:MachineDocApi.reducer,
    [BreakDownApi.reducerPath]: BreakDownApi.reducer,
    [SitesApi.reducerPath]: SitesApi.reducer,
    [EmployeeApi.reducerPath]: EmployeeApi.reducer,
    [EmployeeRoleApi.reducerPath]: EmployeeRoleApi.reducer,
    [OrganizationApi.reducerPath]: OrganizationApi.reducer,
    [FrequentCallApi.reducerPath]: FrequentCallApi.reducer,
    [AirPressureCheckApi.reducerPath]: AirPressureCheckApi.reducer,
    [FuelTypeApi.reducerPath]: FuelTypeApi.reducer,
    [TrackerInfoApi.reducerPath]:TrackerInfoApi.reducer,
    [VehicleInfoApi.reducerPath]: VehicleInfoApi.reducer,
    [MachineServiceApi.reducerPath]: MachineServiceApi.reducer,
    [SiteWorkLogsApi.reducerPath]: SiteWorkLogsApi.reducer,
    [BoreAreaApi.reducerPath]: BoreAreaApi.reducer,
    [WorkItemApi.reducerPath]: WorkItemApi.reducer,
    [DailyLogApi.reducerPath]: DailyLogApi.reducer,
    [MachineWorkLogApi.reducerPath]: MachineWorkLogApi.reducer,
    [MachineTypeApi.reducerPath]: MachineTypeApi.reducer,
    [MachineDocumentTypeApi.reducerPath]:MachineDocumentTypeApi.reducer,
    [VehicleMovementApi.reducerPath]:VehicleMovementApi.reducer,
    [RateChartApi.reducerPath]:RateChartApi.reducer,
    [MonthlyReportApi.reducerPath]: MonthlyReportApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(LoginApi.middleware)
      .concat(UserApi.middleware)
      .concat(UserRoleApi.middleware)
      .concat(MachineInfoApi.middleware)
      .concat(MachineDocApi.middleware)
      .concat(BreakDownApi.middleware)
      .concat(ShiftApi.middleware)
      .concat(ShiftLogsApi.middleware)
      .concat(FuelEntriesApi.middleware)
      .concat(TollExpensesApi.middleware)
      .concat(AccessoriesApi.middleware)
      .concat(SitesApi.middleware)
      .concat(EmployeeApi.middleware)
      .concat(EmployeeRoleApi.middleware)
      .concat(OrganizationApi.middleware)
      .concat(FrequentCallApi.middleware)
      .concat(AirPressureCheckApi.middleware)
      .concat(FuelTypeApi.middleware)
      .concat(TrackerInfoApi.middleware)
      .concat(VehicleInfoApi.middleware)
      .concat(MachineServiceApi.middleware)
      .concat(SiteWorkLogsApi.middleware)
      .concat(BoreAreaApi.middleware)
      .concat(WorkItemApi.middleware)
      .concat(DailyLogApi.middleware)
      .concat(MachineWorkLogApi.middleware)
      .concat(MachineTypeApi.middleware)
      .concat(MachineDocumentTypeApi.middleware)
      .concat(VehicleMovementApi.middleware)
      .concat(RateChartApi.middleware)
      .concat(MonthlyReportApi.middleware),
});

export const clearApiCache = () => {
  Object.values(LoginApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(LoginApi.util.resetApiState(endpoint));
  });
  Object.values(UserApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(UserApi.util.resetApiState(endpoint));
  });
  Object.values(UserRoleApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(UserRoleApi.util.resetApiState(endpoint));
  });
  Object.values(MachineInfoApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(MachineInfoApi.util.resetApiState(endpoint));
  });
  Object.values(ShiftApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(ShiftApi.util.resetApiState(endpoint));
  });
  Object.values(ShiftLogsApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(ShiftLogsApi.util.resetApiState(endpoint));
  });
  Object.values(MachineDocApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(MachineDocApi.util.resetApiState(endpoint));
  });
  Object.values(FuelEntriesApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(FuelEntriesApi.util.resetApiState(endpoint));
  });
  Object.values(TollExpensesApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(TollExpensesApi.util.resetApiState(endpoint));
  });
  Object.values(AccessoriesApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(AccessoriesApi.util.resetApiState(endpoint));
  });
  Object.values(SitesApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(SitesApi.util.resetApiState(endpoint));
  });
  Object.values(EmployeeApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(EmployeeApi.util.resetApiState(endpoint));
  });
  Object.values(EmployeeRoleApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(EmployeeRoleApi.util.resetApiState(endpoint));
  });
  Object.values(OrganizationApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(OrganizationApi.util.resetApiState(endpoint));
  });
  Object.values(FrequentCallApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(FrequentCallApi.util.resetApiState(endpoint));
  });
  Object.values(AirPressureCheckApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(AirPressureCheckApi.util.resetApiState(endpoint));
  });
  Object.values(TrackerInfoApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(TrackerInfoApi.util.resetApiState(endpoint));
  });
  Object.values(VehicleInfoApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(VehicleInfoApi.util.resetApiState(endpoint));
  });
  Object.values(MachineServiceApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(MachineServiceApi.util.resetApiState(endpoint));
  });
  Object.values(WorkItemApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(WorkItemApi.util.resetApiState(endpoint));
  });
  Object.values(DailyLogApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(DailyLogApi.util.resetApiState(endpoint));
  });
  Object.values(MachineWorkLogApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(MachineWorkLogApi.util.resetApiState(endpoint));
  });
  Object.values(MachineTypeApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(MachineTypeApi.util.resetApiState(endpoint));
  });
  Object.values(MachineDocumentTypeApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(MachineDocumentTypeApi.util.resetApiState(endpoint));
  });
  Object.values(VehicleMovementApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(VehicleMovementApi.util.resetApiState(endpoint));
  });
  Object.values(RateChartApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(RateChartApi.util.resetApiState(endpoint));
  });
  Object.values(MonthlyReportApi.endpoints).forEach((endpoint: any) => {
    store.dispatch(MonthlyReportApi.util.resetApiState(endpoint));
  });

};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
