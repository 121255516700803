import React, { useCallback, useEffect,  useState } from 'react';
import { useGetUserDetailQuery, useGetVehicleCurrStatusQuery, useGetVehicleDueServiceQuery, useGetVehicleUsageQuery } from '../../../redux-services';
import { Button } from '@mui/material';
import { Paper, StyledEngineProvider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { TableHeadElement } from '../../../components/TableBody/TableHeadElement';
import { ColumnsType, Order, SortingData } from '../../../types/CommonTypes';
import VehicleInfoModal from '../../Sites/ViewSites/TrackerDetails/ViewTracker/VehicleDetails/VehicleInfoModal';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';

interface Location {
    latitude: number;
    longitude: number;
}

interface TtsDtsData {
    dts: string;
    engineHrsToService: string;
    tts: string;
    serviceStatus: string;
    serviceType: string;
}

interface ServiceData {
    vin: string;
    odometer: number;
    fuel_level: string;
    def_level: string;
    tts_dts_data: TtsDtsData;
    engine_run_hour: string;
    battery_soc: string;
}

interface ResultUsageItem {
    vehicle_id: string;
    registration_number: string;
    vin: string;
    fuel_type: string;
    lob: string;
    account_name: string | null;
    emition_nom: string;
    vehicle_type: string;
    vehicle_model: string;
    is_scv_ev_vehicle: boolean;
    lob_name: string;
    odometer: number;
    event_date_time: string;
}

interface CombinedData extends ServiceData, Location {
    id: string;
    status: string;
    registration_number?: string;
    fuel_type?: string;
    lob?: string;
    account_name?: string | null;
    emition_nom?: string;
    vehicle_type?: string;
    vehicle_model?: string;
    is_scv_ev_vehicle?: boolean;
    lob_name?: string;
    event_date_time?: string;
    service_due_status?: string;
}

const tableColumns: ColumnsType[] = [
    { id: "id", sort: false, label: "S.No." },
    { id: "reg_no", sort: true, label: "Reg. No." },
    { id: "vin_no", sort: true, label: "Vin. No." },
    { id: "model_name", sort: false, label: "Model Name" },
    { id: "vehicle_status", sort: true, label: "Vehicle Status" },
    { id: "odometer", sort: true, label: "Odometer" },
    { id: "fuel_level", sort: true, label: "Fuel Level" },
    { id: "def_level", sort: true, label: "Def Level(%)" },
    { id: "state_of_charge", sort: true, label: "State of Charge(%)" },
    { id: "hrs_to_service", sort: true, label: "Distance/Engine Hours to Service" },
    { id: "tts", sort: true, label: "Time to service(Days)" },
    { id: "engine_run_hour", sort: true, label: "Engine Run Hour" },
    { id: "service_due_status", sort: true, label: "Service Due status" },
    { id: "last_updated", sort: true, label: "Last Updated" },
    { id: "location", sort: true, label: "Location" }
];

const initialOrder: Order = 'asc';

const MachineReport: React.FC = () => {
    let vins: any;
    const { data: userData } = useGetUserDetailQuery();
    const org_id = userData?.response?.organization_id;
    const { data: getMachineCurrStatus } = useGetVehicleCurrStatusQuery(org_id, {
        skip: !org_id,
    });
    const { data: getMachineDueService } = useGetVehicleDueServiceQuery(org_id, {
        skip: !org_id,
    });
    const { data: getMachineUsage } = useGetVehicleUsageQuery(org_id, {
        skip: !org_id,
    });

    const username = userData?.response?.username;

    const navigate = useNavigate();
    const [reqData, setReqData] = useState<CombinedData[]>([]);
    const [orderBy, setOrderBy] = useState<keyof SortingData>('reg_no');
    const [order, setOrder] = useState<Order>(initialOrder);

    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [address, setAddress] = useState<string>('');
    const location = useLocation();
    const fromUrl = location.state?.from;

    const getAddressFromLatLon = useCallback(async (latitude: number, longitude: number) => {
        try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
            const data = response.data;
            const locAddr = data?.address;

            if (locAddr) {
                const address = `${locAddr.road ?? ''} ${locAddr.village ?? ''} ${locAddr.county ?? ''} ${locAddr.state_district ?? ''} ${locAddr.state ?? ''} ${locAddr.postcode ?? ''}`;
                setAddress(address);
            } else {
                setAddress('Unknown location');
            }
        } catch (error) {
            console.error('Error fetching address:', error);
            setAddress('Error fetching address');
        }
    }, []);

    const handleLocationClick = useCallback(async (latitude: number, longitude: number, vin: string) => {
        try {
            await getAddressFromLatLon(latitude, longitude);
            setSelectedLocation({ latitude, longitude });
            setOpen(true);
            vins = vin;
        } catch (error) {
            console.error('Error fetching address:', error);
            setAddress('Error fetching address');
        }
    }, [getAddressFromLatLon]);

    const handleModel = useCallback((isOpen: boolean) => {
        setOpen(isOpen);
    }, []);


    const handleBackButton = () => {
        if (username !== "Super Admin" && fromUrl==="/admin/machine") {
            navigate(`/admin/machine`)
        } else {
            const { siteId } = location.state || {};
            navigate(`/admin/organization/sites/${siteId}`)
        }
    }

    const getStatusClassName = (status: string) => {
        switch (status) {
            case 'Idling': return 'idlingg';
            case 'Stopped': return 'stoppedd';
            case 'Moving': return 'movingg';
            case 'Offline': return 'offlinee';
            default: return 'nan';
        }
    };

    useEffect(() => {
        const fetchData = async () => {

            if (getMachineCurrStatus && getMachineDueService && getMachineUsage) {
                const statusResult = getMachineCurrStatus?.response?.result;
                const location_dict = getMachineCurrStatus?.response?.location_dict;
                const serviceResult = getMachineDueService?.response?.result;
                const usageResults = getMachineUsage?.response?.results;

                const usageMap = new Map<string, ResultUsageItem>(usageResults?.map((item: ResultUsageItem) => [item?.vin, item]));
                if (statusResult && location_dict && serviceResult && usageResults) {
                    
                    const combinedArray: CombinedData[] = Object.keys(statusResult).map(id => {
                        const originalDateTime = usageMap.get(id)?.event_date_time ?? '';
                        const date = new Date(originalDateTime);
                        
                        if (isNaN(date.getTime())) {
                            throw new Error('Invalid date format');
                        }
                    
                        date.setHours(date.getHours() + 5);
                        date.setMinutes(date.getMinutes() + 30);
                        
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');
                        
                        const updatedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
                        
                    
                        const baseData: CombinedData = {
                            id,
                            status: statusResult[id],
                            vin: id,
                            latitude: location_dict[id]?.latitude ?? null,
                            longitude: location_dict[id]?.longitude ?? null,
                            odometer: serviceResult[id]?.odometer ?? 0,
                            fuel_level: serviceResult[id]?.fuel_level ?? '',
                            def_level: serviceResult[id]?.def_level ?? '',
                            tts_dts_data: serviceResult[id]?.tts_dts_data ?? { dts: '', engineHrsToService: '', tts: '', serviceStatus: '', serviceType: '' },
                            engine_run_hour: serviceResult[id]?.engine_run_hour ?? '',
                            battery_soc: serviceResult[id]?.battery_soc ?? '',
                            registration_number: usageMap.get(id)?.registration_number =="AF" && id==="MAT567005RAA01689" ?"AG" : usageMap.get(id)?.registration_number,
                            fuel_type: usageMap.get(id)?.fuel_type ?? '',
                            lob: usageMap.get(id)?.lob ?? '',
                            account_name: usageMap.get(id)?.account_name ?? null,
                            emition_nom: usageMap.get(id)?.emition_nom ?? '',
                            vehicle_type: usageMap.get(id)?.vehicle_type ?? '',
                            vehicle_model: usageMap.get(id)?.vehicle_model ?? '',
                            is_scv_ev_vehicle: usageMap.get(id)?.is_scv_ev_vehicle ?? false,
                            lob_name: usageMap.get(id)?.lob_name ?? '',
                            event_date_time: updatedDateTime ?? '',
                            service_due_status: serviceResult[id]?.tts_dts_data?.serviceStatus ?? ''
                        };
                        return baseData;
                    });
                    setReqData(combinedArray);
                }
            } else {
                console.log("One or more API responses are unavailable");
            }
        };

        fetchData();

        const interval = setInterval(fetchData, 20000);
        return () => clearInterval(interval);
    }, [getMachineCurrStatus, getMachineDueService, getMachineUsage, org_id, username]);




    return (
        <>
            <div className="form-main">
                <Button
                    variant="contained"
                    color="primary"
                    className="secondary-btn"
                    style={{ maxWidth: '60px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                    onClick={handleBackButton}
                >
                    <ArrowBackIcon />
                </Button>
                <Paper>
                    <Toolbar className="removebg mt-2">
                        <Typography sx={{ flex: '1 1 40%' }} variant="h5" id="tableTitle" className="inner-headings" component="div">
                            Vehicle Live Report
                        </Typography>
                        <div className="flex-boxinner">
                            <Typography sx={{ flex: '1 1 auto' }}></Typography>
                        </div>
                    </Toolbar>
                    <StyledEngineProvider>
                        <TableContainer component={Paper} className="form-outers">
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" className="table-outers">
                                <TableHead>
                                    <TableRow>
                                        <TableHeadElement
                                            setHead={tableColumns}
                                            order={order}
                                            orderBy={orderBy}
                                            sortFn={(event, id: string) => {
                                            }}
                                        />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reqData.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={tableColumns.length} align="center">
                                                No record found
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        reqData?.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.registration_number}</TableCell>
                                                <TableCell>{row.vin}</TableCell>
                                                <TableCell>{row.vehicle_model}</TableCell>
                                                <TableCell className={getStatusClassName(row.status)}>
                                                    {row.status}
                                                </TableCell>
                                                <TableCell>{row.odometer}</TableCell>
                                                <TableCell>{row.fuel_level}</TableCell>
                                                <TableCell>{row.def_level}</TableCell>
                                                <TableCell>{row.battery_soc}</TableCell>
                                                <TableCell>{row.tts_dts_data.engineHrsToService}</TableCell>
                                                <TableCell>{row.tts_dts_data.tts}</TableCell>
                                                <TableCell>{row.engine_run_hour}</TableCell>
                                                <TableCell>{row.tts_dts_data.serviceStatus}</TableCell>
                                                <TableCell>{row.event_date_time}</TableCell>
                                                <TableCell>
                                                    <Button onClick={() => handleLocationClick(row.latitude, row.longitude, row.vin)}>
                                                        View Location
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </StyledEngineProvider>
                </Paper>
            </div>
            {open && (
                <VehicleInfoModal
                    open={open}
                    handleModel={handleModel}
                    lat={selectedLocation?.latitude}
                    lon={selectedLocation?.longitude}
                    veh_address={address}
                    vins_id={vins}
                />
            )}
        </>
    );
};

export default MachineReport;
