import { createSlice } from "@reduxjs/toolkit";
import { MtUser } from "../../types/CommonTypes";

export interface AuthState {
  user: MtUser | null;
  isAuthenticated: boolean;
  userAccessToken: string;
  siteOrgId: string;
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  userAccessToken: "",
  siteOrgId: '',
};

const AuthSlices = createSlice({
  name: "authSlices",
  initialState,
  reducers: {
    setAuth: (state) => {
      state.isAuthenticated = true;
      state.userAccessToken = localStorage.accessToken;
    },
    setUserDetail: (state, { payload }) => {
      state.user = payload;
      localStorage.setItem('username', payload.username);
    },
    setAuthLogout: (state) => {
      state.isAuthenticated = false;
      state.userAccessToken = "";
      state.user = null;
      localStorage.clear();
    },
    setSiteOrganizationId: (state, { payload }) => {
      state.siteOrgId = payload;
      localStorage.setItem("orgId", payload);
    },
  },
});

const { reducer, actions } = AuthSlices;

export const { setAuth, setAuthLogout, setUserDetail, setSiteOrganizationId } = actions;

export default reducer;
