import { createApi } from "@reduxjs/toolkit/query/react";

import { Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const VehicleMovementApi = createApi({
    reducerPath: "VehicleMovementApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["vehicleMovementTag"],
    endpoints: (builder) => ({
        getVehiclesRegNo: builder.mutation<Response<any>, any>({
            query: (data: any) => ({
                url: `/vehicle_movement/getvehiclereg`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["vehicleMovementTag"],
        }),
        getVehiclesLatLon: builder.mutation<Response<any>, any>({
            query: (data: any) => ({
                url: `/vehicle_movement/getvehiclehistory`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["vehicleMovementTag"],
        })
    })
})

export const {
    useGetVehiclesRegNoMutation,
    useGetVehiclesLatLonMutation
} = VehicleMovementApi;