import { createSlice } from "@reduxjs/toolkit";

export interface ErrorState {
    errorList: [];
}

const initialState: ErrorState = {
    errorList: []
};

const ErrorSlices = createSlice({
    name: "errorSlices",
    initialState,
    reducers: {
        setError: (state, { payload }) => {
            state.errorList = payload;
        },
    },
});

const { reducer, actions } = ErrorSlices;

export const { setError } = actions;

export default reducer;
