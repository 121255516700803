import {
    Alert,
    Backdrop,
    Box,
    Button,
    Divider,
    Fade,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    SelectChangeEvent,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from 'react'
import { useFindPreviousMachineLogMutation, useGetShiftDetailQuery, usePreviousMachineLogMutation } from '../../../redux-services';
import { TableHeadElement } from "../../../components/TableBody/TableHeadElement";
import { TableBodyElement } from "../../../components/TableBody/TableBodyElement";
import { ColumnsType, Order, PageMeta, SortingData } from "../../../types/CommonTypes";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export interface UserState {
    entry_date: string;
    shift_id: any;
    site_id: string;
}

const tableColumns: ColumnsType[] = [
    {
        id: "check",
        sort: false,
        label: "#",
    },
    {
        id: "machine_name",
        sort: false,
        label: "Machinery Name",
    },
    {
        id: "user_name",
        sort: false,
        label: "Employee Name",
    },
    {
        id: 'entry_date',
        sort: false,
        label: "Entry Date",
    },
    {
        id: 'measurement',
        sort: false,
        style: { width: "20%" },
        label: "Measurement",
    },

];
let initialOrder: string = process.env.REACT_APP_ORDER as string;

const PreviousMachineLogs: FC<{ open: boolean, handleModel: any, siteId: string, workItemId: string }> = ({ open, handleModel, siteId, workItemId }) => {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };

    const intialState = {
        entry_date: "",
        shift_id: "",
        site_id: siteId
    };
    const initial: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };
    const [formData, setFormData] = useState<UserState>(intialState);
    const [tableData, setTableData] = useState<any[]>([]);
    const [showError, setShowError] = useState(false);

    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [userList, setUserList] = useState<PageMeta>(initial);
    const [order, setOrder] = useState<Order>(initialOrder as Order);

    const [showTable, setShowTable] = useState(false);
    const [checkIds, setCheckIds] = useState<any[]>([]);

    const [findPreviousMachineLog, result] = useFindPreviousMachineLogMutation();
    const [previousMachineLog, resultPreviousLog] = usePreviousMachineLogMutation();
    const ShiftDetailData = useGetShiftDetailQuery(siteId);

    useEffect(() => {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); 
        const day = String(today.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        setFormData({ ...formData, entry_date: formattedDate });
    }, []);

    useEffect(() => {
        if (result?.isSuccess && result?.data?.status === 200) {
            let data = result?.data?.response?.data;
            setShowTable(true);
            setTableData(data ?? []);
        }

        if (result?.isSuccess && !result?.data?.response) {
            setShowError(true);
        }
        return () => {
            setShowTable(false);
            setTableData([]);
        };
    }, [result]);

    useEffect(() => {
        if (resultPreviousLog?.isSuccess && resultPreviousLog?.data?.status === 200) {
            let data = resultPreviousLog?.data?.response;
            if (data) {
                handleModel(false);
            }
        }

        if (result?.isSuccess && !result?.data?.response) {
            handleModel(false);
        }
    }, [resultPreviousLog])

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
    }, [showError]);

    const selectChange = (event: (SelectChangeEvent | any)) => {
        setFormData({
            ...formData,
            shift_id: event.target.value as string
        });
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setUserList({
            ...userList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const getTodayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleCheckBox = (id: string) => {
        let findData = checkIds?.length > 0 && checkIds?.find(item => item?.id === id);
        if (findData) {
            let filterData = checkIds.filter(item => item?.id !== id);
            setCheckIds(filterData);
        } else {
            let findTableData = tableData?.length > 0 && tableData.find(item => item?._id === id);
            let mergeData = [...checkIds, { id: id, measurement: findTableData.measurement_value }];
            setCheckIds(mergeData);
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const formattedData = { ...formData, shift_id: formData?.shift_id?._id, work_item_id: workItemId };
        findPreviousMachineLog(formattedData);
    };

    const handleWorkLogCheck = (e: any) => {
        e.preventDefault();
        if (checkIds?.length > 0) {
            previousMachineLog({ check: JSON.stringify(checkIds), work_item_id: workItemId });
        }
    }

    const handleEditMachineLog = (id: string, measurement: string) => {
        let updateData = tableData.map((item: any) => item?._id === id ? { ...item, measurement: `${measurement} Hours`, measurement_value: Number(measurement) } : { ...item });
        setTableData(updateData);
    }

    return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className='form-outers karuModal'>
                        <div>
                            <Paper className="form-outers">
                                <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography
                                        sx={{ flex: '1 1 40%' }}
                                        variant="h5"
                                        id="tableTitle"
                                        component="div"
                                        className="inner-headings"
                                    >
                                        Previous Machine Work Log
                                    </Typography>
                                    <HighlightOffIcon className="closeicon" onClick={() => handleModel(false)} />

                                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                        <form onSubmit={handleSubmit}>
                                            <Grid container spacing={3} item xs={12}>
                                                <Grid item xs={12} md={5}>
                                                    <Tooltip title="Select the Date For Which You want to Log the Machine Log." placement="top">
                                                        <TextField
                                                            fullWidth
                                                            id="entry_date"
                                                            name="entry_date"
                                                            label="Select Date"
                                                            type="date"
                                                            required
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                max: getTodayDate(),
                                                            }}
                                                            size='small'
                                                            value={formData?.entry_date}
                                                            onChange={handleDateChange}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <Box sx={{ minWidth: 120 }}>
                                                        <FormControl fullWidth size='small'>
                                                            <InputLabel id="demo-simple-select-label">Select Shifts *</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={formData.shift_id}
                                                                label="Select Shifts"
                                                                required
                                                                onChange={selectChange}
                                                                renderValue={() => formData.shift_id?.shift_name}
                                                            >
                                                                {ShiftDetailData && ShiftDetailData?.data?.response.map(
                                                                    (item: any, index: number) => (
                                                                        <MenuItem value={item} key={index}>{item?.shift_name}</MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        className="save-btn"
                                                        style={{ marginRight: '10px' }}
                                                    >
                                                        Find
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Box>
                                </Toolbar>
                                {showError && result?.isSuccess && !result?.data?.response &&
                                    <Alert severity="error" style={{ marginTop: "5px" }}>{result?.data?.message}</Alert>
                                }
                                <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                                {showTable &&
                                    <StyledEngineProvider>
                                        <TableContainer component={Paper} className="form-outers">
                                            <Table
                                                sx={{ minWidth: 650 }}
                                                size="small" aria-label="a dense table"
                                                className="table-outers"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableHeadElement
                                                            setHead={tableColumns ? tableColumns : []}
                                                            order={order}
                                                            orderBy={orderBy}
                                                            sortFn={(event, id: string) =>
                                                                createSortHandler(event, id as keyof SortingData)
                                                            }
                                                        />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableBodyElement
                                                        selectedColumns={[
                                                            "machine_name",
                                                            "user_name",
                                                            "entry_date"
                                                        ]}
                                                        checkboxFn={(id: string) => handleCheckBox(id)}
                                                        editMeasurementFn={(id: string, measurement: string) => handleEditMachineLog(id, measurement)}
                                                        setData={tableData}
                                                        showAction={true}
                                                        showSerialNumber={false}
                                                        pageData={{
                                                            limit: tableData?.length ?? initial?.per_page,
                                                            page: 0,
                                                        }}
                                                    />
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </StyledEngineProvider>
                                }
                            </Paper>
                        </div>
                        <Box style={{ display: "flex", gap: 10 }}>
                            {
                                tableData?.length > 0 &&
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="save-btn"
                                        onClick={handleWorkLogCheck}
                                        style={{ marginTop: "20px" }}
                                    >
                                        Check
                                    </Button>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className="close-btn"
                                    onClick={() => handleModel(false)}
                                    style={{ marginTop: "20px" }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
    )
}

export default PreviousMachineLogs