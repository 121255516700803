import React, { FC, useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  Modal,
  Backdrop,
  Fade,
  Typography,
  Divider,
  Container,
  TableContainer,
  Table,
  TableRow,
  Paper,
  TableHead,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { TableHeadElement } from "../../../components/TableBody/TableHeadElement";
import { TableBodyElement } from "../../../components/TableBody/TableBodyElement";
import {
  setError,
  useCreateMachineTypeMutation,
  useMachineTypeListQuery,
  useDeleteMachineTypeMutation,
  useEditMachineTypeMutation,
} from "../../../redux-services";
import { ColumnsType, Order, SortingData } from "../../../types/CommonTypes";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const tableColumns: ColumnsType[] = [
  {
    id: "name",
    sort: true,
    label: "Machine Name",
  },
  {
    id: "createdAt",
    sort: false,
    label: "Created At",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
    style: { width: "15%" },
  },
];

export interface FormData {
  name: string;
  measurement_unit: string;
  number_plate: boolean;
  volume_capacity: boolean;
  tank_capacity: boolean;
}

interface FormErrors {
  name?: string;
}

const AddMachineType: FC<{ open: boolean; handleModel: any }> = ({
  open,
  handleModel,
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };

  const initialState: FormData = {
    name: "",
    number_plate: false,
    volume_capacity: false,
    tank_capacity: false,
    measurement_unit: "trip",
  };

  const dispatch = useDispatch();
  const initialOrder: Order = process.env.REACT_APP_ORDER as Order;
  const [formData, setFormData] = useState<FormData>(initialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [orderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order] = useState<Order>(initialOrder);
  const [createMachineType] = useCreateMachineTypeMutation();
  const [editMachineType] = useEditMachineTypeMutation();
  const { data: machineTypeData } = useMachineTypeListQuery();
  const [deleteMachineType] = useDeleteMachineTypeMutation();
  const [machineEditId, setMachineEditId] = useState<string>("");
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>("");

  useEffect(() => {
    if (machineTypeData) {
      let data: any = machineTypeData?.response;
      if (machineEditId) {
        const documentToEdit = data.find(
          (doc: { _id: string }) => doc._id === machineEditId
        );
        if (documentToEdit) {
          setFormData({
            name: documentToEdit?.name ?? "",
            number_plate: documentToEdit?.number_plate ?? "",
            volume_capacity: documentToEdit?.volume_capacity ?? "",
            tank_capacity: documentToEdit?.tank_capacity ?? "",
            measurement_unit: documentToEdit?.measurement_unit ?? "",
          });
        } else {
          setFormData(initialState);
        }
        return () => {
          dispatch(setError([]));
        };
      }
    }
  }, [machineEditId, machineTypeData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(setError([]));
    let data: any = formData;

    let formattedData = {
      name: data?.name ?? "",
      number_plate: data?.number_plate ?? false,
      volume_capacity: data?.volume_capacity ?? false,
      tank_capacity: data?.tank_capacity ?? false,
      measurement_unit: data?.measurement_unit ?? false,
    };

    try {
      if (machineEditId) {
        await editMachineType({
          id: machineEditId,
          data: formattedData,
        }).unwrap();
        setMachineEditId("");
      } else {
        await createMachineType(formattedData).unwrap();
      }

      setFormData({
        name: "",
        number_plate: false,
        volume_capacity: false,
        tank_capacity: false,
        measurement_unit: "trip",
      });
    } catch (error) {
      dispatch(setError(["An error occurred"]));
    }
  };

  const validate = (formData: FormData) => {
    const errors: FormErrors = {};
    const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]+/;

    if (!formData.name.trim()) {
      errors.name = "Machine Name is required!";
    } else if (regex.test(formData.name)) {
      errors.name = "Machine Type cannot contain special characters!";
    }

    return errors;
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      measurement_unit: event.target.value,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value.trimStart(),
    }));

    if (name === "name") {
      const errors = validate({ ...formData, [name]: value });
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name as keyof FormErrors],
      }));
    }
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      // handleRequestSort(event, property);
    }
  };

  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteMachineType(Id);
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth={false} className="p-0">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style} className="form-outers karuModal">
              <Typography
                id="transition-modal-title"
                variant="h5"
                className="inner-headings"
                component="h2"
              >
                Add Machine Type
              </Typography>
              <HighlightOffIcon
                className="closeicon"
                onClick={() => handleModel(false)}
              />
              <Box className="modalBody">
                <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        size="small"
                        autoComplete="name"
                        value={formData.name}
                        onChange={handleChange}
                        error={!!formErrors.name}
                        helperText={formErrors.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.number_plate}
                            onChange={handleChange}
                            name="number_plate"
                          />
                        }
                        label="Number Plate"
                        sx={{ color: "white" }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.volume_capacity}
                            onChange={handleChange}
                            name="volume_capacity"
                          />
                        }
                        label="Volume Capacity"
                        sx={{ color: "white" }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.tank_capacity}
                            onChange={handleChange}
                            name="tank_capacity"
                          />
                        }
                        label="Tank Capacity"
                        sx={{ color: "white" }}
                      />
                      <FormControl
                        sx={{ minWidth: 170, minHeight: 60, marginLeft: 2 }}
                      >
                        <InputLabel id="select-label" sx={{ color: "white" }}>
                          Measurement Unit
                        </InputLabel>
                        <Select
                          labelId="select-label"
                          value={formData.measurement_unit || "trip"}
                          onChange={handleSelectChange}
                          label="Measurement Unit"
                          sx={{
                            color: "white",
                            borderColor: "white",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "white",
                            },
                            minHeight: 40,
                          }}
                        >
                          <MenuItem value="km">km</MenuItem>
                          <MenuItem value="hours">hours</MenuItem>
                          <MenuItem value="trip">trip</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="save-btn"
                        style={{ marginRight: "10px" }}
                        disabled={Object.values(formErrors).some(
                          (error) => !!error
                        )}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className="close-btn"
                        onClick={() => handleModel(false)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                <Divider sx={{ marginTop: 5, marginBottom: 3 }} />
                <TableContainer component={Paper} ref={tableContainerRef}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                    className="table-outers"
                  >
                    <TableHead>
                      <TableRow>
                        <TableHeadElement
                          setHead={tableColumns ? tableColumns : []}
                          order={order}
                          orderBy={orderBy}
                          sortFn={(event, id: string) =>
                            createSortHandler(event, id as keyof SortingData)
                          }
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableBodyElement
                        selectedColumns={["name", "createdAt"]}
                        setData={
                          machineTypeData?.response
                            ? machineTypeData?.response
                            : []
                        }
                        showSerialNumber={false}
                        editFn={(id) => {
                          setMachineEditId(id);
                          if (tableContainerRef.current) {
                            tableContainerRef.current.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                            });
                          }
                        }}
                        deleteFn={(id) => {
                          setIsClose(true);
                          setId(id);
                        }}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
                <ConfirmBox
                  title="Machine Document Delete Confirmation"
                  message="Are you sure, you want to delete this Machine Type?"
                  open={isClose}
                  onClose={confirmBoxClose}
                />
              </Box>
            </Box>
          </Fade>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default AddMachineType;
