import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const MachineInfoApi = createApi({
  reducerPath: "machineInfoApi",

  baseQuery: axiosBaseQuery(),

  tagTypes: ["machineInfoTag"],

  endpoints: (builder) => ({
    machineInfoList: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/machine_info/getlist`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
          id: pageData.id ?? undefined,
        },
      }),
      providesTags: ["machineInfoTag"],
    }),

    createMachineInfo: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/machine_info/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["machineInfoTag"],
    }),

    getMachineInfoById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/machine_info/getMachineInfo/${id}`,
        method: "GET",
      }),
      providesTags: ["machineInfoTag"],
    }),

    editMachineInfo: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/machine_info/edit/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["machineInfoTag"],
    }),

    deleteMachineImage: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/machine_info/remove-images/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["machineInfoTag"],
    }),
    getSitesDetail: builder.query<Response<any>, void>({
      query: () => ({
        url: `/sites/site_name`,
        method: "GET",
      }),
      providesTags: ["machineInfoTag"],
    }),

    getSitesDetailByOrgId: builder.query<Response<any>, string>({
      query: (id: string) => ({
        url: `/sites/site_name`,
        method: "GET",
        params: {
          org_id: id,
        },
      }),
      providesTags: ["machineInfoTag"],
    }),

    getMachineByOrganization: builder.query<Response<any>, string>({
      query: (id: string) => ({
        url: `/machine_info/${id}`,
        method: "GET",
      }),
      providesTags: ["machineInfoTag"],
    }),

    getPreviousInsuranceImages: builder.query<
      Response<any>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/machine_info/previous_insurance_images/${id}`,
        method: "GET",
      }),
      providesTags: ["machineInfoTag"],
    }),

    deleteMachineInfo: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/machine_info/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["machineInfoTag"],
    }),
    updateMachineStatus: builder.mutation<
      Response<any>,
      { id: string; status: boolean }
    >({
      query: ({ id, status }) => ({
        url: `/machine_info/updateStatus/${id}`,
        method: "POST",
        body: { status },
      }),
      invalidatesTags: ["machineInfoTag"],
    }),
  }),
});

export const {
  useGetMachineInfoByIdQuery,
  useCreateMachineInfoMutation,
  useEditMachineInfoMutation,
  useDeleteMachineInfoMutation,
  useMachineInfoListQuery,
  useGetSitesDetailQuery,
  useUpdateMachineStatusMutation,
  useDeleteMachineImageMutation,
  useGetPreviousInsuranceImagesQuery,
  useGetSitesDetailByOrgIdQuery,
  useGetMachineByOrganizationQuery,
} = MachineInfoApi;
