import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Grid, Card, CardHeader, CardContent, Toolbar, Typography } from '@mui/material';
import { useCreateUserRoleMutation, useEditUserRoleMutation, useGetUserRoleByIdQuery } from '../../redux-services/api/UserRoleApi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setError } from '../../redux-services';
import Error from '../../components/ErrorBox/Error';
import arrowImage from "../../assests/img/arrow.svg"



export interface UserRoleState {
    role_name: string;
}
interface FormErrors {
    role_name?: string
}
const AddEditUserRole = () => {

    const navigate = useNavigate();
    const { id } = useParams();

    const intialState = {
        role_name: "",
    }
    let dispatch = useDispatch();
    const [formData, setFormData] = useState<UserRoleState>(intialState);
    const [formErrors, setFormErrors] = useState<FormErrors>({});

    const [createUserRole, result] = useCreateUserRoleMutation();
    const [editUserRole, editResult] = useEditUserRoleMutation();
    const { data: UserRoleData } = useGetUserRoleByIdQuery(id, {
        skip: !id,
    });

    useEffect(() => {
        if (UserRoleData?.status === 200) {
            let data = UserRoleData?.response;
            setFormData({
                ...formData,
                role_name: data?.role_name,
            })
        }
        return () => {
            dispatch(setError([]));
        };
    }, [UserRoleData]);

    if (result.isSuccess && result.data.status === 200) {
        navigate("/admin/user/role");
    }

    if (editResult.isSuccess && editResult.data.status === 200) {
        navigate("/admin/user/role");
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const checkError = validate(formData);
        if (Object.values(checkError)?.length > 0) {
            setFormErrors(checkError);
        } else {
            setFormErrors({});
            dispatch(setError([]));
            let formattedData = {
                role_name: formData?.role_name ?? '',
                id: id ? id : null,
            }
            id ? editUserRole(formattedData) : createUserRole(formattedData);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value.trimStart()
        }));

        const errors = validate({ ...formData, [name]: value });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errors[name as keyof UserRoleState]
        }));
    };



    const validate = (formData: UserRoleState) => {
        const errors: Partial<UserRoleState> = {};
        const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/;

        if (!formData.role_name.trim()) {
            errors.role_name = "Role Name is required!";
        } else if (regex.test(formData.role_name)) {
            errors.role_name = "Role Name cannot contain special characters!";
        }



        return errors;
    };
    const breadcrumbs = [
        { name: 'Dashboard', to: '/admin/dashboard' },
        { name: 'Roles-List', to: '/admin/user/role' },
        { name: id ? 'Edit-Role' : 'Add-Role', to: id ? `/admin/user/role/edit/${id}` : '/admin/user/role/add' },

    ];

    return (
        <React.Fragment>
            <Toolbar>
                <div className="breadcrumbs" style={{ display: 'flex', alignItems: 'center' }}>
                    {breadcrumbs.map((crumb, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <img src={arrowImage} alt="arrow" style={{ margin: '0 8px', verticalAlign: 'middle' }} />}
                            {index === breadcrumbs.length - 1 ? (
                                <Typography variant="body2" component="span" style={{ color: 'white' }}>{crumb.name}</Typography>
                            ) : (
                                <Link to={crumb.to} className="breadcrumb-link" style={{ color: 'white' }}>{crumb.name}</Link>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Toolbar>
            <Container maxWidth={false} className='p-0'>
                <Card elevation={3} className="table-outers">
                    <CardHeader className="inner-headings" title={id ? 'Edit Role' : 'Add Role'} />
                    <CardContent>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="role_name"
                                        label="Role Name"
                                        name="role_name"
                                        type="role_name"
                                        size='small'
                                        autoComplete="role_name"
                                        value={formData.role_name}
                                        onChange={handleChange}
                                        error={!!formErrors.role_name}
                                        helperText={formErrors.role_name}
                                    />
                                    <Error current_key="role_name" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onSubmit={handleSubmit}
                                        className="save-btn"
                                        style={{ marginRight: '10px' }}
                                        disabled={Object.values(formErrors).some(error => !!error)}

                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="close-btn"
                                        onClick={() => navigate('/admin/user/role')}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </React.Fragment >
    );
};

export default AddEditUserRole;
