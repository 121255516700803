import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const SiteWorkLogsApi = createApi({
  reducerPath: "siteWorkLogsApi",

  baseQuery: axiosBaseQuery(),

  tagTypes: ["siteWorkLogsTag"],

  endpoints: (builder) => ({
    siteWorkLogsList: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/site_work_log/list`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
        },
      }),
      providesTags: ["siteWorkLogsTag"],
    }),

    createSiteWorkLogs: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/site_work_log/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["siteWorkLogsTag"],
    }),

    getSiteWorkLogsById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/site_work_log/${id}`,
        method: "GET",
      }),
      providesTags: ["siteWorkLogsTag"],
    }),

    editSiteWorkLogs: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/site_work_log/edit/${data.get("id")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["siteWorkLogsTag"],
    }),
    deleteSiteWorkLogs: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/site_work_log/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["siteWorkLogsTag"],
    }),
    getBoreAreaDetail: builder.query<Response<any>, any>({
      query: (data: any) => ({
        url: `/bore_area/bore_area_name`,
        method: "GET",
        params: {
          organization_id: data.organization_id ?? null,
          site_id: data.site_id ?? null,
        },
      }),
      providesTags: ["siteWorkLogsTag"],
    }),
  }),
});

export const {
  useSiteWorkLogsListQuery,
  useCreateSiteWorkLogsMutation,
  useEditSiteWorkLogsMutation,
  useGetSiteWorkLogsByIdQuery,
  useDeleteSiteWorkLogsMutation,
  useGetBoreAreaDetailQuery,
} = SiteWorkLogsApi;
