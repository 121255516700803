import React, { memo, useCallback, useEffect, useState } from 'react';
import {  TextField, Button, Grid, Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, TextareaAutosize, Divider, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { setError, useCreateShiftLogsMutation, useEditShiftLogsMutation, useGetMachineryDetailQuery, useGetShiftLogsByIdQuery, useGetShiftDetailQuery } from '../../redux-services';
import Error from '../../components/ErrorBox/Error';
import dayjs from 'dayjs';

export interface UserState {
    user_id: any;
    machine_id: any;
    shift_id: any;
    from_time: string;
    to_time: string;
    working_datetime: string | Record<string, any>;
    comment: string;
}

const AddEditAttendance = ({ setOpen, id, userInfo }: any) => {

    const intialState = {
        user_id: "",
        machine_id: "",
        shift_id: "",
        from_time: "",
        to_time: "",
        working_datetime: "",
        comment: "",
    }
    let dispatch = useDispatch();
    const [formData, setFormData] = useState<UserState>(intialState);
    const [submitted, setSubmitted] = useState(false);
    const [createShiftLogs, result] = useCreateShiftLogsMutation();
    const [editShiftLogs, editResult] = useEditShiftLogsMutation();
    const { data: ShiftData } = useGetShiftLogsByIdQuery(id, {
        skip: !id,
    });

    const ShiftDetailData = useGetShiftDetailQuery();

    const MachineryData = useGetMachineryDetailQuery();

    useEffect(() => {
        if (ShiftData?.status === 200) {
            const shiftDataResponse = ShiftData?.response || [];
            let data: any = shiftDataResponse;
            const unixTimestamp1 = data.working_datetime;
            const dateObject1 = dayjs(new Date(unixTimestamp1));
            setFormData({
                ...formData,
                user_id: userInfo?.displayName ?? "",
                machine_id: data?.machineData[0] ?? "",
                shift_id: data?.shiftData[0] ?? "",
                from_time: data?.from_time ?? "",
                to_time: data?.to_time ?? "",
                working_datetime: dateObject1,
                comment: data?.comment ?? "",
            });
        }
        return () => {
            dispatch(setError([]));
        };
    }, [ShiftData]);

    useEffect(() => {
        let filterMachineData = MachineryData && MachineryData?.data?.response;
        if (filterMachineData?.length > 0 && !id) {
            filterMachineData = filterMachineData.find((item: any) => item._id === userInfo?.machine_id);
            setFormData({ ...formData, machine_id: filterMachineData, user_id: userInfo?.displayName });
        }
    }, [MachineryData, userInfo]);

    if (result.isSuccess && result.data.status === 200) {
        setOpen(false);
    }

    if (editResult.isSuccess && editResult.data.status === 200) {
        setOpen(false);
    }

    const selectChange = useCallback((event: (SelectChangeEvent | any)) => {
        setFormData({
            ...formData,
            shift_id: event.target.value as string,
            from_time: event.target.value.from_time as string,
            to_time: event.target.value.to_time as string,
        });
    }, [formData]);

    const selectUserChange = useCallback((event: (SelectChangeEvent | any)) => {
        setFormData({ ...formData, user_id: event.target.value as string });
    }, [formData]);

    const selectMachineryChange = useCallback((event: (SelectChangeEvent | any)) => {
        setFormData({ ...formData, machine_id: event.target.value as string });
    }, [formData]);
    const handleDateTimeChange = (fieldName: string, value: any) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldName]: new Date(value).valueOf()
        }));
    };

    const handleSubmit = useCallback((e: any) => {
        e.preventDefault();
        dispatch(setError([]));
        setSubmitted(true);
        let data: any = formData;
        let workingTime = data.working_datetime instanceof Object ? new Date(data.working_datetime).valueOf() : data.working_datetime;
        let formattedData = {
            user_id: userInfo?._id !== '' ? userInfo?._id : null,
            machine_id: data?.machine_id?._id ? data?.machine_id?._id : null,
            shift_id: data?.shift_id?._id ?? "",
            from_time: data?.from_time ?? "",
            to_time: data?.to_time ?? "",
            working_datetime: workingTime,
            comment: data?.comment.trim() ?? "",
            types: "User",
            id: id ? id : null,
        }

        id ? editShiftLogs(formattedData) : createShiftLogs(formattedData);
    }, [formData]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.trimStart() });
    }, [formData])

    return (
        <React.Fragment>
            <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                {id ? 'Edit Attendance Log' : 'Add Attendance Log'}
            </Typography>

            <Box >
                <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                disabled
                                fullWidth
                                id="user_id"
                                label="User Name"
                                name="user_id"
                                type="text"
                                size='small'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                autoComplete="user_id"
                                value={formData.user_id}
                            />
                            <Error current_key="user_id" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">Shifts *</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formData.shift_id}
                                        label="Shifts"
                                        required
                                        onChange={selectChange}
                                        renderValue={() => formData.shift_id?.shift_name}
                                    >
                                        {ShiftDetailData && ShiftDetailData?.data?.response.map(
                                            (item: any, index: number) => (
                                                <MenuItem value={item} key={index}>{item?.shift_name}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">Machinery Names</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formData?.machine_id }
                                        label="Machinery Name"
                                        onChange={selectMachineryChange}
                                        renderValue={() => formData.machine_id?.machine_type + ` (${formData.machine_id?.number_plate})`}
                                    >
                                        {MachineryData && MachineryData?.data?.response.map(
                                            (item: any, index: number) => (
                                                <MenuItem value={item} key={index}>{item?.machine_type + ` (${item?.number_plate})`}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                fullWidth
                                id="from_time"
                                label="Attendance From Time"
                                name="from_time"
                                type="time"
                                className="calendradate"
                                size='small'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                autoComplete="from_time"
                                value={formData.from_time}
                                onChange={handleChange}
                            />
                            <Error current_key="from_time" />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                required
                                fullWidth
                                id="to_time"
                                label="Attendance To Time"
                                name="to_time"
                                className="calendradate"
                                type="time"
                                size='small'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                autoComplete="to_time"
                                value={formData.to_time}
                                onChange={handleChange}
                            />
                            <Error current_key="to_time" />
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >

                            <Grid item xs={12} md={12} className='custom-datepicker' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                                <DateTimePicker
                                    label="EntryTime"
                                    name="working_datetime"

                                    slotProps={{
                                        textField: {
                                            error: !formData.working_datetime,
                                            helperText: submitted && !formData.working_datetime ? 'Working time is required' : '',
                                        },
                                    }}

                                    value={formData.working_datetime}
                                    onChange={(newValue) => handleDateTimeChange('working_datetime', newValue)}
                                    sx={{ width: '100%' }}

                                />

                            </Grid>

                        </LocalizationProvider>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="comment"
                                label="Comment"
                                InputProps={{
                                    inputComponent: TextareaAutosize,
                                    rows: 3
                                }}
                                size='small'
                                name="comment"
                                type="textarea"
                                autoComplete="comment"
                                value={formData.comment}
                                onChange={handleChange}
                            />
                            <Error current_key="comment" />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="save-btn"
                                onSubmit={handleSubmit}
                                style={{ marginRight: '10px' }}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className="close-btn"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    );
};

export default memo(AddEditAttendance);
