import React, { useEffect, useState, FC, useCallback } from 'react';
import { Divider, Fade, Typography } from "@mui/material";
import { TextField, Button, Grid, Box, Modal } from '@mui/material';
import {  useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import { setError, useCreateVehicleInfoMutation, useEditVehicleInfoMutation, useGetTrackerInfoByIdQuery, useGetUserDetailQuery, useGetVehicleInfoByIdQuery } from '../../../../../../redux-services';
import Error from '../../../../../../components/ErrorBox/Error';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
    maxHeight: '80vh',
    overflowY: 'auto',
};
export interface VehicleInfoState {
    fleet_tracker_id: '',
    vehicle_reg_number: '',
    vins_id: '',
    engine_number: '',
    fleet_id: '',
    fuel_type: '',
    is_scv_ev: '',
    lob: '',
    req_by: '',
    vehicle_type: ''
}

const VehicleAddEditModal: FC<{ open: boolean, handleModel: any, editId?: string, siteID?: any }> = ({ open, handleModel, editId, siteID }) => {


    const { id } = useParams();

    const initialState = {
        vehicle_reg_number: '',
        vins_id: '',
        engine_number: '',
        fleet_id: '',
        fuel_type: '',
        is_scv_ev: '',
        lob: '',
        req_by: '',
        vehicle_type: ''
    };

    let dispatch = useDispatch();
    const [formData, setFormData] = useState(initialState);
    const [createVehicleInfo, result] = useCreateVehicleInfoMutation();
    const [editVehicleInfo, editResult] = useEditVehicleInfoMutation();

    const { data: getVehicleDataById } = useGetVehicleInfoByIdQuery(editId, {
        skip: !editId,
    });

    const { data: userDetailInfo } = useGetUserDetailQuery();
    const organizationID = userDetailInfo?.response.organization_id;

    const dtt = getVehicleDataById?.response?.vehicle_info;
    const objectWithRequiredId = dtt?.find((obj: any) => obj._id === editId);
    let objectOtherThanRequiredId = dtt?.filter((obj: any) => obj._id !== editId);


    const { data: getTrackerDataById } = useGetTrackerInfoByIdQuery(id, {
        skip: !id,
    });
    const siteid = getTrackerDataById?.response?.site_id;

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(setError([]));
        let createData = {
            fleet_tracker_id: id, organization_id: organizationID ? organizationID: localStorage.getItem('orgId'),
             vehicle_info: [formData]
        }
    
        let ftid = id;
        let data = {
            vehicle_reg_number: formData?.vehicle_reg_number,
            vins_id: formData?.vins_id,
            engine_number: formData?.engine_number,
            fleet_id: formData?.fleet_id,
            fuel_type: formData?.fuel_type,
            is_scv_ev: formData?.is_scv_ev,
            lob: formData?.lob,
            req_by: formData?.req_by,
            vehicle_type: formData?.vehicle_type,
            _id: editId
        }
        let editData = {};
        let tempForm = { "_id": editId, ...formData }
    
        if (dtt?.length > 1) {
                editData = {
                    id: editId,
                    fleet_tracker_id: ftid,
                    organization_id: organizationID ? organizationID: localStorage.getItem('orgId'),
                    vehicle_info: [...objectOtherThanRequiredId, tempForm]
                }

            console.log(editData);
        } else {
                editData = {
                    id: editId,
                    fleet_tracker_id: ftid,
                    organization_id: organizationID,
                    vehicle_info: [data, tempForm]
                }
        }
        editId ? editVehicleInfo(editData as any) : createVehicleInfo(createData as any);
        handleModel(false);
    };

    useEffect(() => {
        if (getVehicleDataById?.status === 200) {
            setFormData({
                ...formData,
                vehicle_reg_number: objectWithRequiredId?.vehicle_reg_number ?? "",
                vins_id: objectWithRequiredId?.vins_id ?? "",
                engine_number: objectWithRequiredId?.engine_number ?? "",
                fleet_id: objectWithRequiredId?.fleet_id ?? "",
                fuel_type: objectWithRequiredId?.fuel_type ?? "",
                is_scv_ev: objectWithRequiredId?.is_scv_ev ?? "",
                lob: objectWithRequiredId?.lob ?? "",
                req_by: objectWithRequiredId?.req_by ?? "",
                vehicle_type: objectWithRequiredId?.vehicle_type ?? "",
            });
        }

        return () => {
            setFormData(initialState)
        };
    }, [getVehicleDataById]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }, [formData]);

    if ((editResult.isSuccess && editResult.data.status === 200) || (result.isSuccess && result.data.status === 200)) {
        handleModel(false)
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style} className='form-outers karuModal'>
                    <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                        {editId ? 'Edit Vehicle Details' : 'Add Vehicle Details'}
                    </Typography>
                    <HighlightOffIcon className="closeicon" onClick={() => handleModel(false)} />

                    <Box className="modalBody">
                        <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                        <form onSubmit={handleSubmit} >
                            <Grid container spacing={6} >
                                <Grid item xs={12} spacing={2} container alignItems="center">
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="vehicle_reg_number"
                                            label="Vehicle Reg. No."
                                            name="vehicle_reg_number"
                                            type="text"
                                            size='small'
                                            autoComplete="off"
                                            value={formData?.vehicle_reg_number || ""}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <Error current_key="vehicle_reg_number" />
                                    </Grid>
                                    <br />

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="vins_id"
                                            label="Vins ID"
                                            name="vins_id"
                                            type="text"
                                            size='small'
                                            autoComplete="off"
                                            value={formData.vins_id}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <Error current_key="vins_id" />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="engine_number"
                                            label="Engine Number"
                                            name="engine_number"
                                            type="text"
                                            size='small'
                                            autoComplete="off"
                                            value={formData.engine_number}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <Error current_key="engine_number" />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="fleet_id"
                                            label="Fleet ID"
                                            name="fleet_id"
                                            type="text"
                                            size='small'
                                            autoComplete="off"
                                            value={formData.fleet_id}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <Error current_key="fleet_id" />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="fuel_type"
                                            label="Fuel Type"
                                            name="fuel_type"
                                            type="text"
                                            size='small'
                                            autoComplete="off"
                                            value={formData.fuel_type}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <Error current_key="fuel_type" />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="is_scv_ev"
                                            label="is scv ev"
                                            name="is_scv_ev"
                                            type="text"
                                            size='small'
                                            autoComplete="off"
                                            value={formData.is_scv_ev}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <Error current_key="is_scv_ev" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="lob"
                                            label="lob"
                                            name="lob"
                                            type="text"
                                            size='small'
                                            autoComplete="off"
                                            value={formData.lob}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <Error current_key="lob" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="req_by"
                                            label="Req By"
                                            name="req_by"
                                            type="text"
                                            size='small'
                                            autoComplete="off"
                                            value={formData.req_by}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <Error current_key="req_by" />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="vehicle_type"
                                            label="Vehicle Type"
                                            name="vehicle_type"
                                            type="text"
                                            size='small'
                                            autoComplete="off"
                                            value={formData.vehicle_type}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <Error current_key="vehicle_type" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="mt-2">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="save-btn"
                                    style={{ marginRight: '10px' }}
                                >
                                    Submit
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className="close-btn"
                                    onClick={() => handleModel(false)}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default VehicleAddEditModal;
