import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import LeafMap from "leaflet";
import SitesList from "./SitesList";
import useLeafletMap from "../../../components/LeafLetMap/useLeafletMap";
import { MapConfig, MapIcon } from "../../../ constants";
import { IconOptions } from "leaflet";
import {
  useGetVehicleStatusQuery,
  useVehicleInfoListWoIdQuery,
} from "../../../redux-services";
import { useParams } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import L from "leaflet";
import "leaflet.markercluster";

const markersClusters = L.markerClusterGroup();

const ViewSitesInfo = () => {
  const [siteData, setSiteData] = useState<any>([]);

  var gpsData: any = [];
  const [initialZoomDone, setInitialZoomDone] = useState(false);
  const mapRef = useRef<HTMLDivElement>(null);
  let map = useLeafletMap(mapRef, MapConfig.tileLayer.uri);

  const mapRefVehicle = useRef<HTMLDivElement>(null);
  let mapVehicle = useLeafletMap(mapRefVehicle, MapConfig.tileLayer.uri);

  const { id } = useParams();

  const { data: getVehicleStatusData } = useGetVehicleStatusQuery(id, {
    skip: !id,
  });

  const { data } = useVehicleInfoListWoIdQuery({});
  console.log(data);

  const result = getVehicleStatusData?.response?.result;
  const vehicle_data = result?.vehicle_data;

  useEffect(() => {
    if (map) {
      if (mapRef.current && siteData.length > 0) {
        const markersLayer = LeafMap.featureGroup();
        siteData?.forEach((data: any) => {
          if (data?.location !== null) {
            const marker = LeafMap.marker(data?.location?.coordinates);
            map?.flyTo(data?.location?.coordinates, map.getZoom());
            marker.setIcon(LeafMap.icon(MapIcon as IconOptions));
            const popup = LeafMap.popup({
              closeButton: false,
              className: "leak-marker",
              offset: [-3, 1],
            }).setContent(
              `<div class="c-tooltip">
                                <table width="100%"  class="vaolum-data-table">
                                    <thead><tr><th colspan='2'>Site Name:- ${data.name}</th></tr></thead>
                                    <tbody>
                                        <tr>
                                            <td>Latitude</td>
                                            <td>${data?.location?.coordinates[0]}</td>
                                        </tr>
                                        <tr>
                                            <td>Longitude</td>
                                            <td>${data?.location?.coordinates[1]}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>`
            );
            marker.bindPopup(popup);
            markersLayer.addLayer(marker);

            marker.on("mouseover", (e: any) => {
              e.target.openPopup();
            });
            marker.on("mouseout", (e: any) => {
              e.target.closePopup();
            });
          }
        });
        map.addLayer(markersLayer);
        map.fitBounds(markersLayer.getBounds(), { maxZoom: 15 });
        const bounds = markersLayer.getBounds();
        map.fitBounds(bounds, { padding: [50, 50], maxZoom: 4 });

        map.invalidateSize();
      }
    }
  }, [map, siteData]);

  const vinsToRegNumbers = data?.response?.flatMap((item: any) =>
    item.vehicle_info.map((vehicle: any) => ({
      [vehicle.vins_id]: vehicle.vehicle_reg_number,
    }))
  );

  const vinsToRegNumberss = vinsToRegNumbers?.reduce((acc: any, curr: any) => {
    const [vins, reg] = Object.entries(curr)[0];
    acc[vins] = reg;
    return acc;
  }, {});

  if (vehicle_data) {
    const result = Object.values(vehicle_data).map((item: any) => ({
      vins: item.vin as string,
      speed: item.speed as number,
      odometer: item.odometer as number,
      gpsLatitude: item.gpsLatitude as number,
      gpsLongitude: item.gpsLongitude as number,
      status: item.status,
    }));
    gpsData = result;
  } else {
    gpsData = [];
  }
  console.log(gpsData, "lppp");

  useEffect(() => {
    if (mapRefVehicle && gpsData && vehicle_data) {
      markersClusters.clearLayers();
      const markersLayer = LeafMap.featureGroup();
      gpsData.forEach((data: any, i: number) => {
        const marker = LeafMap.marker([
          gpsData[i]?.gpsLatitude,
          gpsData[i]?.gpsLongitude,
        ]);
        marker.setIcon(LeafMap.icon(MapIcon as IconOptions));
        map?.flyTo(
          { lat: gpsData[i]?.gpsLatitude, lng: gpsData[i]?.gpsLongitude },
          map.getZoom()
        );

        const popup = LeafMap.popup({
          closeButton: false,
          className: "leak-marker",
          offset: [2, 1],
        }).setContent(
          `<div class="c-tooltip">
                        <table width="100%" class="vaolum-data-table">
                            <thead><tr><th colspan='2'>Vehicle Info</th></tr></thead>
                            <tbody>
                                <tr>
                                    <td>Reg No</td>
                                    <td>${
                                      vinsToRegNumberss[
                                        `${gpsData[i]?.vins}`
                                      ] || "Not found"
                                    }</td>
                                </tr>
                                <tr>
                                    <td>Latitude</td>
                                    <td>${gpsData[i]?.gpsLatitude}</td>
                                </tr>
                                <tr>
                                    <td>Longitude</td>
                                    <td>${gpsData[i]?.gpsLongitude}</td>
                                </tr>
                                <tr>
                                    <td>Odometer</td>
                                    <td>${gpsData[i]?.odometer}</td>
                                </tr>
                                <tr>
                                    <td>Speed</td>
                                    <td>${gpsData[i]?.speed}</td>
                                </tr>
                                <tr>
    <td>Status</td>
    <td id="${gpsData[i]?.status}">${gpsData[i]?.status}</td>
</tr>

                            </tbody>
                        </table>
                    </div>`
        );

        marker.bindPopup(popup);
        markersLayer.addLayer(marker);
        marker.on("mouseover", (e: any) => {
          e.target.openPopup();
        });
        marker.on("mouseout", (e: any) => {
          e.target.closePopup();
        });
        markersClusters.addLayer(marker);
      });

      mapVehicle?.addLayer(markersLayer);
      mapVehicle?.fitBounds(markersLayer.getBounds(), { maxZoom: 15 });

      mapVehicle?.addLayer(markersClusters);

      if (
        !initialZoomDone &&
        gpsData[0]?.gpsLatitude &&
        gpsData[0]?.gpsLongitude
      ) {
        mapVehicle?.flyTo(
          [gpsData[0].gpsLatitude, gpsData[0].gpsLongitude],
          15
        );
        setInitialZoomDone(true);
      }

      mapVehicle?.invalidateSize();
    }
  }, [mapVehicle, gpsData, vehicle_data]);

  const getWidth = () => {
    if (siteData.length > 0) {
      if (!vehicle_data && !result) {
        return "100%";
      } else {
        return "50%";
      }
    } else {
      return "0%";
    }
  };

  const width = getWidth();

  return (
    <>
      <Box mt={3}>
        <SitesList setSiteData={setSiteData} />
      </Box>

      <Box className="map-container" style={{ height: "80%" }}>
        <Box
          id={vehicle_data && result ? "site-map-half" : "site-map-full"}
          style={{
            width: width,
            height: "100%",
            display: siteData.length > 0 ? "block" : "none",
          }}
        >
          <h4 style={{ color: "white", margin: "10px 40%" }}>Site Location</h4>
          <div
            ref={mapRef}
            id="map"
            style={{ height: "80%", width: "95%" }}
          ></div>
        </Box>

        <Box
          style={{
            width: vehicle_data ? "50%" : "0%",
            height: "100%",
            display: vehicle_data ? "block" : "none",
          }}
        >
          <h4 style={{ color: "white", margin: "10px 30%" }}>
            Vehicle Location
          </h4>
          <div
            ref={mapRefVehicle}
            id="mapVehicle"
            style={{ width: "100%", height: "80%" }}
          ></div>
        </Box>
      </Box>
    </>
  );
};

export default ViewSitesInfo;
