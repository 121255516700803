import { createApi } from "@reduxjs/toolkit/query/react";

import {
  PageMeta,
  PaginatedData,
  Response,
  VehicleInfo,
} from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const VehicleInfoApi = createApi({
  reducerPath: "VehicleInfoApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["vehicleInfoTag"],
  endpoints: (builder) => ({
    vehicleInfoList: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/vehicleinfo/vehiclelist`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
          id: pageData.id ?? "",
        },
      }),
      providesTags: ["vehicleInfoTag"],
    }),
    vehicleInfoListWoId: builder.query<Response<any>, any>({
      query: (pageData: any) => ({
        url: `/vehicleinfo/vehiclelistwoid`,
        method: "GET",
        params: {
        },
      }),
      providesTags: ["vehicleInfoTag"],
    }),
    vehicleInfoListWithSite: builder.query<
      Response<PaginatedData<any>>,
      PageMeta
    >({
      query: (pageData: PageMeta) => ({
        url: `/vehicleinfo/vehiclelistwithsite`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
          id: pageData.id ?? "",
        },
      }),
      providesTags: ["vehicleInfoTag"],
    }),
    createVehicleInfo: builder.mutation<Response<any>, FormData>({
      query: (data: FormData) => ({
        url: `/vehicleinfo/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["vehicleInfoTag"],
    }),
    getVehicleAddress: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/sites/getmorevehicleinfo/${id}`,
        method: "GET",
      }),
      providesTags: ["vehicleInfoTag"],
    }),
    getVehicleDaywiseHistory: builder.query<
      Response<PaginatedData<any>>,
      {
        pageData?: PageMeta;
        id: string | undefined;
        siteId: string | undefined;
      }
    >({
      query: ({ pageData, id, siteId }) => ({
        url: `/sites/getdaywise/${id}/${siteId}`,
        method: "GET",
        params: {
          limit: pageData?.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData?.page ?? 1,
          order: pageData?.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData?.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData?.searchText,
          startDate: pageData?.startDate ?? "",
          endDate: pageData?.endDate ?? "",
        },
      }),
      providesTags: ["vehicleInfoTag"],
    }),
    getVehicleServiceHistory: builder.query<
      Response<PaginatedData<any>>,
      PageMeta
    >({
      query: (pageData: PageMeta) => ({
        url: `/sites/getservicehistory`,
        method: "GET",
        params: {
          limit: pageData?.limit ?? Number(process.env.REACT_APP_PER_PAGE),
          number_plate: pageData?.number_plate ?? "",
          org_id: pageData?.org_id ?? "",
          year: pageData?.year ?? "",
          page: pageData?.page ?? 1,
          order: pageData?.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData?.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData?.searchText,
        },
      }),
      providesTags: ["vehicleInfoTag"],
    }),
    getVehicleCurrStatus: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/sites/getcurrstatus/${id}`,
        method: "GET",
      }),
      providesTags: ["vehicleInfoTag"],
    }),
    getVehicleDueService: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/sites/getdueservice/${id}`,
        method: "GET",
      }),
      providesTags: ["vehicleInfoTag"],
    }),
    getVehicleUsage: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/sites/getvehicleusage/${id}`,
        method: "GET",
      }),
      providesTags: ["vehicleInfoTag"],
    }),
    getVehicleInfoById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/vehicleinfo/getVehicle/${id}`,
        method: "GET",
      }),
      providesTags: ["vehicleInfoTag"],
    }),
    getVehicleStatus: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/sites/vehiclecurrentinfo/${id}`,
        method: "GET",
      }),
      providesTags: ["vehicleInfoTag"],
    }),
    deleteVehicleInfo: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/vehicleinfo/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["vehicleInfoTag"],
    }),
    editVehicleInfo: builder.mutation<Response<any>, any>({
      query: (data: VehicleInfo) => ({
        url: `/vehicleinfo/edit/${data?.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["vehicleInfoTag"],
    }),
  }),
});

export const {
  useVehicleInfoListQuery,
  useVehicleInfoListWoIdQuery,
  useVehicleInfoListWithSiteQuery,
  useCreateVehicleInfoMutation,
  useGetVehicleAddressQuery,
  useGetVehicleDaywiseHistoryQuery,
  useGetVehicleServiceHistoryQuery,
  useGetVehicleCurrStatusQuery,
  useGetVehicleDueServiceQuery,
  useGetVehicleUsageQuery,
  useGetVehicleInfoByIdQuery,
  useGetVehicleStatusQuery,
  useDeleteVehicleInfoMutation,
  useEditVehicleInfoMutation,
} = VehicleInfoApi;
