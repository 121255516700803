import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Container, TextField, Typography, Modal, Fade, Backdrop, Snackbar, Alert } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {  useChangePasswordMutation } from '../../redux-services';
import { useSelector } from "react-redux";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { RootState } from '../../store/store';

export interface LoginType {
    email: string;
    password: string;
    confirm_password: string;
    current_password: string;
}

const ChangePassword: FC<{ open: boolean, setShowModal: (open: boolean) => void }> = ({ open, setShowModal }) => {
  
    const userDetail = useSelector((state: RootState) => state.auth.user);
    const initialState = {
        email: userDetail?.email ?? '',
        current_password: '',
        password: '',
        confirm_password: '',
    };

    const [changePassword] = useChangePasswordMutation();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [formData, setFormData] = useState<LoginType>(initialState);
    const [showError, setShowError] = useState<{ password: boolean, confirm_password: boolean, current_password: boolean }>({ password: false, confirm_password: false, current_password: false });
    const [isShowPwd, setIsShowPwd] = useState<boolean>(false);
    const [isShowConfirmPwd, setIsShowConfirmPwd] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<string>('');


    useEffect(() => {
        if (snackbarOpen) {
            const timer = setTimeout(() => {
                setShowModal(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [snackbarOpen]);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);

    };

    const validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,}$/;
        return passwordRegex.test(password);
    };


    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
        e.preventDefault();
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });

        if (name === 'password') {
            if (value === formData.current_password) {
                setPasswordError('Password cannot be the same as the current password.');
                setShowError(prev => ({ ...prev, password: true }));
            } else if (!validatePassword(value)) {
                setPasswordError('Password must be 6 characters long, include a lowercase letter, an uppercase letter, a number, and a special character.');
                setShowError(prev => ({ ...prev, password: true }));
            } else {
                setPasswordError('');
                setShowError(prev => ({ ...prev, password: false }));
            }
        } else if (name === 'confirm_password') {
            if (value !== formData.password) {
                setPasswordError('The confirm password confirmation does not match the password provided.');
                setShowError(prev => ({ ...prev, confirm_password: true }));
            } else {
                setPasswordError('');
                setShowError(prev => ({ ...prev, confirm_password: false }));
            }
        } else if (name === 'current_password') {
            if (value === formData.password) {
                setPasswordError('Current password cannot be the same as the new password.');
                setShowError(prev => ({ ...prev, current_password: true }));
            } else {
                setPasswordError('');
                setShowError(prev => ({ ...prev, current_password: false }));
            }
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (formData.password !== formData.confirm_password) {
            setShowError(prev => ({ ...prev, confirm_password: true }));
            setPasswordError('The confirm password confirmation does not match the password provided.');
        } else if (!validatePassword(formData.password)) {
            setShowError(prev => ({ ...prev, password: true }));
            setPasswordError('Password must be at least 6 characters long, include a special symbol, a number, an uppercase letter, and a lowercase letter.');
        } else if (formData.password === formData.current_password) {
            setShowError(prev => ({ ...prev, password: true }));
            setPasswordError('New password cannot be the same as the current password.');
        } else {
            try {
                setShowError({ password: false, confirm_password: false, current_password: false });
                setPasswordError('');
                const result = await changePassword(formData).unwrap();
                if (result.status === 200) {
                    setSnackbarMessage('Password changed successfully');
                    setSnackbarOpen(true);
                } else {
                    setPasswordError('An error occurred while changing the password.');
                }
            } catch (error) {
                setPasswordError('An error occurred while changing the password.');
            }
        }
    };


    const isButtonDisabled = Object.values(showError).includes(true) || !formData.current_password || !formData.password || !formData.confirm_password;

    return (
            <Container maxWidth={false} className='p-0'>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={() => setShowModal(false)}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={open}>
                        {/* <Box className=''> */}
                        <Box className="form-outers" sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            border: '1px solid #000',
                            boxShadow: 24,
                            borderRadius: 2,
                            p: 2,
                            width: '90%',
                            maxWidth: '500px',
                        }}>
                            <Container component="main" maxWidth="sm">
                              
                                <Typography className="inner-headings" component="h1" variant="h5">
                                    Change Password
                                </Typography>
                                <HighlightOffIcon className="closeicon" onClick={() => { setShowModal(false) }} />

                                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        name="current_password"
                                        label="Current Password"
                                        type='text'
                                        id="current_password"
                                        autoComplete="Current Password"
                                        value={formData.current_password}
                                        onChange={handleOnChange}
                                        error={showError.current_password}
                                        helperText={showError.current_password && passwordError}
                                    />
                                    <span className="password-login">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type={!isShowPwd ? "password" : 'text'}
                                            id="password"
                                            autoComplete="password"
                                            value={formData.password}
                                            onChange={handleOnChange}
                                            error={showError.password}
                                            helperText={showError.password && passwordError}
                                        />
                                        <Box onClick={() => { setIsShowPwd(!isShowPwd) }} className="pwd-icon" sx={{ color: 'white' }}>
                                            {isShowPwd ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </Box>
                                    </span>
                                    <span className="password-login">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="confirm_password"
                                            label="Confirm Password"
                                            type={!isShowConfirmPwd ? "password" : 'text'}
                                            id="confirm_password"
                                            autoComplete="confirm_password"
                                            value={formData.confirm_password}
                                            onChange={handleOnChange}
                                            error={showError.confirm_password}
                                            helperText={showError.confirm_password && passwordError}
                                        />
                                        <Box onClick={() => { setIsShowConfirmPwd(!isShowConfirmPwd) }} className="pwd-icon" sx={{ color: 'white' }}>
                                            {isShowConfirmPwd ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </Box>
                                    </span>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        className="secondary-btn py-3"
                                        disabled={isButtonDisabled}
                                    >
                                        {'Change Password'}
                                    </Button>
                                    {/* </Box> */}
                                </Box>
                                <Snackbar
                                    open={snackbarOpen}
                                    autoHideDuration={3000}
                                    onClose={handleCloseSnackbar}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                >
                                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                                        {snackbarMessage}
                                    </Alert>
                                </Snackbar>
                            </Container>
                        </Box>
                    </Fade>
                </Modal>
            </Container>
    );
}

export default ChangePassword;
