import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const BoreAreaApi = createApi({
  reducerPath: "boreAreaApi",

  baseQuery: axiosBaseQuery(),

  tagTypes: ["boreAreApi"],

  endpoints: (builder) => ({
    boreAreaList: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/bore_area/list`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
          site_id: pageData.id ?? "",
        },
      }),
      providesTags: ["boreAreApi"],
    }),

    createBoreArea: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/bore_area/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["boreAreApi"],
    }),

    getBoreAreaById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/bore_area/${id}`,
        method: "GET",
      }),
      providesTags: ["boreAreApi"],
    }),

    editBoreArea: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/bore_area/edit/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["boreAreApi"],
    }),
    deleteBoreArea: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/bore_area/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["boreAreApi"],
    }),
  }),
});

export const {
  useBoreAreaListQuery,
  useCreateBoreAreaMutation,
  useEditBoreAreaMutation,
  useGetBoreAreaByIdQuery,
  useDeleteBoreAreaMutation,
} = BoreAreaApi;
