import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";


export const MonthlyReportApi = createApi({
    reducerPath: "monthlyReportApi",

    baseQuery: axiosBaseQuery(),

    tagTypes: ["monthlyReportApi"],

    endpoints: (builder) => ({
        machineMonthlyReportList: builder.query<Response<any>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/report/machine_efficiency`,
                method: "GET",
                params: {
                   startDate: pageData.startDate,
                   endDate: pageData.endDate,
                   site_id: pageData.site_id,
                },
            }),
            providesTags: ["monthlyReportApi"],
        }),

        empAttendanceMonthlyReportList: builder.query<Response<any>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/report/employee_attendance`,
                method: "GET",
                params: {
                   startDate: pageData.startDate,
                   endDate: pageData.endDate,
                   site_id: pageData.site_id,
                },
            }),
            providesTags: ["monthlyReportApi"],
        }),

        earningExpenditureReportList: builder.query<Response<any>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/report/profit_expense`,
                method: "GET",
                params: {
                   startDate: pageData.startDate,
                   endDate: pageData.endDate,
                   site_id: pageData.site_id,
                },
            }),
            providesTags: ["monthlyReportApi"],
        }),
    })
});

export const {
    useMachineMonthlyReportListQuery,
    useEmpAttendanceMonthlyReportListQuery,
    useEarningExpenditureReportListQuery,
} = MonthlyReportApi;
