import {  FC,  memo } from 'react';
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider, Fade, Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useGetVehicleAddressQuery } from '../../../../../../redux-services';
import VehicleMap from './VehicleMap';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const style = {
    position: 'absolute' as 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
};

const VehicleInfoModal: FC<{ open: boolean, handleModel: any, vins_id?: string, lat?:number, lon?:number, veh_address?: string }> = ({ open, handleModel, vins_id, lat, lon, veh_address }) => {
    
    const { data: getVehicleAddressData } = useGetVehicleAddressQuery(vins_id, {
        skip: !vins_id,
    });

    const vehicle_address = veh_address ? veh_address : getVehicleAddressData?.response?.results[0];
    const latitude = lat?lat: getVehicleAddressData?.response?.latitude;
    const longitude = lon?lon: getVehicleAddressData?.response?.longitude;
    return (
        <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className='form-outers karuModal'>
                        <Typography style={{paddingLeft: 7}} id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                            Vehicle Details
                        </Typography>
                        <HighlightOffIcon className="closeicon" onClick={() => handleModel(false)} />
                        <Divider sx={{ marginTop: 2, marginBottom: 3 }} />

                        <Box className="modalBody">
                            <Card sx={{ minWidth: 275 }} className='card-inner'>
                                <CardContent>
                                    <Typography sx={{ fontSize: 17, fontWeight: 900 }} color="white" gutterBottom>
                                        {veh_address? veh_address :vehicle_address?.formatted_address}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, fontWeight: 900}} component="div">
                                        {veh_address? "":vehicle_address?.city} {veh_address?"":vehicle_address?.state}
                                    </Typography>
                                </CardContent>
                            </Card>
                            {
                            latitude && longitude && <VehicleMap lat={latitude} lon={longitude} address={veh_address? veh_address:vehicle_address?.formatted_address+vehicle_address?.city+vehicle_address?.state}/>
                            }
                           <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                        </Box>
                        <Button
                            className='secondary-btn'
                            onClick={() => handleModel(false)}
                        >
                            Close
                        </Button>
                        
                    </Box>
                </Fade>
            </Modal>
    );
}
export default memo(VehicleInfoModal)
