import { FC, useEffect, useRef, useState } from "react";
import LeafMap, { IconOptions, Map } from 'leaflet';
import { MapConfig, MapIcon } from "../../../../../../ constants";
import useLeafletMap from "../../../../../../components/LeafLetMap/useLeafletMap";

export interface LatLng {
    latitude: number,
    longitude: number
}

const VehicleMap: FC<any> = ({ lat, lon, address }) => {
    console.log(lat, lon);
    const mapRef = useRef<HTMLDivElement>(null);
    const [position, setPosition] = useState(null);
    let map = useLeafletMap(mapRef, MapConfig.tileLayer.uri)
    useEffect(() => {
        if (map) {
            map.locate().on("locationfound", (e: any) => {
                setPosition(e.latlng);
                map?.flyTo([lat, lon], map.getZoom());
                const radius = e.accuracy;
                const circle = LeafMap.circle([lat, lon], radius);
                circle.addTo(map as Map);
            });
        }
    }, [map]);

    useEffect(() => {
        if (position) {
            const markersLayer = LeafMap.featureGroup();
            const marker = LeafMap.marker([lat, lon]);
            marker.setIcon(LeafMap.icon(MapIcon as IconOptions));
            const popup = LeafMap.popup({ closeButton: false, className: 'leak-marker', offset: [-3, 1] }).setContent(
                `<div class="c-tooltip">
                    <table width="100%"  class="vaolum-data-table">
                        <thead><tr><th colspan='2'></th></tr></thead>
                        <tbody class='vehicle_info_card'>
                            <tr>
                                <td>Latitude</td>
                                <td>${lat}</td>
                            </tr>
                            <tr>
                                <td>Longitude</td>
                                <td>${lon}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>${address}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>`);
            marker.bindPopup(popup);
            marker.on('mouseover', (e: any) => {
                e.target.openPopup()
            });
            marker.on('mouseout', (e: any) => {
                e.target.closePopup()
            });
            markersLayer.addLayer(marker);
            map?.addLayer(markersLayer);
            map?.fitBounds(markersLayer.getBounds(), { maxZoom: 15 });
        }
    }, [position]);

    return (
            <div ref={mapRef} id="map" style={{ height: '300px' }}></div>
    
    )
}

export default VehicleMap;