import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Snackbar,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    debounce,
} from "@mui/material";
import { TableBodyElement } from '../../../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../../../components/TableBody/TableHeadElement';
import { ColumnsType, Order, PageMeta, SortingData } from '../../../../types/CommonTypes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmBox from '../../../../components/ConfirmBox/ConfirmBox';
import { SearchElement } from '../../../../components/Search/Search';
import { useDeleteWorkItemMutation, useGetSitesByIdQuery, useWorkItemListQuery } from '../../../../redux-services';
import AddEditWorkItem from './WorkItemAddEditModal';
import MachineWorkLog from '../../../WorkItem/MachineWorkLog';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "work_item",
        sort: true,
        label: "Work Item",
    },
    {
        id: "chainage_start_point",
        sort: true,
        label: "Chainage Start Point",
    },
    {
        id: "chainage_end_point",
        sort: true,
        label: "Chainage End Point",
    },
    {
        id: "bore_area",
        sort: true,
        label: "Bore Area",
    },
    {
        id: "total_trip",
        sort: true,
        label: "Total Trips",
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "18%" },
    },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

export interface UserState {
    shift_id: any;
    select_date: string | Record<string, any>;
}

const WorkItem: FC<{ siteDetail: { id: string, name: string } }> = ({ siteDetail }) => {
    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: 'desc',
        order_by: "entry_date",
    };
    const intialState = {
        shift_id: "",
        select_date: "",
    };

    let navigate = useNavigate();
    let query = useLocation();
    let { id: siteId } = useParams();
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const [open, setOpen] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>('');
    const [formData, setFormData] = useState<UserState>(intialState);

    const [siteWorkLogList, setSiteWorkLogList] = useState<PageMeta>(initialState);
    const [isClose, setIsClose] = useState<boolean>(false);
    const [Id, setId] = useState<string>("");
    const [isActive, setIsActive] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [viewMachineLog, setViewMachineLog] = useState(false);
    const [workItemId, setWorkItemId] = useState('');
    const siteOrgId = useSelector((state: RootState) => state.auth.siteOrgId);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false); 

    const [viewWorkItemData, setViewWorkItemData] = useState<{ entry_date: string, work_type: string, shift: string }>({ entry_date: "", work_type: "", shift: "" });

    const {  data, refetch } = useWorkItemListQuery({
        per_page: siteWorkLogList.per_page ?? rowsPerPage,
        page: query.state ?? siteWorkLogList.page,
        order: siteWorkLogList.order ?? order,
        order_by: siteWorkLogList.order_by ?? orderBy,
        searchText: siteWorkLogList.searchText ?? search,
        id: siteId,
        organization_id: siteOrgId,
        select_date: formData?.select_date ?? '',
        shift_id: formData?.shift_id?._id ?? '',
    });
    const { data: getData } = useGetSitesByIdQuery(siteId);

    useEffect(() => {
        if (getData?.status === 200) {
            let data = getData?.response;
            setIsButtonDisabled(data.is_deactivate)
            setIsActive(data.is_active)

        }
    }, [getData]);

    const [deleteWorkItem] = useDeleteWorkItemMutation();

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setSiteWorkLogList({
            ...siteWorkLogList,
            order: newOrder,
            order_by: property,
        });
    };

    const getTodayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); 
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setSiteWorkLogList({ ...siteWorkLogList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setSiteWorkLogList({
            ...siteWorkLogList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setSiteWorkLogList({
                page: 1,
                per_page: siteWorkLogList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

    const confirmBoxClose = (res: boolean) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteWorkItem(Id);
        } else {
            setIsClose(res);
        }
    };
    const handleModel = useCallback((isOpen: boolean) => {
        setOpen(isOpen);
    }, []);
    const handleShowMessage = () => {
        setSnackbarOpen(true);
        setTimeout(() => {
            setSnackbarOpen(false);
        }, 3000);
    };


    const handleMachineLogModel = useCallback((isOpen: boolean) => {
        setViewMachineLog(isOpen);
    }, []);

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value.trimStart()
        }));
    };

    const selectChange = (event: (SelectChangeEvent | any)) => {
        setFormData({
            ...formData,
            shift_id: event.target.value as string
        });
    };
    const deleteFn = (id: string) => {
        if (isButtonDisabled) {
            alert("Work Item cannot be deleted as its completed")
            return;
        }
        setIsClose(true);
        setId(id);
    };
    const editFn = (id: string) => {
        if (isButtonDisabled) {
            alert("Work Item cannot be edited as its completed")
            return;
        }
        setOpen(true);
        setEditId(id);
    };
    return (
        <div>
            <Paper className="table-outers mb-2">
                <Toolbar>
                    <Typography
                        sx={{ flex: '1 1 40%' }}
                        variant="h5"
                        id="tableTitle"
                        component="div"
                        className="inner-headings"
                    >
                        Work Items
                    </Typography>
                    <div className="flex-boxinner" style={{ gap: "10px" }}>
                        <TextField
                            id="select_date"
                            name="select_date"
                            label="Select Date"
                            className='theme-select-date'
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                max: getTodayDate(),
                            }}
                            value={formData.select_date}
                            size='small'
                            onChange={handleDateChange}
                        />
                        <Box sx={{ minWidth: 150 }}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-simple-select-label">Select Shifts *</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formData.shift_id !== '' ? formData.shift_id : "None"}
                                    label="Select Shifts"
                                    required
                                    onChange={selectChange}
                                    renderValue={() => formData.shift_id !== "" ? formData.shift_id?.name : "None"}
                                >
                                    <MenuItem value={""}>None</MenuItem>
                                    {getData && getData?.response?.shiftNames?.map(
                                        (item: any, index: number) => (
                                            <MenuItem value={item} key={index}>{item?.name}</MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Typography
                            sx={{ flex: '1 1 auto' }}
                        >
                            <SearchElement
                                searchFn={searchHandler}
                                searchTag={"Search Work Item..."}
                            />

                        </Typography>
                        <Tooltip title="Add Work Item" arrow className="me-1 mt-1">
                            <Button className="secondary-btn" onClick={() => { setOpen(true); setEditId(''); handleShowMessage(); }} type="submit" variant="contained" color="primary" disabled={isButtonDisabled}>Add Work Item</Button>
                        </Tooltip>
                        <Snackbar
                            open={snackbarOpen && !isActive}
                            autoHideDuration={3000}
                            onClose={() => setSnackbarOpen(false)}
                            message="Please activate this site to add Site Work logs."
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            style={{ marginTop: '140px' }}
                        />
                    </div>
                </Toolbar>
                <StyledEngineProvider>
                    <TableContainer component={Paper} className="table-outers">
                        <Table
                            sx={{ minWidth: 650 }}
                            size="small" aria-label="a dense table"
                            className="table-outers"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableHeadElement
                                        setHead={tableColumns ? tableColumns : []}
                                        order={order}
                                        orderBy={orderBy}
                                        sortFn={(event: any, id: string) =>
                                            createSortHandler(event, id as keyof SortingData)
                                        }
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableBodyElement
                                    selectedColumns={[
                                        "work_item",
                                        "chainage_start_point",
                                        "chainage_end_point",
                                        "bore_area",
                                        "total_trip"
                                    ]}
                                    setData={data?.response.data ? data?.response.data : []}
                                    deleteFn={deleteFn}
                                    viewWorkItemFn={(id: string, data: any) =>
                                    {
                                        setWorkItemId(id);
                                        setViewMachineLog(true);
                                        setViewWorkItemData({ entry_date: data?.entry_date, work_type: data?.work_type, shift: data?.shift });
                                    }
                                    }
                                    editFn={editFn}
                                    pageData={{
                                        limit:
                                            rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                        page: data?.response.page as number,
                                    }}
                                />
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={data?.response.total ?? 0}
                            page={data?.response.page ?? 0}
                            rowsPerPageOptions={[20, 50, 100]}
                            onPageChange={handleChangePage}
                            rowsPerPage={
                                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                            }
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </StyledEngineProvider>
            </Paper>
            <ConfirmBox
                title="Work Item Delete Confirmation"
                message="Are you sure, you want to delete this Work Item?"
                open={isClose}
                onClose={confirmBoxClose}
            />
            {open && isActive &&
                <AddEditWorkItem
                    open={open}
                    handleModel={handleModel}
                    siteInfo={siteDetail}
                    id={editId}
                />
            }
            {viewMachineLog &&
                <MachineWorkLog
                    open={viewMachineLog}
                    buttonDisabled={isButtonDisabled}
                    id={workItemId}
                    handleMachineLogModel={handleMachineLogModel}
                    siteId={siteDetail?.id ?? ''}
                    titleData={viewWorkItemData?.entry_date !== '' ? ('(' + viewWorkItemData?.entry_date + ' (' + viewWorkItemData?.shift + ')' + ' - ' + viewWorkItemData?.work_type + ')') : ""}
                    refetch={refetch}
                />
            }
        </div>
    )
}

export default memo(WorkItem);