import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";


export const OrganizationApi = createApi({
    reducerPath: "organizationApi",

    baseQuery: axiosBaseQuery(),

    tagTypes: ["organizationTag"],

    endpoints: (builder) => ({
        organizationList: builder.query<Response<PaginatedData<any>>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/organization/getlist`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                    organization_id: pageData.organization_id,
                },
            }),
            providesTags: ["organizationTag"],
        }),

        createOrganization: builder.mutation<Response<any>, FormData>({
            query: (data: FormData) => ({
                url: `/organization/create`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["organizationTag"],
        }),

        getOrganizationById: builder.query<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/organization/getOrganization/${id}`,
                method: "GET",
            }),
            providesTags: ["organizationTag"],
        }),

        editOrganization: builder.mutation<Response<any>, FormData>({
            query: (data: FormData) => ({
                url: `/organization/edit/${data.get("id")}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["organizationTag"],
        }),
        deleteOrganization: builder.mutation<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/organization/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["organizationTag"],
        }),

    })
});

export const {
    useOrganizationListQuery,
    useCreateOrganizationMutation,
    useGetOrganizationByIdQuery,
    useEditOrganizationMutation,
    useDeleteOrganizationMutation,
} = OrganizationApi;
