import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import arrowImage from "../../../assests/img/arrow.svg";
import {
    Button,
    Paper,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
    debounce,
} from "@mui/material";
import { TableBodyElement } from '../../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../../components/TableBody/TableHeadElement';
import { ColumnsType, Order, PageMeta, SortingData } from '../../../types/CommonTypes';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmBox from '../../../components/ConfirmBox/ConfirmBox';
import { SearchElement } from '../../../components/Search/Search';
import { useSitesListByOrgIdQuery, useDeleteSitesMutation, useUpdateSiteStatusMutation, setSiteOrganizationId, useGetOrganizationByIdQuery,useDeactivateSitesMutation } from '../../../redux-services';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import SiteAddEditModal from '../../../components/Models/SiteAddEditModal';
import DeactivateSiteBox from '../../../components/ConfirmBox/DeactivateSiteBox';
import { set } from 'date-fns';
import { isDisabled } from '@testing-library/user-event/dist/utils';
interface MachineType {
    _id: string;
    status: boolean;
}
const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "name",
        sort: true,
        label: "Site Name",
    },
    {
        id: "organization_name",
        sort: true,
        label: "Organization Name",
    },
    {
        id: "country",
        sort: true,
        label: "Country",
    },
    {
        id: "state",
        sort: true,
        label: "State"
    },
    {
        id: 'city',
        sort: true,
        label: "City"
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "15%" },
    },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const SitesList: FC<{ setSiteData: any }> = ({ setSiteData }) => {
    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: 'name',
    };
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    let query = useLocation();
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
    const [activeMachineId, setActiveMachineId] = useState<string>("");
    const [machineStatus, setMachineStatus] = useState<boolean>(false);
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>("");
    const [organizationName, setOrganizationName] = useState<string>('');
    
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const [sitesList, setSitesList] = useState<PageMeta>(initialState);
    const [isClose, setIsClose] = useState<boolean>(false);
    const [isCompleteSite,setCompleteSite]=useState<boolean>(false);
    const [Id, setId] = useState<string>("");
    const [deactivateId,setDeactivateId]=useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [disabledIds, setDisabledIds] = useState<string[]>([]);

    const { data: UserData } = useGetOrganizationByIdQuery(id, {
        skip: !id,
    });
    const userDetail = useSelector((state: RootState) => state.auth.user);

    useEffect(() => {
        if (UserData?.status === 200) {
            let orgData = UserData?.response && UserData?.response[0];
            setOrganizationName(orgData?.organization_name ?? '');
        }
    }, [UserData]);

    const { data } = useSitesListByOrgIdQuery({
        per_page: sitesList.per_page ?? rowsPerPage,
        page: query.state ?? sitesList.page,
        order: sitesList.order ?? order,
        order_by: sitesList.order_by ?? orderBy,
        searchText: sitesList.searchText ?? search,
        organization_id: id ?? '',
    });
    useEffect(() => {
        if (data) {
            const fetchedData = data.response.data;
            setSiteData(fetchedData);
            const idsToDisable = fetchedData
                .filter((item:any) => item.is_deactivate === true)
                .map((item:any) => item._id);
            setDisabledIds(idsToDisable);
        }
    }, [data, setSiteData]);

    const [deleteSites] = useDeleteSitesMutation();
    const [deactivateSites]=useDeactivateSitesMutation();
    const [updateActiveStatus] = useUpdateSiteStatusMutation();


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setSitesList({
            ...sitesList,
            order: newOrder,
            order_by: property,
        });
    };
    const deleteFn = (id:string) => {
        if (disabledIds?.includes(id)) {
            alert("Site cannot be deleted as its deactivated")
            return;
        }
        setIsClose(true);
        setId(id);
    };
    const isActivefn=(id: string)=> {
        if (disabledIds.includes(id)) {
            alert(`Cannot toggle active for this site as it is deactivated already`);
            return;
        }
        handleToggleActive(id);
    }
    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setSitesList({ ...sitesList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setSitesList({
            ...sitesList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setSitesList({
                page: 1,
                per_page: sitesList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);
    const handleToggleActive = (id: string) => {
        const machine = data?.response.data.find((machine: MachineType) => machine._id === id);
        if (machine) {
            setActiveMachineId(id);
            setIsConfirmationOpen(true);
            setMachineStatus(machine.is_active);
        }
    };
    const handleConfirmationResponse = (confirmed: boolean) => {
        if (confirmed) {
            const newStatus = !machineStatus;
            updateActiveStatus({ id: activeMachineId, status: newStatus });
        }
        setActiveMachineId("");
        setIsConfirmationOpen(false);
    };

    const confirmBoxClose = (res: boolean) => {
        if (res) {
            setIsClose(false);
            deleteSites(Id);
        } else {
            setIsClose(res);
        }
    };
    const deactivateConfirmBoxClose=(res:boolean)=>{
        if (res) {
            setCompleteSite(false);
            deactivateSites(deactivateId);
        } else {
            setCompleteSite(res);
        }
    }

    const handleModel = useCallback((isOpen: boolean) => {
        setShowModal(isOpen);
    }, []);
    const breadcrumbs = [
        { name: 'Dashboard', to: '/admin/dashboard' },
        ...(userDetail?.role_type === 0 ? [{ name: 'Organizations', to: '/admin/organization' }] : []),
        { name: organizationName, to: `/admin/organization/${id}` },
    ];

    return (
        <div>
            <Paper className="form-outers">
                <Toolbar>
                    <div className="breadcrumbs" style={{ display: 'flex', alignItems: 'center' }}>
                        {breadcrumbs.map((crumb, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && <img src={arrowImage} alt="arrow" style={{ margin: '0 8px', verticalAlign: 'middle' }} />}
                                {index === breadcrumbs.length - 1 ? (
                                    <Typography variant="body2" component="span" style={{ color: 'white' }}>{crumb.name}</Typography>
                                ) : (
                                    <Link to={crumb.to} className="breadcrumb-link" style={{ color: 'white' }}>{crumb.name}</Link>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </Toolbar>
                <Toolbar>
                    <Typography
                        sx={{ flex: '1 1 40%' }}
                        variant="h5"
                        id="tableTitle"
                        component="div"
                        className="inner-headings"
                    >
                        Sites Listing
                    </Typography>
                    <div className="flex-boxinner">
                        <Typography
                            sx={{ flex: '1 1 auto' }}
                        >
                            <SearchElement
                                searchFn={searchHandler}
                                searchTag={"Search Sites..."}
                            />

                        </Typography>
                        <Tooltip title="Add Site" arrow className="me-1 mt-1">
                            <Button className="secondary-btn" onClick={() => setShowModal(true)} type="submit" variant="contained" color="primary">Add Site</Button>
                        </Tooltip>
                    </div>
                </Toolbar>
                <StyledEngineProvider>
                    <TableContainer component={Paper} className="form-outers">
                        <Table
                            sx={{ minWidth: 650 }}
                            size="small" aria-label="a dense table"
                            className="table-outers"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableHeadElement
                                        setHead={tableColumns ? tableColumns : []}
                                        order={order}
                                        orderBy={orderBy}
                                        sortFn={(event, id: string) =>
                                            createSortHandler(event, id as keyof SortingData)
                                        }
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableBodyElement
                                    selectedColumns={[
                                        "name",
                                        "organization_name",
                                        "country",
                                        "state",
                                        "city",
                                    ]}
                                    setData={data?.response.data ? data?.response.data : []}
                                    viewSitesFn={(id, orgId) => {
                                        dispatch(setSiteOrganizationId(orgId));
                                        navigate(`/admin/organization/sites/${id}`, {
                                            state: page,
                                        })
                                    }
                                    }
                                    isHandleDeactivatefn={(id) => {
                                        setCompleteSite(true);
                                        setDeactivateId(id);
                                    }}
                                    
                                    deleteFn={deleteFn}
                                    isActivefn={(id) => isActivefn(id)}
                                    pageData={{
                                        limit:
                                            rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                        page: data?.response.page as number,
                                    }}
                                />
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={data?.response.total ?? 0}
                            page={data?.response.page ?? 0}
                            rowsPerPageOptions={[20, 50, 100]}
                            onPageChange={handleChangePage}
                            rowsPerPage={
                                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                            }
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </StyledEngineProvider>
            </Paper>
            <ConfirmBox
                title={`Site ${machineStatus ? 'Deactivate' : 'Activate'} Confirmation`}
                message={activeMachineId ? `Are you sure you want to ${machineStatus ? 'deactivate' : 'activate'} this Site?` : ''}
                open={isConfirmationOpen}
                onClose={handleConfirmationResponse}
            />
            <ConfirmBox
                title="Sites Delete Confirmation"
                message="Are you sure, you want to delete this Sites?"
                open={isClose}
                onClose={confirmBoxClose}
            />
             <DeactivateSiteBox
                title="Sites Completion Confirmation"
                message="Are you sure, you want to complete this Site?"
                open={isCompleteSite}
                onSiteCompletion={deactivateConfirmBoxClose}
            />
            {showModal &&
                <SiteAddEditModal open={showModal} handleModel={handleModel} isEdit={false} sitesData={undefined} />
            }
        </div>
    )
}

export default memo(SitesList);