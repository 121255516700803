import { ReactElement, useEffect, useState } from "react";
import AlertBox from "./AlertBox";
import  { PageBreadCrum } from "./Breadcrum";
import Footer from "./Footer";
import Header from "./Header";
import AdminRoutes from '../../AdminRouting';
import { Navigate, Route, Routes } from 'react-router-dom';
import Sidebar from "../../layout/Sidebar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { RouteInfo } from "../../types/CommonTypes";
export interface LayoutProps {
    breadcrums: PageBreadCrum[]
    children: ReactElement,
    isLoading: boolean
}
const Layout = () => {

    const userDetail = useSelector((state: RootState) => state.auth.user);
    const [route, setRoute] = useState<RouteInfo[]>([]);
    const [openSidebarToggle, setOpenSidebarToggle] = useState<boolean>(false);
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }

    const CloseSidebar = () => {
        setOpenSidebarToggle(false)
    }

    useEffect(() => {
        if (userDetail !== null) {
            if (userDetail?.organization_id === '') {
                if (userDetail?.role_type === 0) {
                    setRoute(AdminRoutes?.SuperAdminRoute);
                } else {
                    setRoute(AdminRoutes?.SubSuperAdminRoute);
                }
            } else {
                setRoute(AdminRoutes?.OrgAdminRoute);
            }
        }
    }, [userDetail])

    return (
        <>
            <Header OpenSidebar={OpenSidebar} openSidebarToggle={openSidebarToggle} />
            <AlertBox type="success" message="Success Alert Box" />
            <div className="layout-outer">
                <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} CloseSidebar={CloseSidebar} />
                <main className='main-container'>
                    <Routes>
                        {route.map((routeElem: { element: JSX.IntrinsicAttributes; path: string | undefined; } | any, idx: number) => {
                            return (
                                routeElem.element && (
                                    <Route
                                        key={idx}
                                        path={routeElem.path}
                                        element={<routeElem.element />}
                                    />
                                )
                            )
                        })}
                        <Route path="/" element={<Navigate to="dashboard" replace />} />
                    </Routes>
                </main>
            </div>
            <Footer />
        </>
    );
}

export default Layout;