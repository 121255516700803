import React, { memo, useCallback, useMemo, useState } from 'react'
import {
    Paper,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Typography,
    debounce,
} from "@mui/material";
import { TableBodyElement } from '../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../components/TableBody/TableHeadElement';
import { ColumnsType, Order, PageMeta, SortingData } from '../../types/CommonTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox';
import { SearchElement } from '../../components/Search/Search';
import { useDeleteShiftLogsMutation, useGetDailyLogsByEmployeeIdQuery } from '../../redux-services';
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite-no-reset.min.css";
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "user_name",
        sort: true,
        label: "User Name",
    },
    {
        id: "site_name",
        sort: true,
        label: "Site Name",
    },
    {
        id: "machine_name",
        sort: true,
        label: "Machinery Name",
    },
    {
        id: "shift_name",
        sort: true,
        label: "Attendance Name",
    },
    {
        id: "from_time",
        sort: false,
        label: "From Time",
    },
    {
        id: "to_time",
        sort: false,
        label: "To Time"
    },
    {
        id: "attendance_status",
        sort: true,
        label: "Attendance Status",
    },
    {
        id: 'createdAt',
        sort: true,
        label: "Date"
    },
];

const predefinedRanges = [
    {
        label: 'Today',
        value: [new Date(), new Date()],
        placement: 'left'
    },
    {
        label: 'Yesterday',
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
        placement: 'left'
    },
    {
        label: 'This week',
        value: [startOfWeek(new Date()), endOfWeek(new Date())],
        placement: 'left'
    },
    {
        label: 'Last 7 days',
        value: [subDays(new Date(), 6), new Date()],
        placement: 'left'
    },
    {
        label: 'Last 30 days',
        value: [subDays(new Date(), 29), new Date()],
        placement: 'left'
    },
    {
        label: 'This month',
        value: [startOfMonth(new Date()), new Date()],
        placement: 'left'
    },
    {
        label: 'Last month',
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
        placement: 'left'
    },
    {
        label: 'This year',
        value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
        placement: 'left'
    },
    {
        label: 'Last year',
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
        placement: 'left'
    },
    {
        label: 'All time',
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
        placement: 'left'
    },
    {
        label: 'Last week',
        closeOverlay: false,
        value: (value: any) => {
            const [start = new Date()] = value || [];
            return [
                addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
            ];
        },
        appearance: 'default'
    },
    {
        label: 'Next week',
        closeOverlay: false,
        value: (value: any) => {
            const [start = new Date()] = value || [];
            return [
                addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
            ];
        },
        appearance: 'default'
    }
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const ViewUserAttendance = ({ id, setOpen, setEditLogId }: any) => {
    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };
    let navigate = useNavigate();
    let query = useLocation();
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const [shiftLogsList, setShiftLogsList] = useState<PageMeta>(initialState);
    const [dateRange, setDateRange] = useState({
        startDate: "",
        endDate: "",
    })

    const [isClose, setIsClose] = useState<boolean>(false);
    const [Id, setId] = useState<string>("");

    const DateFormat = useCallback((dateString: any) => {
        const year = dateString.getFullYear();
        const month = String(dateString.getMonth() + 1).padStart(2, '0');
        const day = String(dateString.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }, []);

    const { data } = useGetDailyLogsByEmployeeIdQuery({
        per_page: shiftLogsList.per_page ?? rowsPerPage,
        page: query.state ?? shiftLogsList.page,
        order: shiftLogsList.order ?? order,
        order_by: shiftLogsList.order_by ?? orderBy,
        searchText: shiftLogsList.searchText ?? search,
        startDate: dateRange.startDate ? dateRange.startDate : "",
        endDate: dateRange.endDate ? dateRange.endDate : "",
        id: id ?? "",
    });

    const [deleteShiftLogs] = useDeleteShiftLogsMutation();

    const handleRequestSort = useCallback((
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setShiftLogsList({
            ...shiftLogsList,
            order: newOrder,
            order_by: property,
        });
    }, [order, orderBy, shiftLogsList]);

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setShiftLogsList({ ...shiftLogsList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setShiftLogsList({
            ...shiftLogsList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setShiftLogsList({
                page: 1,
                per_page: shiftLogsList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

    const confirmBoxClose = (res: boolean) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteShiftLogs(Id);
        } else {
            setIsClose(res);
        }
    };

    const handleDateChange = (date: any) => {
        if (date !== null) {
            setDateRange({ ...dateRange, startDate: DateFormat(date[0]), endDate: DateFormat(date[1]) })
        } else {
            setDateRange({ ...dateRange, startDate: '', endDate: '' })
        }
    };

    return (
        <div >
            <Paper className="form-outers AttendanceLogs">
                <Toolbar>
                    <Typography
                        sx={{ flex: '1 1 40%' }}
                        variant="h5"
                        id="tableTitle"
                        className="inner-headings"
                        component="div"
                    >
                        Attendance Logs
                    </Typography>
                    <div className="flex-boxinner worklogs">
                        <Typography
                            sx={{ flex: '1 1 auto' }}
                            className="datepicker-outer"
                        >
                            <DateRangePicker
                                ranges={predefinedRanges as any}
                                placeholder="Select Date Range"
                                showOneCalendar
                                onChange={handleDateChange}
                            />
                            <SearchElement
                                searchFn={searchHandler}
                                searchTag={"Search Attendance Log..."}
                            />

                        </Typography>
                        {/* <Tooltip title="Add Attendance Logs" arrow className="me-1 mt-1">
                            <Button className="secondary-btn" onClick={() => { setOpen(true); setEditLogId(null); }} type="submit" variant="contained" color="primary">Add</Button>
                        </Tooltip> */}
                    </div>
                </Toolbar>
                <StyledEngineProvider>
                    <TableContainer component={Paper} className="form-outers">
                        <Table
                            sx={{ minWidth: 650 }}
                            size="small" aria-label="a dense table"
                            className="table-outers"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableHeadElement
                                        setHead={tableColumns ? tableColumns : []}
                                        order={order}
                                        orderBy={orderBy}
                                        sortFn={(event, id: string) =>
                                            createSortHandler(event, id as keyof SortingData)
                                        }
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableBodyElement
                                    selectedColumns={[
                                        "user_name",
                                        "site_name",
                                        "machine_name",
                                        "shift_name",
                                        "from_time",
                                        "to_time",
                                        "attendance_status",
                                        "createdAt",
                                    ]}
                                    setData={data?.response.data ? data?.response.data : []}
                                    pageData={{
                                        limit:
                                            rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                        page: data?.response.page as number,
                                    }}
                                />
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={data?.response.total ?? 0}
                            page={data?.response.page ?? 0}
                            rowsPerPageOptions={[20, 50, 100]}
                            onPageChange={handleChangePage}
                            rowsPerPage={
                                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                            }
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </StyledEngineProvider>
            </Paper>
            <ConfirmBox
                title="Attendance Log Delete Confirmation"
                message="Are you sure, you want to delete this Attendance Log?"
                open={isClose}
                onClose={confirmBoxClose}
            />
        </div>
    )
}

export default memo(ViewUserAttendance);