import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";


export const    FuelEntriesApi = createApi({
    reducerPath: "fuelEntriesApi",

    baseQuery: axiosBaseQuery(),

    keepUnusedDataFor: 0,
    
    tagTypes: ["fuelEntriesTag"],

    endpoints: (builder) => ({
        fuelEntriesList: builder.query<Response<PaginatedData<any>>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/fuel/getlist`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                },
            }),
            providesTags: ["fuelEntriesTag"],
        }),
        machineFuelEntriesList: builder.query<Response<PaginatedData<any>>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/fuel/getMachineFuelEntry`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                    id: pageData.id ?? "",
                    site_id: pageData.site_id ?? "",
                },
            }),
            providesTags: ["fuelEntriesTag"],
        }),

        createFuelEntries: builder.mutation<Response<any>, FormData>({
            query: (data: FormData) => ({
                url: `/fuel/create`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["fuelEntriesTag"],
        }),

        getFuelEntriesById: builder.query<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/fuel/getFuelEntry/${id}`,
                method: "GET",
            }),
            providesTags: ["fuelEntriesTag"],
        }),

        editFuelEntries: builder.mutation<Response<any>, FormData>({
            query: (data: FormData) => ({
                url: `/fuel/edit/${data.get("id")}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["fuelEntriesTag"],
        }),
        deleteFuelEntries: builder.mutation<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/fuel/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["fuelEntriesTag"],
        }),

        getFuelLogs: builder.query<Response<any>, void>({
            query: () => ({
                url: `/fuel/getFuelLogs`,
                method: "GET",
            }),
            providesTags: ["fuelEntriesTag"],
        }),
    })
});

export const {
    useCreateFuelEntriesMutation,
    useEditFuelEntriesMutation,
    useFuelEntriesListQuery,
    useGetFuelEntriesByIdQuery,
    useDeleteFuelEntriesMutation,
    useMachineFuelEntriesListQuery,
    useGetFuelLogsQuery,
} = FuelEntriesApi;
