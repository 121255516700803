import React, { useCallback, useMemo, useState } from 'react'
import {
    Box,
    Button,
    Grid,
    Paper,
    Toolbar,
    Tooltip,
    Typography,
    debounce,
} from "@mui/material";
import {  Order, PageMeta, SortingData } from '../../../types/CommonTypes';
import { Link, useLocation } from 'react-router-dom';
import { SearchElement } from '../../../components/Search/Search';
import { useOrganizationListQuery } from '../../../redux-services';
import AddEditOrg from './AddEditOrg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import EditIcon from '@mui/icons-material/Edit';
import arrowImage from "../../../assests/img/arrow.svg"



let initialOrder: string = process.env.REACT_APP_ORDER as string;

const ViewOrganizationInfo = () => {
    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };
    const userDetail = useSelector((state: RootState) => state.auth.user);

    let query = useLocation();
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const [sitesList, setSitesList] = useState<PageMeta>(initialState);
    const [showModal, setShowModal] = useState<boolean>(false);

    const [editId, setEditId] = useState('');

    const {  data } = useOrganizationListQuery({
        per_page: sitesList.per_page ?? rowsPerPage,
        page: query.state ?? sitesList.page,
        order: sitesList.order ?? order,
        order_by: sitesList.order_by ?? orderBy,
        searchText: sitesList.searchText ?? search,
        organization_id: userDetail?.organization_id ?? '',
    });

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setSitesList({
                page: 1,
                per_page: sitesList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

    const handleModel = useCallback((isOpen: boolean) => {
        setShowModal(isOpen);
        setEditId('');
    }, []);
    const breadcrumbs = [
        { name: 'Dashboard', to: '/admin/dashboard' },
        { name: 'Organizations', to: '/admin/organization' },
    ];

    return (
        <div>
            {userDetail?.organization_id === '' && userDetail?.role_type === 0 ?
                <Paper className="form-outers">
                    <Toolbar>
                        <div className="breadcrumbs" style={{ display: 'flex', alignItems: 'center' }}>
                            {breadcrumbs.map((crumb, index) => (
                                <React.Fragment key={index}>
                                    {index > 0 && <img src={arrowImage} alt="arrow" style={{ margin: '0 8px', verticalAlign: 'middle' }} />}
                                    {index === breadcrumbs.length - 1 ? (
                                        <Typography variant="body2" component="span" style={{ color: 'white' }}>{crumb.name}</Typography>
                                    ) : (
                                        <Link to={crumb.to} className="breadcrumb-link" style={{ color: 'white' }}>{crumb.name}</Link>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </Toolbar>
                    <Toolbar>
                        <Typography
                            sx={{ flex: '1 1 40%' }}
                            variant="h5"
                            id="tableTitle"
                            component="div"
                            className="inner-headings"
                        >
                            Organization List
                        </Typography>
                        <Box className="flex-boxinner">
                            <Typography
                                sx={{ flex: '1 1 auto' }}
                            >
                                <SearchElement
                                    searchFn={searchHandler}
                                    searchTag={"Search Organization..."}
                                />

                            </Typography>

                            <Tooltip title="Add Organization" arrow className="me-1 mt-1">
                                <Button className="secondary-btn" onClick={() => { setShowModal(true); setEditId(''); }} type="submit" variant="contained" color="primary">Add Organization</Button>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </Paper>
                : ""
            }
            <Box sx={{ flexGrow: 1,marginBottom:2 }} className='mt-3'>
                <Grid container spacing={2} >
                    {data?.response?.data.map((item: any) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <Box className="single-customer-box">
                                <Link to={`/admin/organization/${item._id}`} >
                                    <Typography variant='h4'>{item.organization_name}</Typography>
                                    <Typography variant='subtitle1'>{item.email}</Typography>
                                    <Box className="company-initial">
                                        {item.organization_name.split(' ').map((name: string) => name[0]).join('').toUpperCase()}
                                    </Box>
                                </Link>
                                <Button className='edit-org-btn' onClick={() => { setShowModal(true); setEditId(item?._id ?? '') }}><EditIcon /></Button>
                            </Box>
                        </Grid>))}

                </Grid>
            </Box>
            {showModal &&
                <AddEditOrg open={showModal} handleModel={handleModel} editId={editId !== '' ? editId : null} />
            }
        </div>
    )
}

export default ViewOrganizationInfo;