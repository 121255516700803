import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const MachineWorkLogApi = createApi({
  reducerPath: "machineWorkLogApi",

  baseQuery: axiosBaseQuery(),

  tagTypes: ["machineLogTag"],

  endpoints: (builder) => ({
    machineWorkLogList: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/machine_log/list`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
          id: pageData.id ?? "",
        },
      }),
      providesTags: ["machineLogTag"],
    }),

    createMachineWorkLog: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/machine_log/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["machineLogTag"],
    }),

    getMachineWorkLogById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/machine_log/${id}`,
        method: "GET",
      }),
      providesTags: ["machineLogTag"],
    }),

    editMachineWorkLog: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/machine_log/edit/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["machineLogTag"],
    }),
    deleteMachineWorkLog: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/machine_log/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["machineLogTag"],
    }),

    findPreviousMachineLog: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/machine_log/previous_machine_log`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["machineLogTag"],
    }),

    previousMachineLog: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/machine_log/previous_log`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["machineLogTag"],
    }),

    getMachineLogsById: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/machine_log/machine_work_log`,
        method: "GET",
        params: {
          id: pageData.id ?? "",
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
        },
      }),
      providesTags: ["machineLogTag"],
    }),
    machineTripWorkItem: builder.query<Response<any>, any>({
      query: (query: any) => ({
        url: `/machine_log/machine_trip_calculate`,
        method: "GET",
        params: {
          number_plate: query.number_plate,
          work_item_id: query.work_item_id,
        },
      }),
      providesTags: ["machineLogTag"],
    }),
  }),
});

export const {
  useMachineWorkLogListQuery,
  useCreateMachineWorkLogMutation,
  useEditMachineWorkLogMutation,
  useGetMachineWorkLogByIdQuery,
  useDeleteMachineWorkLogMutation,
  useFindPreviousMachineLogMutation,
  usePreviousMachineLogMutation,
  useGetMachineLogsByIdQuery,
  useMachineTripWorkItemQuery,
} = MachineWorkLogApi;
