import React from 'react';
import { Grid, TextField } from '@mui/material';

interface MachineType {
    number_plate: boolean;
    volume_capacity: boolean;
    tank_capacity: boolean;
    trip_value:string;
    [key: string]: any;
  }
  
  interface FormData {
    number_plate: string;
    volume_capacity: string;
    tank_capacity: string;
    [key: string]: any;
  }
  
  interface FormErrors {
    number_plate?: string;
    volume_capacity?: string;
    tank_capacity?: string;
    [key: string]: any;
  }
  
  interface DynamicFieldsProps {
    machineType: MachineType | null;
    formData: FormData;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => void;
      formErrors: FormErrors;
  }

const ReusableAddEditMachineInfo: React.FC<DynamicFieldsProps> = ({machineType, formData, handleChange, formErrors}) => {
  
    if(!machineType){
        return null;
    }
    return(
        <>
        {machineType.number_plate && (
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              id="number_plate"
              label="Number Plate"
              name="number_plate"
              type="text"
              size='small'
              value={formData.number_plate}
              onChange={handleChange}
              error={!!formErrors.number_plate}
              helperText={formErrors.number_plate}
            />
          </Grid>
        )}
        {machineType.volume_capacity && (
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              id="volume_capacity"
              label="Volume Capacity (m³)"
              name="volume_capacity"
              type="number"
              size='small'
              value={formData.volume_capacity}
              onChange={handleChange}
              error={!!formErrors.volume_capacity}
              helperText={formErrors.volume_capacity}
            />
          </Grid>
        )}
        {machineType.tank_capacity && (
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              id="tank_capacity"
              label="Tank Capacity"
              name="tank_capacity"
              type="number"
              size='small'
              value={formData.tank_capacity}
              onChange={handleChange}
              error={!!formErrors.tank_capacity}
              helperText={formErrors.tank_capacity}
            />
          </Grid>
        )}
         {machineType.tank_capacity && (
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              id="measurement_unit"
              label="Measurement Value"
              name="measurement_unit"
              type="string"
              size='small'
              disabled
              value={formData.measurement_unit}
              onChange={handleChange}
              error={!!formErrors.trip_value}
              helperText={formErrors.trip_value}
            />
          </Grid>
        )}
      </>
    )
}

export default ReusableAddEditMachineInfo;