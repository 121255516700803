import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";


export const ShiftLogsApi = createApi({
    reducerPath: "shfitLogsApi",

    baseQuery: axiosBaseQuery(),

    tagTypes: ["shiftLogsTag"],

    endpoints: (builder) => ({
        shiftLogsList: builder.query<Response<PaginatedData<any>>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/shift_logs/getlist`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                    type: pageData.type ?? "",
                },
            }),
            providesTags: ["shiftLogsTag"],
        }),

        getShiftLogsByUserId: builder.query<Response<PaginatedData<any>>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/shift_logs/user_id`,
                method: "GET",
                params: {
                    id: pageData.id ?? '',
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                },
            }),
            providesTags: ["shiftLogsTag"],
        }),

        machineWorkLogsList: builder.query<Response<PaginatedData<any>>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/shift_logs/getMachineWorkLogs`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                    id: pageData.id ?? "",
                },
            }),
            providesTags: ["shiftLogsTag"],
        }),

        createShiftLogs: builder.mutation<Response<any>, any>({
            query: (data: any) => ({
                url: `/shift_logs/create`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["shiftLogsTag"],
        }),

        getShiftLogsById: builder.query<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/shift_logs/getShiftLogs/${id}`,
                method: "GET",
            }),
            providesTags: ["shiftLogsTag"],
        }),

        editShiftLogs: builder.mutation<Response<any>, any>({
            query: (data: any) => ({
                url: `/shift_logs/edit/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["shiftLogsTag"],
        }),
        deleteShiftLogs: builder.mutation<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/shift_logs/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["shiftLogsTag"],
        }),
        getShiftDetail: builder.query<Response<any>, string | void | null>({
            query: (siteId?: string) => ({
                url: `/shift/shift_name/${siteId}`,
                method: "GET",
            }),
            providesTags: ["shiftLogsTag"],
        }),
        getUserDataDetail: builder.query<Response<any>, string | void | null>({
            query: (siteId?: string) => ({
                url: `/employee/employee_name/${siteId}`,
                method: "GET"
            }),
            providesTags: ["shiftLogsTag"],
        }),
    })
});

export const {
    useCreateShiftLogsMutation,
    useEditShiftLogsMutation,
    useGetShiftLogsByIdQuery,
    useShiftLogsListQuery,
    useDeleteShiftLogsMutation,
    useGetShiftDetailQuery,
    useGetUserDataDetailQuery,
    useMachineWorkLogsListQuery,
    useGetShiftLogsByUserIdQuery,
} = ShiftLogsApi;
