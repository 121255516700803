import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const Footer: React.FunctionComponent = (props) => {
    const currentYear = new Date().getFullYear();
    return (
        <Box className="footer">
            <Typography textAlign="center">
                © {currentYear} Karu Infrastructure | ALL RIGHTS RESERVED | PRIVACY POLICY
            </Typography>

        </Box>

    )
}

export default Footer;