import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import { Provider, useDispatch } from "react-redux";
import {
  removeAlert,
  setAlert,
  setAuthLogout,
  setError,
  setLoader,
} from "./redux-services";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ThemeProvider, createTheme } from "@mui/material";

const Interceptor: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const showError = (error: any) => {
    dispatch(setError(error));
  };
  const showAlert = (error = false, message = "") => {
    dispatch(
      setAlert({
        type: error ? "error" : "success",
        message,
      })
    );
    setTimeout(() => {
      dispatch(removeAlert(0));
    }, 5000);
  };

  axios.interceptors.request.use(
    (request: AxiosRequestConfig): any => {
      dispatch(setLoader({ isLoading: true }));
      return request;
    },
    (error) => error
  );

  axios.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
      if (response?.data?.status === 401) {
        localStorage.clear();
        dispatch(setAuthLogout());
        window.location.reload();
      }
      let splitUrl: any = response?.config.url?.split("/");
      let urlData: string =
        splitUrl[splitUrl?.length - 2] + splitUrl[splitUrl?.length - 1];
      if (
        urlData === "authlogin" &&
        response?.data?.message !== "Invalid credentials" &&
        response?.data?.status !== 200
      ) {
        let errorMsg: any = Object.values(response?.data?.error);
        showAlert(true, errorMsg);
      } else {
        if (
          urlData === "daily_logcreate" ||
          urlData === "daily_loglist" ||
          urlData === "daily_log_mark_attendance"
        ) {
          dispatch(removeAlert(0));
          setTimeout(() => {
            dispatch(removeAlert(0));
          }, 0);
        }
        if (
          urlData !== "sitesgetVehicleDetails" &&
          urlData !== "sitesgetmorevehicleinfo" &&
          urlData !== "vehicle_movementgetvehiclereg" &&
          urlData !== "sitesgetdaywise" &&
          (urlData === "getcurrstatus" ||
            urlData === "getdueservice" ||
            urlData === "getvehicleusage")
        ) {
          showAlert(response?.data?.status !== 200, response.data?.message);
        }
        let lasturl = [
          "sitesgetVehicleDetails",
          "sitesgetmorevehicleinfo",
          "forgot_password",
          "sitesgetdaywise",
          "getcurrstatus",
          "getdueservice",
          "getvehicleusage",
          "sitesgetcurrstatus",
          "sitesgetdueservice",
          "sitesgetcurrstatus",
          "sitesgetvehicleusage",
          "vehicle_movementgetvehiclereg",
          "vehicle_movementgetvehiclehistory",
          "dailylogcreate",
        ];
        if (
          !["get", "patch"].includes(response.config.method as string) &&
          !lasturl.includes(urlData)
        ) {
          showAlert(response?.data?.status !== 200, response.data?.message);
        }
      }
      dispatch(setLoader({ isLoading: false }));
      return response;
    },
    (error: AxiosError<any>): AxiosError => {
      dispatch(setLoader({ isLoading: false }));

      if (error.response?.status === 422) {
        showError(error.response?.data?.errors);
      } else {
        showAlert(true, error.response?.data.message ?? error.message);
      }

      if (error.response?.status === 401) {
        localStorage.clear();
        dispatch(setAuthLogout());
        window.location.reload();
      }
      return error.response as any;
    }
  );
  return <></>;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Interceptor />
      <ThemeProvider theme={darkTheme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
