import { createApi } from "@reduxjs/toolkit/query/react";
import { Response } from "../../types/CommonTypes";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export const MachineDocumentTypeApi = createApi({
    reducerPath: "machineDocumentTypeApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["machineDocumentTypeTag"],

    endpoints: (builder) => ({
        machineDocumentTypeList: builder.query<Response<any>, string>({
            query: (id:string) => ({
                url: `/machine_document_type/${id}`,
                method: "GET",
                params:{
                    org_id : id
                  }
                
            }),
            providesTags: ["machineDocumentTypeTag"],
        }),

        createMachineDocumentType: builder.mutation<Response<any>, any> ({
            query: (data: any) => ({
                url: `/machine_document_type/create`,
                method: "POST",
                
                body: data,
            }),
            invalidatesTags: ["machineDocumentTypeTag"],
        }),

        deleteMachineDocType: builder.mutation<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/machine_document_type/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["machineDocumentTypeTag"],
        }),

        getDocumentTypeById: builder.query<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/machine_document/getMachineDocuments/${id}`,
                method: "GET",
            }),
            providesTags: ["machineDocumentTypeTag"],
        }),
        editMachineDocType: builder.mutation<Response<any>, { id: string; data: any }>({
            query: ({ id, data }) => ({
                url: `/machine_document_type/edit/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["machineDocumentTypeTag"],
        }),
        
    })
   
    
})

export const {
    useCreateMachineDocumentTypeMutation,
    useMachineDocumentTypeListQuery,
    useDeleteMachineDocTypeMutation,
    useEditMachineDocTypeMutation,
    useGetDocumentTypeByIdQuery
} = MachineDocumentTypeApi;