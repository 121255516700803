import React, { useCallback, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import {
  useGetSitesByIdQuery,
  useGetVehicleDetailsQuery,
} from "../../../redux-services";
import SiteAddEditModal from "../../../components/Models/SiteAddEditModal";
import VehicleInfoModal from "./TrackerDetails/ViewTracker/VehicleDetails/VehicleInfoModal";

interface SiteState {
  name: string;
  location: {
    coordinates: any[];
  };
  address: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  encodedUrl: string[];
}

const SiteDetails: React.FC<{
  id: string;
  setSiteDetail: any;
  org_id: any;
}> = ({ id, setSiteDetail, org_id }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openVehicleInfoModal, setOpenVehicleInfoModal] =
    useState<boolean>(false);
  const [siteData, setSiteData] = useState<SiteState | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [vehicleOdoDetails, setVehicleOdoDetails] = useState<any[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State to control button disable

  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState<number>(-1);
  const { data: getData } = useGetSitesByIdQuery(id);
  const { data: getVehicleDetailsData } = useGetVehicleDetailsQuery(
    { id: org_id || "", siteId: id },
    {
      skip: !id || !org_id, // Skip if either id or org_id is falsy or undefined
    }
  );
  useEffect(() => {
    if (getVehicleDetailsData?.response) {
      setVehicleOdoDetails(getVehicleDetailsData?.response);
    }
  }, [getVehicleDetailsData?.response, vehicleOdoDetails]);

  useEffect(() => {
    if (!getData) return;
    const { status, response } = getData;
    if (status === 200 && response) {
      const { _id, name, is_deactivate, ...siteDetails } = response;
      setSiteData({ name, ...siteDetails });
      setSiteDetail({ id: _id, name });
      setIsButtonDisabled(is_deactivate);
      setLoading(false);
    }
  }, [getData, setSiteDetail]);

  const handleModel = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
  }, []);

  const handleVehicleInfoModal = useCallback((index: number) => {
    setSelectedVehicleIndex(index);
    setOpenVehicleInfoModal(true);
  }, []);

  return (
    <>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Box>
          <Box className="heading-bar">
            <List>
              <ListItem>
                <Button onClick={() => handleModel(true)} disabled={isButtonDisabled}>
                  <EditIcon />
                </Button>
              </ListItem>
            </List>
          </Box>
          <Box className="site-details">
            <List>
              <ListItem>
                <Typography variant="h5">Site Name</Typography>
                <Typography className="site-detail-value">
                  {siteData?.name}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="h5">Site Address</Typography>
                <Typography className="site-detail-value">
                  {siteData?.address}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="h5">City</Typography>
                <Typography className="site-detail-value">
                  {siteData?.city}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="h5">State</Typography>
                <Typography className="site-detail-value">
                  {siteData?.state}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="h5">Country</Typography>
                <Typography className="site-detail-value">
                  {siteData?.country}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="h5">Postal Code</Typography>
                <Typography className="site-detail-value">
                  {siteData?.postal_code}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="h5">Latitude</Typography>
                <Typography className="site-detail-value">
                  {siteData?.location?.coordinates[0]}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="h5">Longitude</Typography>
                <Typography className="site-detail-value">
                  {siteData?.location?.coordinates[1]}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="h5">Site Image</Typography>
                <Box className="site-detail-value">
                  {siteData?.encodedUrl.map((img, index) => (
                    <img key={img} src={img} alt={`Site Not Found`} />
                  ))}
                </Box>
              </ListItem>
            </List>
            {open && (
              <SiteAddEditModal
                open={open}
                sitesData={siteData as any}
                handleModel={handleModel}
                isEdit={true}
              />
            )}
          </Box>
          <Box className="vehicle-odo-details">
            <Container>
              <Grid container spacing={2}>
                {vehicleOdoDetails?.map((vehicleInfo, index) => (
                  <Grid item xs={12} sm={6} md={3} key={vehicleInfo?._id}>
                    <Box className="cardBox">
                      <Card className="card-inner">
                        <CardContent>
                          <Typography
                            variant="h5"
                            sx={{ fontSize: 17 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            {vehicleInfo.vehicle_type}
                          </Typography>
                          <Typography
                            sx={{ mb: 1.0, fontSize: 12 }}
                            color="text.secondary"
                          >
                            Fuel Value: {vehicleInfo.fuel_value}
                          </Typography>
                          <Typography
                            sx={{ mb: 1.0, fontSize: 12 }}
                            color="text.secondary"
                          >
                            New Odometer Reading: {vehicleInfo.new_odo_reading}
                          </Typography>
                          <Typography
                            sx={{ mb: 1.0, fontSize: 12 }}
                            color="text.secondary"
                          >
                            Vins ID: {vehicleInfo.vin}
                          </Typography>
                          <Button
                            className="secondary-btn"
                            onClick={() => handleVehicleInfoModal(index)}
                          >
                            View Details
                          </Button>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Container>
            {openVehicleInfoModal && (
              <VehicleInfoModal
                vins_id={vehicleOdoDetails[selectedVehicleIndex]?.vin}
                open={openVehicleInfoModal}
                handleModel={() => setOpenVehicleInfoModal(false)}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default SiteDetails;
